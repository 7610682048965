export default class UsersPrivilegeEmployeeStores {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.employeeStoresView = false;
    this.employeeStoresAdd = false;
    this.employeeStoresUpdate = false;
    this.employeeStoresArchive = false;
    this.employeeStoresRestore = false;
    this.employeeStoresDelete = false;
  }
  fillData(data) {
    if (data) {
      this.employeeStoresView = data.employeeStoresView;
      this.employeeStoresAdd = data.employeeStoresAdd;
      this.employeeStoresUpdate = data.employeeStoresUpdate;
      this.employeeStoresArchive = data.employeeStoresArchive;
      this.employeeStoresRestore = data.employeeStoresRestore;
      this.employeeStoresDelete = data.employeeStoresDelete;
    } else {
      this.setInitialValue();
    }
  }
}
