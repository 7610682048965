var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"DeductionsPlanDetailsInfo","scrollable":"","size":"lg","centered":"","hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h3',[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/deductions.svg")}}),_vm._v(" "+_vm._s(_vm.$t("deductions.data"))+" ")])]},proxy:true}])},[_c('div',{staticClass:"row"},[_c('DataLabelGroup',{attrs:{"className":'col-lg-12',"value":_vm.convertTimeAndDaysToString(
          _vm.deductionsPlansSlideDetailsData.fromNumberInSeconds,
          _vm.deductionsPlansSlideDetailsData.fromNumberInMinutes,
          _vm.deductionsPlansSlideDetailsData.fromNumberInHours,
          _vm.deductionsPlansSlideDetailsData.fromNumberInDays
        ),"title":_vm.$t('deductionsPlanDetails.maxFrom'),"imgName":'days.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-12',"value":_vm.convertTimeAndDaysToString(
          _vm.deductionsPlansSlideDetailsData.toNumberInSeconds,
          _vm.deductionsPlansSlideDetailsData.toNumberInMinutes,
          _vm.deductionsPlansSlideDetailsData.toNumberInHours,
          _vm.deductionsPlansSlideDetailsData.toNumberInDays
        ),"title":_vm.$t('deductionsPlanDetails.maxFrom'),"imgName":'days.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-12',"value":_vm.deductionsPlansSlideDetailsData.hourfactor,"title":_vm.$t('deductionsPlanDetails.hourfactor'),"imgName":'hours.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-12',"value":_vm.deductionsPlansSlideDetailsData.employeeSalaryEffectNameCurrent,"title":_vm.$t('salaryEffectName'),"imgName":'money.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-12',"value":_vm.deductionsPlansSlideDetailsData.effectOnEmployeeSalaryStatus
          ? _vm.$t('yes')
          : _vm.$t('no'),"title":_vm.$t('deductions.effectOnEmployeeSalaryStatus'),"imgName":'checkmark.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-12',"value":_vm.deductionsPlansSlideDetailsData.slideTypeNameCurrent,"title":_vm.$t('deductions.slideType'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-12',"value":_vm.deductionsPlansSlideDetailsData.fullShiftDeductionStatus
          ? _vm.$t('yes')
          : _vm.$t('no'),"title":_vm.$t('deductions.fullShiftDeductionStatus'),"imgName":'checkmark.svg'}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }