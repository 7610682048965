export default class UsersPrivilegeEmployeesInputSheetAttendanceMerge {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.EmployeesInputSheetAttendanceMergeView = false;
    this.EmployeesInputSheetAttendanceMergeAdd = false;
    this.EmployeesInputSheetAttendanceMergeDelete = false;
  }
  fillData(data) {
    if (data) {
      this.EmployeesInputSheetAttendanceMergeView =
        data.EmployeesInputSheetAttendanceMergeView;
      this.EmployeesInputSheetAttendanceMergeAdd =
        data.EmployeesInputSheetAttendanceMergeAdd;
      this.EmployeesInputSheetAttendanceMergeDelete =
        data.EmployeesInputSheetAttendanceMergeDelete;
    } else {
      this.setInitialValue();
    }
  }
}
