<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <div v-if="deductionsPlanData">
      <DeductionsPlanNameTable :deductionsPlanData="deductionsPlanData" />

      <DeductionsPlanDetailsTable
        :deductionsPlansSlidesDetailsData="
          deductionsPlanData.deductionsPlansDetailsSlideData
        "
        v-on:setDeductionsPlansSlideDetailsData="
          deductionsPlansSlideDetailsData.fillData($event)
        "
        :deductionsPlansSlideDetailsData="deductionsPlansSlideDetailsData"
      />

      <!-- <Pagination
        v-if="!isLoading"
        :paginationData="paginationData"
        v-on:changePage="changePage"
      /> -->

      <DeductionsPlanDetailsInfo
        :deductionsPlansSlideDetailsData="deductionsPlansSlideDetailsData"
      />
      <DeductionsPlanDetailsDelete
        :deductionsPlansSlideDetailsData="deductionsPlansSlideDetailsData"
        v-on:refresh="getDeductionsPlanDetails()"
      />
    </div>

    <!-- <DeductionsPlanDetailsFloatBtns
    :deductionsPlansToken="deductionsPlansToken" :textSearch="textSearch"
    v-on:search="search" /> -->
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import DeductionsPlanNameTable from "@/components/settings/settingsSalary/deductionsPlans/DeductionsPlanNameTable.vue";
import DeductionsPlanDetailsTable from "@/components/settings/settingsSalary/deductionsPlans/DeductionsPlanDetailsTable.vue";
import DeductionsPlanDetailsInfo from "@/components/settings/settingsSalary/deductionsPlans/DeductionsPlanDetailsInfo.vue";
import DeductionsPlanDetailsDelete from "@/components/settings/settingsSalary/deductionsPlans/DeductionsPlanDetailsDelete.vue";
// // import DeductionsPlanDetailsFloatBtns from "@/components/settings/settingsSalary/deductionsPlans/DeductionsPlanDetailsFloatBtns.vue";
// import Pagination from "@/components/general/Pagination.vue";
import DeductionsPlan from "@/models/settings/settingsSalary/deductionsPlans/DeductionsPlan";
import DeductionsPlansSlideDetails from "@/models/settings/settingsSalary/deductionsPlans/DeductionsPlansSlideDetails";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "DeductionsPlanDetails",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    DeductionsPlanNameTable,
    DeductionsPlanDetailsTable,
    DeductionsPlanDetailsInfo,
    DeductionsPlanDetailsDelete,
    // DeductionsPlanDetailsFloatBtns,
    // Pagination,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      textSearch: "",
      paginationData: {
        totalPages: 1,
        totalItems: 0,
        countItemsInPage: 0,
        selfPage: 1,
      },
      deductionsPlansData: null,
      deductionsPlanData: new DeductionsPlan(),
      deductionsPlansSlideDetailsData: new DeductionsPlansSlideDetails(),
      deductionsPlansToken: this.$route.params.deductionsPlansToken,
    };
  },
  methods: {
    changePage(page) {
      this.paginationData.selfPage = page;
      this.getDeductionsPlanDetails();
    },
    search(text) {
      this.textSearch = text;
      this.getDeductionsPlanDetails();
    },
    async getDeductionsPlanDetails() {
      this.isLoading = true;
      this.deductionsPlanData.deductionsPlansToken = this.deductionsPlansToken;
      try {
        let response = await this.deductionsPlanData.getDeductionsPlanDetails(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.deductionsPlanData.fillData(response.data.deductionsPlansData);
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.deductionsPlansData = null;
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.deductionsPlansData = null;
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.deductionsPlansData = null;
          this.exceptionMsg = response.data.msg;
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.deductionsPlansData = null;
      }
      this.isLoading = false;
    },
  },
  computed: {},
  async created() {
    this.getDeductionsPlanDetails();
  },
};
</script>
