<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <DeductionsPlanDetailsForm
      v-if="!isLoading"
      :deductionsPlansSlideDetailsData="deductionsPlansSlideDetailsData"
      v-on:addOrUpdateDeductionsPlansSlidesDetails="
        updateDeductionsPlansSlidesDetails
      "
      :submitName="$t('edit')"
    />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import DeductionsPlanDetailsForm from "@/components/settings/settingsSalary/deductionsPlans/DeductionsPlanDetailsForm.vue";
import DeductionsPlansSlideDetails from "@/models/settings/settingsSalary/deductionsPlans/DeductionsPlansSlideDetails";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "DeductionsPlanDetailsEdit",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    DeductionsPlanDetailsForm,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      deductionsPlansSlideDetailsData: new DeductionsPlansSlideDetails(),
      deductionsPlansSlidesDetailsToken:
        this.$route.params.deductionsPlansSlidesDetailsToken,
    };
  },
  methods: {
    async getDeductionsPlansSlidesDetailsDetails() {
      this.isLoading = true;
      this.deductionsPlansSlideDetailsData.deductionsPlansSlidesDetailsToken =
        this.deductionsPlansSlidesDetailsToken;
      try {
        let response =
          await this.deductionsPlansSlideDetailsData.getDeductionsPlansSlidesDetailsDetails(
            this.language,
            this.userAuthorizeToken
          );
        if (response.data.status == STATUS.SUCCESS) {
          this.deductionsPlansSlideDetailsData.fillData(
            response.data.deductionsPlansSlidesDetailsData
          );
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
          this.deductionsPlansSlideDetailsData = null;
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.deductionsPlansSlideDetailsData = null;
      }
      this.isLoading = false;
    },
    async updateDeductionsPlansSlidesDetails() {
      this.isLoading = true;
      try {
        let response =
          await this.deductionsPlansSlideDetailsData.addOrUpdateDeductionsPlansSlidesDetails(
            this.language,
            this.userAuthorizeToken
          );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$router.push(
            "/deductions-plan-details/" +
              this.deductionsPlansSlideDetailsData.deductionsPlansToken
          );
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.getDeductionsPlansSlidesDetailsDetails();
  },
};
</script>

<style scoped lang="scss"></style>
