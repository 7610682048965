<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <form
      autocomplete="off"
      method="post"
      @submit.prevent="addOrUpdateDeductionsPlansSlidesDetails"
    >
      <div class="my-card">
        <div class="row">
          <CustomSelectBox
            :className="'col-md-12'"
            :id="'deductionTypeToken'"
            :value="deductionsPlansSlideDetailsData.deductionTypeToken"
            :options="deductionTypeTokenOptions"
            v-on:changeValue="deductionsPlanTypeChanged($event)"
            :title="$t('deductions.selectType')"
            :imgName="'type.svg'"
          />
          <CustomSelectBox
            v-if="
              deductionsPlansSlideDetailsData.deductionTypeToken ==
              deductionTypeTokens.Effect
            "
            :className="'col-md-12'"
            :id="'employeeSalaryEffectToken'"
            :value="deductionsPlansSlideDetailsData.employeeSalaryEffectToken"
            :options="employeeSalaryEffectTokenOptions"
            v-on:changeValue="
              deductionsPlansSlideDetailsData.employeeSalaryEffectToken = $event
            "
            :title="$t('selectSalaryEffect')"
            :imgName="'money.svg'"
          />

          <div class="my-card col-md-12">
            <span class="my-card-title">{{
              $t("deductionsPlanDetails.max")
            }}</span>

            <div class="row">
              <CustomInput
                :className="'col-md-3'"
                :id="`deductionNumberInDays`"
                :value="deductionsPlansSlideDetailsData.deductionNumberInDays"
                v-on:changeValue="
                  deductionsPlansSlideDetailsData.deductionNumberInDays = $event
                "
                :title="$t('day')"
                :imgName="'days.svg'"
              />
              <CustomInput
                :className="'col-md-3'"
                :id="'deductionNumberInHours'"
                :value="deductionsPlansSlideDetailsData.deductionNumberInHours"
                v-on:changeValue="
                  deductionsPlansSlideDetailsData.deductionNumberInHours =
                    $event
                "
                :title="$t('hour')"
                :imgName="'hours.svg'"
              />
              <CustomInput
                :className="'col-md-3'"
                :id="'deductionNumberInMinutes'"
                :value="
                  deductionsPlansSlideDetailsData.deductionNumberInMinutes
                "
                v-on:changeValue="
                  deductionsPlansSlideDetailsData.deductionNumberInMinutes =
                    $event
                "
                :title="$t('minute')"
                :imgName="'minutes.svg'"
              />
              <CustomInput
                :className="'col-md-3'"
                :id="'deductionNumberInSeconds'"
                :value="
                  deductionsPlansSlideDetailsData.deductionNumberInSeconds
                "
                v-on:changeValue="
                  deductionsPlansSlideDetailsData.deductionNumberInSeconds =
                    $event
                "
                :title="$t('second')"
                :imgName="'seconds.svg'"
              />
            </div>
          </div>

          <TextArea
            :className="'col-md-12'"
            :id="'deductionsPlansSlidesDetailsNote'"
            :value="
              deductionsPlansSlideDetailsData.deductionsPlansSlidesDetailsNote
            "
            v-on:changeValue="
              deductionsPlansSlideDetailsData.deductionsPlansSlidesDetailsNote =
                $event
            "
            :title="$t('notes')"
            :imgName="'notes.svg'"
          />
        </div>
      </div>

      <div class="form-footer">
        <button name="submit" type="submit" class="btn btn-submit">
          {{ submitName }}
        </button>
        <router-link
          :to="`/deductions-plan-details/${deductionsPlansSlideDetailsData.deductionsPlansToken}`"
          class="btn btn-cancel"
        >
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import { STATUS } from "@/utils/constants";
import { setDataMultiLang } from "@/utils/functions";
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import CustomInput from "@/components/general/CustomInput.vue";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import TextArea from "@/components/general/TextArea.vue";
import SalaryEffect from "@/models/settings/settingsSalary/salaryEffects/SalaryEffect";
import {
  DEDUCTIONS_TYPE_LIST,
  DEDUCTIONS_TYPE_TOKENS,
} from "@/utils/constantLists";

export default {
  name: "DeductionsPlanDetailsForm",
  components: {
    PreLoader,
    ExceptionWithImg,
    CustomInput,
    CustomSelectBox,
    TextArea,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      employeeSalaryEffectData: new SalaryEffect(),
      employeeSalaryEffectTokenOptions: [],
      deductionType: DEDUCTIONS_TYPE_LIST,
      deductionTypeTokens: DEDUCTIONS_TYPE_TOKENS,
      deductionTypeTokenOptions: [],
    };
  },
  props: ["deductionsPlansSlideDetailsData", "submitName"],
  methods: {
    async deductionsPlanTypeChanged(data) {
      this.deductionsPlansSlideDetailsData.deductionTypeToken = data;
      if (
        this.deductionsPlansSlideDetailsData.deductionTypeToken ==
        this.deductionTypeTokens.Sallary
      ) {
        this.deductionsPlansSlideDetailsData.employeeSalaryEffectToken = "";
      }
    },
    async addOrUpdateDeductionsPlansSlidesDetails() {
      this.$emit("addOrUpdateDeductionsPlansSlidesDetails");
    },
    async getDialogOfDeductionsType() {
      this.isLoading = true;
      this.deductionTypeTokenOptions = [];
      this.deductionTypeTokenOptions.push({
        value: "",
        text: this.$t("deductions.selectType"),
      });
      let deductions =
        this.$store.getters.userData.constantLists.listDeductionType ||
        this.deductionType;
      for (let item in deductions) {
        this.deductionTypeTokenOptions.push({
          value: deductions[item]["itemToken"],
          text: this.setDataMultiLang(
            this.language,
            deductions[item]["itemNameAr"],
            deductions[item]["itemNameEn"]
          ),
        });
      }
      this.isLoading = false;
    },
    async getDialogOfSalaryEffects() {
      this.isLoading = true;
      this.employeeSalaryEffectTokenOptions = [];
      this.employeeSalaryEffectTokenOptions.push({
        value: "",
        text: this.$t("selectSalaryEffect"),
      });
      try {
        let response =
          await this.employeeSalaryEffectData.getDialogOfEmployeeSalaryEffect(
            this.language,
            this.userAuthorizeToken
          );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.employeeSalaryEffectTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    setDataMultiLang(lang, dataAr, dataEn) {
      return setDataMultiLang(lang, dataAr, dataEn);
    },
  },
  watch: {},
  async created() {
    this.getDialogOfDeductionsType();
    this.getDialogOfSalaryEffects();
  },
};
</script>

<style lang="scss"></style>
