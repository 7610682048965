<template>
  <div class="">
    <b-button v-b-toggle.Custodies class="btn btn-lg btn-collapse">
      {{ $t("Custodies.modelName") }}
      <i class="fa fa-angle-down" aria-hidden="true"></i>
    </b-button>
    <b-collapse id="Custodies">
      <div class="my-card">
        <div class="row">
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="custodyViewAll"
              v-model="usersPrivilegeCustodies.custodyViewAll"
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="custodyViewAll">{{
              $t("viewAll")
            }}</label>
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="custodyViewSelf"
              v-model="usersPrivilegeCustodies.custodyViewSelf"
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="custodyViewSelf">{{
              $t("viewSelf")
            }}</label>
          </div>

          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="custodyAddAll"
              v-model="usersPrivilegeCustodies.custodyAddAll"
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="custodyAddAll">{{
              $t("addAll")
            }}</label>
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="custodyAddSelf"
              v-model="usersPrivilegeCustodies.custodyAddSelf"
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="custodyAddSelf">{{
              $t("addSelf")
            }}</label>
          </div>

          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="custodyCancelAll"
              v-model="usersPrivilegeCustodies.custodyCancelAll"
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="custodyCancelAll">{{
              $t("cancelAll")
            }}</label>
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="custodyCancelSelf"
              v-model="usersPrivilegeCustodies.custodyCancelSelf"
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="custodyCancelSelf">{{
              $t("cancelSelf")
            }}</label>
          </div>

          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="custodySettlementAddAll"
              v-model="usersPrivilegeCustodies.custodySettlementAddAll"
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="custodySettlementAddAll">{{
              $t("settlementAll")
            }}</label>
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="custodySettlementAddSelf"
              v-model="usersPrivilegeCustodies.custodySettlementAddSelf"
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="custodySettlementAddSelf">{{
              $t("settlementSelf")
            }}</label>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["usersPrivilegeCustodies"],
  methods: {},
};
</script>

<style scoped lang="scss"></style>
