export default class TimeSeparated {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.days = 0;
    this.hours = 0;
    this.minutes = 0;
    this.seconds = 0;
    this.milliSeconds = 0;
  }
  fillData(data) {
    this.days = data.days;
    this.hours = data.hours;
    this.minutes = data.minutes;
    this.seconds = data.seconds;
    this.milliSeconds = data.milliSeconds;
  }
}
