export default class JobsFilter {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.sectorToken = "";
    this.textSearch = "";
  }
  fillData(data) {
    this.sectorToken = data.sectorToken;
    this.textSearch = data.textSearch;
  }
}
