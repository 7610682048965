import { checkPrivilege } from "@/utils/functions";
import UsersEstablishmentPrivilege from "@/models/privileges/establishmentPrivileges/UsersEstablishmentPrivilege";

export default {
  data() {
    return {
      usersEstablishmentPrivilegeData:
        this.$store.getters.usersEstablishmentPrivilegeData ||
        new UsersEstablishmentPrivilege(),
    };
  },
  methods: {
    checkPrivilege(privilegeStatus) {
      return checkPrivilege(privilegeStatus);
    },
  },
};
