import { PAGE_SIZE } from "@/utils/constants";
import axios from "axios";

export default class EffectType {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.additionalEffectTypeToken = "";
    this.additionalEffectTypeNameCurrent = "";
    this.additionalEffectTypeNameEn = "";
    this.additionalEffectTypeNameAr = "";
    this.additionalEffectTypeNameUnd = "";
    this.mathematicalTypeToken = "";
    this.mathematicalTypeNameCurrent = "";
    this.mathematicalTypeNameEn = "";
    this.mathematicalTypeNameAr = "";
    this.mathematicalTypeNameUnd = "";
    this.additionalEffectMaximumNumberInMonth = "";
    this.additionalEffectMaximumNumberInMonthStatus = "";
    this.additionalEffectMaximumNumberInYear = "";
    this.additionalEffectMaximumNumberInYearStatus = "";
    this.additionalEffectTypeDescriptionCurrent = "";
    this.additionalEffectTypeDescriptionEn = "";
    this.additionalEffectTypeDescriptionAr = "";
    this.additionalEffectTypeDescriptionUnd = "";
    this.additionalEffectTypeNotes = "";
    this.additionalEffectTypeArchiveStatus = "";
  }
  fillData(data) {
    this.additionalEffectTypeToken = data.additionalEffectTypeToken;
    this.additionalEffectTypeNameCurrent = data.additionalEffectTypeNameCurrent;
    this.additionalEffectTypeNameEn = data.additionalEffectTypeNameEn;
    this.additionalEffectTypeNameAr = data.additionalEffectTypeNameAr;
    this.additionalEffectTypeNameUnd = data.additionalEffectTypeNameUnd;
    this.mathematicalTypeToken = data.mathematicalTypeToken;
    this.mathematicalTypeNameCurrent = data.mathematicalTypeNameCurrent;
    this.mathematicalTypeNameEn = data.mathematicalTypeNameEn;
    this.mathematicalTypeNameAr = data.mathematicalTypeNameAr;
    this.mathematicalTypeNameUnd = data.mathematicalTypeNameUnd;
    this.additionalEffectMaximumNumberInMonth =
      data.additionalEffectMaximumNumberInMonth;
    this.additionalEffectMaximumNumberInMonthStatus =
      data.additionalEffectMaximumNumberInMonthStatus;
    this.additionalEffectMaximumNumberInYear =
      data.additionalEffectMaximumNumberInYear;
    this.additionalEffectMaximumNumberInYearStatus =
      data.additionalEffectMaximumNumberInYearStatus;
    this.additionalEffectTypeDescriptionCurrent =
      data.additionalEffectTypeDescriptionCurrent;
    this.additionalEffectTypeDescriptionEn =
      data.additionalEffectTypeDescriptionEn;
    this.additionalEffectTypeDescriptionAr =
      data.additionalEffectTypeDescriptionAr;
    this.additionalEffectTypeDescriptionUnd =
      data.additionalEffectTypeDescriptionUnd;
    this.additionalEffectTypeNotes = data.additionalEffectTypeNotes;
    this.additionalEffectTypeArchiveStatus =
      data.additionalEffectTypeArchiveStatus;
  }

  async getAllEffectTTypes(language, userAuthorizeToken, selfPage, textSearch) {
    try {
      let response = await axios.get(
        `/AdditionalEffectTypes/GetAllAdditionalEffectTypes/?language=${language}&userAuthorizeToken=${userAuthorizeToken}&page=${selfPage}&pageSize=${PAGE_SIZE}&filterStatus=true&textSearch=${textSearch}&mathematicalTypeToken=${this.mathematicalTypeToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getEffectTypeDetails(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/AdditionalEffectTypes/GetAdditionalEffectTypeDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&additionalEffectTypeToken=${this.additionalEffectTypeToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getDialogOfEffectTypes(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/AdditionalEffectTypes/GetDialogOfAdditionalEffectTypes?language=${language}&userAuthorizeToken=${userAuthorizeToken}&mathematicalTypeToken=${this.mathematicalTypeToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addOrUpdateEffectType(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      additionalEffectTypeToken: this.additionalEffectTypeToken,
      additionalEffectTypeNameEn: this.additionalEffectTypeNameEn,
      additionalEffectTypeNameAr: this.additionalEffectTypeNameAr,
      additionalEffectTypeNameUnd: this.additionalEffectTypeNameUnd,
      mathematicalTypeToken: this.mathematicalTypeToken,
      additionalEffectMaximumNumberInMonth:
        this.additionalEffectMaximumNumberInMonth,
      additionalEffectMaximumNumberInMonthStatus:
        this.additionalEffectMaximumNumberInMonthStatus,
      additionalEffectMaximumNumberInYear:
        this.additionalEffectMaximumNumberInYear,
      additionalEffectMaximumNumberInYearStatus:
        this.additionalEffectMaximumNumberInYearStatus,
      additionalEffectTypeDescriptionEn: this.additionalEffectTypeDescriptionEn,
      additionalEffectTypeDescriptionAr: this.additionalEffectTypeDescriptionAr,
      additionalEffectTypeDescriptionUnd:
        this.additionalEffectTypeDescriptionUnd,
      additionalEffectTypeNotes: this.additionalEffectTypeNotes,
    };

    try {
      let response = "";
      if (
        this.additionalEffectTypeToken == "" ||
        this.additionalEffectTypeToken == undefined
      ) {
        response = await axios.post(
          `/AdditionalEffectTypes/AddAdditionalEffectType`,
          data
        );
      } else {
        response = await axios.post(
          `/AdditionalEffectTypes/UpdateAdditionalEffectType`,
          data
        );
      }
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async archiveEffectType(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      additionalEffectTypeToken: this.additionalEffectTypeToken,
    };

    try {
      let response = await axios.post(
        `/AdditionalEffectTypes/ArchiveAdditionalEffectType`,
        data
      );
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
