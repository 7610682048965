var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.revenuesTypesData)?_c('div',{},[_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"my-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v(_vm._s(_vm.$t("nameAr")))]),_c('th',[_vm._v(_vm._s(_vm.$t("nameEn")))]),_c('th',[_vm._v(_vm._s(_vm.$t("nameUnd")))]),_c('th',[_vm._v(_vm._s(_vm.$t("notes")))]),(
              _vm.checkPrivilege(
                _vm.usersEstablishmentPrivilegeData
                  .usersPrivilegAdditionalSettings.additionalSettingsUpdate
              )
            )?_c('th',[_vm._v(" "+_vm._s(_vm.$t("edit"))+" ")]):_vm._e(),(
              _vm.checkPrivilege(
                _vm.usersEstablishmentPrivilegeData
                  .usersPrivilegAdditionalSettings.additionalSettingsArchive
              )
            )?_c('th',[_vm._v(" "+_vm._s(_vm.$t("delete"))+" ")]):_vm._e()])]),_c('tbody',_vm._l((_vm.revenuesTypesData),function(revenueType,index){return _c('tr',{key:revenueType.revenuesTypeToken},[_c('td',[_vm._v(_vm._s(++index))]),_c('td',[_vm._v(_vm._s(_vm.isDataExist(revenueType.revenuesTypeNameAr)))]),_c('td',[_vm._v(_vm._s(_vm.isDataExist(revenueType.revenuesTypeNameEn)))]),_c('td',[_vm._v(_vm._s(_vm.isDataExist(revenueType.revenuesTypeNameUnd)))]),_c('td',[_vm._v(_vm._s(_vm.isDataExist(revenueType.revenuesTypeNotes)))]),(
              _vm.checkPrivilege(
                _vm.usersEstablishmentPrivilegeData
                  .usersPrivilegAdditionalSettings.additionalSettingsUpdate
              )
            )?_c('td',[_c('router-link',{attrs:{"to":("/edit-revenue-type/" + (revenueType.revenuesTypeToken)),"title":_vm.$t('edit')}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/pencil.svg")}})])],1):_vm._e(),(
              _vm.checkPrivilege(
                _vm.usersEstablishmentPrivilegeData
                  .usersPrivilegAdditionalSettings.additionalSettingsArchive
              )
            )?_c('td',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.RevenueTypeDelete",modifiers:{"RevenueTypeDelete":true}}],staticClass:"btn p-0",attrs:{"title":_vm.$t('delete')},on:{"click":function($event){return _vm.setRevenueTypeData(revenueType)}}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/trash.svg")}})])]):_vm._e()])}),0)])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }