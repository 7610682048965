import { render, staticRenderFns } from "./BranchPrivilegeForm.vue?vue&type=template&id=b370cd7e&scoped=true&"
import script from "./BranchPrivilegeForm.vue?vue&type=script&lang=js&"
export * from "./BranchPrivilegeForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b370cd7e",
  null
  
)

export default component.exports