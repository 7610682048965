<template>
  <div
    class="customFileInput"
    :class="className"
    :style="{ '--custom-file-input-img-url': 'url(' + theImage + ')' }"
  >
    <!-- :accept="'image/*,video/*,.zip'" -->
    <VueFileAgent
      ref="vueFileAgent"
      :theme="'grid'"
      :multiple="false"
      :deletable="true"
      :editable="false"
      :meta="true"
      :linkable="false"
      :maxSize="maxSize"
      :maxFiles="20"
      :helpText="title ? title : $t('fileInput.title')"
      :errorText="{
        type: 'Invalid file type. Only images or zip Allowed',
        size: `${$t('fileInput.filesSizeShouldNotExceed')} (${maxSize})`,
      }"
      :sortable="false"
      @select="filesSelected($event)"
      @beforedelete="onBeforeDelete($event)"
      @delete="fileDeleted($event)"
      v-model="fileRecords"
    ></VueFileAgent>
  </div>
</template>

<script>
import { checkIfImageExists } from "@/utils/functions";
import TheDefaultImg from "@/assets/images/upload-icon.svg";
import { MEDIA_TYPE_TOKENS, MEDIA_EXTENSIONS } from "@/utils/constantLists";
import CustomFile from "@/models/general/CustomFile";

export default {
  data: function () {
    return {
      fileRecords: [],
      // uploadUrl: "https://www.mocky.io/v2/5d4fb20b3000005c111099e3",
      // uploadHeaders: { "X-Test-Header": "vue-file-agent" },
      fileRecordsForUpload: [], // maintain an upload queue
      theImage: "",
      customFile: new CustomFile(),
    };
  },
  props: {
    className: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    maxSize: {
      type: String,
      default: "100 MB",
    },
    defaultImg: {
      type: String,
      default: "",
    },
  },
  methods: {
    // uploadFiles: function () {
    //   // Using the default uploader. You may use another uploader instead.
    //   this.$refs.vueFileAgent.upload(
    //     this.uploadUrl,
    //     this.uploadHeaders,
    //     this.fileRecordsForUpload
    //   );
    //   this.fileRecordsForUpload = [];
    // },
    // deleteUploadedFile: function (fileRecord) {
    //   // Using the default uploader. You may use another uploader instead.
    //   this.$refs.vueFileAgent.deleteUpload(
    //     this.uploadUrl,
    //     this.uploadHeaders,
    //     fileRecord
    //   );
    // },
    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      this.fileRecordsForUpload =
        this.fileRecordsForUpload.concat(validFileRecords);
    },
    onBeforeDelete: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1);
        var k = this.fileRecords.indexOf(fileRecord);
        if (k !== -1) this.fileRecords.splice(k, 1);
        // } else {
        // if (confirm("Are you sure you want to delete?")) {
        //   this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
        // }
      }
      this.$emit("changeValue", this.customFile);
    },
    fileDeleted: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
        // } else {
        // this.deleteUploadedFile(fileRecord);
      }
      this.$emit("changeValue", this.customFile);
    },
  },
  watch: {
    fileRecords: function (val) {
      if (val[0]) {
        let value = val[0];
        let extension = value.ext;

        if (MEDIA_EXTENSIONS.image.indexOf(extension) > -1) {
          value.mediaTypeToken = MEDIA_TYPE_TOKENS.image;
        } else if (MEDIA_EXTENSIONS.video.indexOf(extension) > -1) {
          value.mediaTypeToken = MEDIA_TYPE_TOKENS.video;
        } else if (MEDIA_EXTENSIONS.audio.indexOf(extension) > -1) {
          value.mediaTypeToken = MEDIA_TYPE_TOKENS.audio;
        } else if (MEDIA_EXTENSIONS.pdf.indexOf(extension) > -1) {
          value.mediaTypeToken = MEDIA_TYPE_TOKENS.pdf;
        } else if (MEDIA_EXTENSIONS.excel.indexOf(extension) > -1) {
          value.mediaTypeToken = MEDIA_TYPE_TOKENS.excel;
        } else if (MEDIA_EXTENSIONS.word.indexOf(extension) > -1) {
          value.mediaTypeToken = MEDIA_TYPE_TOKENS.word;
        } else if (MEDIA_EXTENSIONS.powerPoint.indexOf(extension) > -1) {
          value.mediaTypeToken = MEDIA_TYPE_TOKENS.powerPoint;
        } else {
          value.mediaTypeToken = "";
        }
        this.$emit("changeValue", value);
      } else {
        this.$emit("changeValue", this.customFile);
      }
      // this.$emit("changeValue", val[0] ? val[0].file : "");
    },
  },
  created() {
    checkIfImageExists(this.defaultImg, (exists) => {
      this.theImage = exists ? this.defaultImg : TheDefaultImg;
    });
  },
};
</script>
