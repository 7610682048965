var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.insurancesPlansSlidesDetailsData)?_c('div',{},[_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"my-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v(_vm._s(_vm.$t("planTypeName")))]),_c('th',[_vm._v(_vm._s(_vm.$t("employeeTotalSalaryFrom")))]),_c('th',[_vm._v(_vm._s(_vm.$t("employeeTotalSalaryTo")))]),_c('th',[_vm._v(_vm._s(_vm.$t("employeeTotalSalaryPercentage")))]),_c('th',[_vm._v(_vm._s(_vm.$t("employeeTotalSalaryDeductionStatus")))]),_c('th',[_vm._v(_vm._s(_vm.$t("notes")))]),_c('th',[_vm._v(_vm._s(_vm.$t("info")))]),(
              _vm.checkPrivilege(
                _vm.usersEstablishmentPrivilegeData
                  .usersPrivilegAdditionalSettings.additionalSettingsUpdate
              )
            )?_c('th',[_vm._v(" "+_vm._s(_vm.$t("edit"))+" ")]):_vm._e(),(
              _vm.checkPrivilege(
                _vm.usersEstablishmentPrivilegeData
                  .usersPrivilegAdditionalSettings.additionalSettingsArchive
              )
            )?_c('th',[_vm._v(" "+_vm._s(_vm.$t("delete"))+" ")]):_vm._e()])]),_c('tbody',_vm._l((_vm.insurancesPlansSlidesDetailsData),function(insurancesPlansSlide,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(++index))]),_c('td',[_vm._v(_vm._s(_vm.isDataExist(insurancesPlansSlide.planTypeNameCurrent)))]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist(insurancesPlansSlide.employeeTotalSalaryFrom))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist(insurancesPlansSlide.employeeTotalSalaryTo))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist(insurancesPlansSlide.employeeTotalSalaryPercentage))+" ")]),_c('td',[_vm._v(" "+_vm._s(insurancesPlansSlide.employeeTotalSalaryDeductionStatus === true ? _vm.$t("yes") : _vm.$t("no"))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist( insurancesPlansSlide.insurancesPlansSlidesDetailsNote ))+" ")]),_c('td',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.InsurancesPlanDetailsInfo",modifiers:{"InsurancesPlanDetailsInfo":true}}],staticClass:"btn p-0",attrs:{"title":_vm.$t('info')},on:{"click":function($event){return _vm.setInsurancesPlansSlideDetailsData(insurancesPlansSlide)}}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/info.svg")}})])]),(
              _vm.checkPrivilege(
                _vm.usersEstablishmentPrivilegeData
                  .usersPrivilegAdditionalSettings.additionalSettingsUpdate
              )
            )?_c('td',[_c('router-link',{attrs:{"to":("/edit-insurances-plan-details/" + (insurancesPlansSlide.insurancesPlansSlidesDetailsToken)),"title":_vm.$t('edit')}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/pencil.svg")}})])],1):_vm._e(),(
              _vm.checkPrivilege(
                _vm.usersEstablishmentPrivilegeData
                  .usersPrivilegAdditionalSettings.additionalSettingsArchive
              )
            )?_c('td',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.InsurancesPlanDetailsDelete",modifiers:{"InsurancesPlanDetailsDelete":true}}],staticClass:"btn p-0",attrs:{"title":_vm.$t('delete')},on:{"click":function($event){return _vm.setInsurancesPlansSlideDetailsData(insurancesPlansSlide)}}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/trash.svg")}})])]):_vm._e()])}),0)])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }