export default class UsersBranchPrivilageView {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.treasuriesView = false;
    this.expensesView = false;
    this.revenuesView = false;
    this.treasuryTransactionsView = false;
  }
}
