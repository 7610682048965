import axios from "axios";

import UsersPrivilegeExpenses from "@/models/privileges/branchPrivileges/UsersPrivilegeExpenses";
import UsersPrivilegeRevenues from "@/models/privileges/branchPrivileges/UsersPrivilegeRevenues";
import UsersPrivilegeTreasuries from "@/models/privileges/branchPrivileges/UsersPrivilegeTreasuries";
import UsersPrivlageTreasuriesTransactions from "@/models/privileges/branchPrivileges/UsersPrivlageTreasuriesTransactions";

export default class UsersBranchPrivilege {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.branchRoleToken = "";
    this.userBranchPrivilegeToken = "";
    this.usersPrivilegeExpenses = new UsersPrivilegeExpenses();
    this.usersPrivilegeRevenues = new UsersPrivilegeRevenues();
    this.usersPrivilegeTreasuries = new UsersPrivilegeTreasuries();
    this.usersPrivlageTreasuriesTransactions =
      new UsersPrivlageTreasuriesTransactions();
  }
  fillData(data) {
    this.branchRoleToken = data.branchRoleToken;
    this.userBranchPrivilegeToken = data.userBranchPrivilegeToken;
    this.usersPrivilegeExpenses = data.usersPrivilegeExpenses;
    this.usersPrivilegeRevenues = data.usersPrivilegeRevenues;
    this.usersPrivilegeTreasuries = data.usersPrivilegeTreasuries;
    this.usersPrivlageTreasuriesTransactions =
      data.usersPrivlageTreasuriesTransactions;
  }

  async getUserBranchPrivilege(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/UsersBranchPrivilege//GetUserBranchPrivilege?language=${language}&userAuthorizeToken=${userAuthorizeToken}&branchRoleToken=${this.branchRoleToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async updateUserBranchPrivilege(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      branchRoleToken: this.branchRoleToken,
      userBranchPrivilegeToken: this.userBranchPrivilegeToken,
      usersPrivilegeExpenses: this.usersPrivilegeExpenses,
      usersPrivilegeRevenues: this.usersPrivilegeRevenues,
      usersPrivilegeTreasuries: this.usersPrivilegeTreasuries,
      usersPrivlageTreasuriesTransactions:
        this.usersPrivlageTreasuriesTransactions,
    };

    try {
      let response = await axios.post(
        `/UsersBranchPrivilege//UpdateUserBranchPrivilege`,
        data
      );
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
