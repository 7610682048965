<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <form autocomplete="off">
      <div class="my-card">
        <div class="row">
          <CustomSelectBox
            :className="'col-md-12'"
            :id="'establishmentRoleToken'"
            :value="userEstablishmentPrivilegeDate.establishmentRoleToken"
            :options="establishmentRoleTokenOptions"
            v-on:changeValue="changeEstablishmentRoleToken($event)"
            :title="$t('setting.others.establishmentRolesMsgs.select')"
            :imgName="'establishment-roles.svg'"
          />
        </div>
      </div>

      <div v-if="userEstablishmentPrivilegeDate.establishmentRoleToken">
        <UsersPrivilegeEmployees
          :usersPrivilegeEmployees="
            userEstablishmentPrivilegeDate.usersPrivilegeEmployees
          "
        />

        <UsersPrivilegeEmployeesAttendance
          :usersPrivilegeEmployeesAttendance="
            userEstablishmentPrivilegeDate.usersPrivilegeEmployeesAttendance
          "
        />
        <UsersPrivilegeEmployeesMovement
          :usersPrivilegeEmployeesMovement="
            userEstablishmentPrivilegeDate.usersPrivilegeEmployeesMovement
          "
        />
        <UsersPrivilegeEmployeesMovementMedai
          :usersPrivilegeEmployeesMovementMedai="
            userEstablishmentPrivilegeDate.usersPrivilegeEmployeesMovementMedai
          "
        />
        <UsersPrivilegeEmployeesSettlementMovement
          :usersPrivilegeEmployeesSettlementMovement="
            userEstablishmentPrivilegeDate.usersPrivilegeEmployeesSettlementMovement
          "
        />
        <UsersPrivilegeEmployeesMoveSlides
          :usersPrivilegeEmployeesMoveSlides="
            userEstablishmentPrivilegeDate.usersPrivilegeEmployeesMoveSlides
          "
        />

        <!-- <UserPrivillegBranchesSettings
          :userPrivillegBranchesSettings="
            userEstablishmentPrivilegeDate.userPrivillegBranchesSettings
          "
        /> -->

        <!-- <UsersPrivilegeClients
          :usersPrivilegeClients="
            userEstablishmentPrivilegeDate.usersPrivilegeClients
          "
        /> -->
        <!-- <UsersPrivilegeNews
          :usersPrivilegeNews="
            userEstablishmentPrivilegeDate.usersPrivilegeNews
          "
        />
        <UsersPrivilegeOffers
          :usersPrivilegeOffers="
            userEstablishmentPrivilegeDate.usersPrivilegeOffers
          "
        /> -->

        <UsersPrivilegeBranches
          :usersPrivilegeBranches="
            userEstablishmentPrivilegeDate.usersPrivilegeBranches
          "
        />
        <!-- <UsersPrivilegeOfficesTimes
          :usersPrivilegeOfficesTimes="
            userEstablishmentPrivilegeDate.usersPrivilegeOfficesTimes
          "
        /> -->

        <!-- <UsersPrivilegeServicesReservations
          :usersPrivilegeServicesReservations="
            userEstablishmentPrivilegeDate.usersPrivilegeServicesReservations
          "
        />
        <UsersPrivilegeServicesReservationTreatmentPlan
          :usersPrivilegeServicesReservationTreatmentPlan="
            userEstablishmentPrivilegeDate.usersPrivilegeServicesReservationTreatmentPlan
          "
        /> -->

        <!-- <UsersPrivilegeTreasuries
          :usersPrivilegeTreasuries="
            userEstablishmentPrivilegeDate.usersPrivilegeTreasuries
          "
        />
        <UsersPrivilegeTreasuriesTransaction
          :usersPrivilegeTreasuriesTransaction="
            userEstablishmentPrivilegeDate.usersPrivilegeTreasuriesTransaction
          "
        />
        <UsersPrivilegeExpenses
          :usersPrivilegeExpenses="
            userEstablishmentPrivilegeDate.usersPrivilegeExpenses
          "
        />
        <UsersPrivilegeRevenus
          :usersPrivilegeRevenus="
            userEstablishmentPrivilegeDate.usersPrivilegeRevenus
          "
        /> -->

        <UsersPrivilegeEmployeeBranches
          :usersPrivilegeEmployeeBranches="
            userEstablishmentPrivilegeDate.usersPrivilegeEmployeeBranches
          "
        />

        <!-- <UsersPrivilegeEmployeeOfficialHolidaysPlans
          :usersPrivilegeEmployeeOfficialHolidaysPlans="
            userEstablishmentPrivilegeDate.usersPrivilegeEmployeeOfficialHolidaysPlans
          "
        /> -->
        <UsersPrivilegeEmployeeWorkSchedule
          :usersPrivilegeEmployeeWorkSchedule="
            userEstablishmentPrivilegeDate.usersPrivilegeEmployeeWorkSchedule
          "
        />
        <UsersPrivilegeEmployeeSalarySetting
          :usersPrivilegeEmployeeSalarySetting="
            userEstablishmentPrivilegeDate.usersPrivilegeEmployeeSalarySetting
          "
        />

        <UsersPrivilegeCustodies
          :usersPrivilegeCustodies="
            userEstablishmentPrivilegeDate.usersPrivilegeCustodies
          "
        />

        <UsersPrivilegAdditionalSettings
          :usersPrivilegAdditionalSettings="
            userEstablishmentPrivilegeDate.usersPrivilegAdditionalSettings
          "
        />
        <UsersPrivilegeEstablishmentsSettings
          :usersPrivilegeEstablishmentsSettings="
            userEstablishmentPrivilegeDate.usersPrivilegeEstablishmentsSettings
          "
        />
        <UsersPrivilegeEstablishmentYearSetting
          :usersPrivilegeEstablishmentYearSetting="
            userEstablishmentPrivilegeDate.usersPrivilegeEstablishmentYearSetting
          "
        />

        <UsersPrivilegeUsers
          :usersPrivilegeUsers="
            userEstablishmentPrivilegeDate.usersPrivilegeUsers
          "
        />

        <div class="form-footer">
          <button
            name="submit"
            type="submit"
            class="btn btn-submit"
            @click.prevent="updateUserEstablishmentPrivilege"
          >
            {{ submitName }}
          </button>
          <router-link to="/employees" class="btn btn-cancel">
            {{ $t("cancel") }}
          </router-link>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import UsersPrivilegeEmployees from "./UsersPrivilegeEmployees.vue";
import UsersPrivilegeEmployeesAttendance from "./UsersPrivilegeEmployeesAttendance.vue";
import UsersPrivilegeEmployeesMovement from "./UsersPrivilegeEmployeesMovement.vue";
import UsersPrivilegeEmployeesMovementMedai from "./UsersPrivilegeEmployeesMovementMedai.vue";
import UsersPrivilegeEmployeesSettlementMovement from "./UsersPrivilegeEmployeesSettlementMovement.vue";
import UsersPrivilegeEmployeesMoveSlides from "./UsersPrivilegeEmployeesMoveSlides.vue";
// import UserPrivillegBranchesSettings from "./UserPrivillegBranchesSettings.vue";
import UsersPrivilegeEstablishmentsSettings from "./UsersPrivilegeEstablishmentsSettings.vue";
// import UsersPrivilegeClients from "./UsersPrivilegeClients.vue";
// import UsersPrivilegeNews from "./UsersPrivilegeNews.vue";
// import UsersPrivilegeOffers from "./UsersPrivilegeOffers.vue";
import UsersPrivilegeBranches from "./UsersPrivilegeBranches.vue";
// import UsersPrivilegeOfficesTimes from "./UsersPrivilegeOfficesTimes.vue";
// import UsersPrivilegeServicesReservations from "./UsersPrivilegeServicesReservations.vue";
// import UsersPrivilegeServicesReservationTreatmentPlan from "./UsersPrivilegeServicesReservationTreatmentPlan.vue";
// import UsersPrivilegeTreasuries from "./UsersPrivilegeTreasuries.vue";
// import UsersPrivilegeTreasuriesTransaction from "./UsersPrivilegeTreasuriesTransaction.vue";
// import UsersPrivilegeExpenses from "./UsersPrivilegeExpenses.vue";
// import UsersPrivilegeRevenus from "./UsersPrivilegeRevenus.vue";
import UsersPrivilegeCustodies from "./UsersPrivilegeCustodies.vue";
import UsersPrivilegAdditionalSettings from "./UsersPrivilegAdditionalSettings.vue";
import UsersPrivilegeUsers from "./UsersPrivilegeUsers.vue";
import UsersPrivilegeEmployeeSalarySetting from "./UsersPrivilegeEmployeeSalarySetting.vue";
import UsersPrivilegeEmployeeWorkSchedule from "./UsersPrivilegeEmployeeWorkSchedule.vue";
import UsersPrivilegeEmployeeBranches from "./UsersPrivilegeEmployeeBranches.vue";
// import UsersPrivilegeEmployeeOfficialHolidaysPlans from "./UsersPrivilegeEmployeeOfficialHolidaysPlans.vue";
import UsersPrivilegeEstablishmentYearSetting from "./UsersPrivilegeEstablishmentYearSetting.vue";
import EstablishmentRole from "@/models/settings/settingsOther/establishmentRoles/EstablishmentRole";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "EstablishmentPrivilegeForm",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    CustomSelectBox,
    UsersPrivilegeEmployees,
    UsersPrivilegeEmployeesAttendance,
    UsersPrivilegeEmployeesMovement,
    UsersPrivilegeEmployeesMovementMedai,
    UsersPrivilegeEmployeesSettlementMovement,
    UsersPrivilegeEmployeesMoveSlides,
    // UserPrivillegBranchesSettings,
    UsersPrivilegeEstablishmentsSettings,
    // UsersPrivilegeClients,
    // UsersPrivilegeNews,
    // UsersPrivilegeOffers,
    UsersPrivilegeBranches,
    // UsersPrivilegeOfficesTimes,
    // UsersPrivilegeServicesReservations,
    // UsersPrivilegeServicesReservationTreatmentPlan,
    // UsersPrivilegeTreasuries,
    // UsersPrivilegeTreasuriesTransaction,
    // UsersPrivilegeExpenses,
    // UsersPrivilegeRevenus,
    UsersPrivilegeCustodies,
    UsersPrivilegAdditionalSettings,
    UsersPrivilegeUsers,
    UsersPrivilegeEmployeeSalarySetting,
    UsersPrivilegeEmployeeWorkSchedule,
    UsersPrivilegeEmployeeBranches,
    // UsersPrivilegeEmployeeOfficialHolidaysPlans,
    UsersPrivilegeEstablishmentYearSetting,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      establishmentRoleData: new EstablishmentRole(),
      establishmentRoleTokenOptions: [],
    };
  },
  props: ["userEstablishmentPrivilegeDate", "submitName"],
  methods: {
    async changeEstablishmentRoleToken(establishmentRoleToken) {
      this.userEstablishmentPrivilegeDate.establishmentRoleToken =
        establishmentRoleToken;
      this.getUserEstablishmentPrivilege();
    },
    async getDialogOfEstablishmentRoles() {
      this.isLoading = true;
      this.establishmentRoleTokenOptions = [];
      this.establishmentRoleTokenOptions.push({
        value: "",
        text: this.$t("setting.others.establishmentRolesMsgs.select"),
      });
      try {
        let response =
          await this.establishmentRoleData.getDialogOfEstablishmentRoles(
            this.language,
            this.userAuthorizeToken
          );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.establishmentRoleTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getUserEstablishmentPrivilege() {
      this.isLoading = true;
      try {
        let response =
          await this.userEstablishmentPrivilegeDate.getUserEstablishmentPrivilege(
            this.language,
            this.userAuthorizeToken
          );
        if (response.data.status == STATUS.SUCCESS) {
          this.userEstablishmentPrivilegeDate.fillData(
            response.data.usersEstablishmentPrivilegeData
          );
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async updateUserEstablishmentPrivilege() {
      this.$emit("updateUserEstablishmentPrivilege");
    },
  },
  async created() {
    this.getDialogOfEstablishmentRoles();
  },
};
</script>

<style scoped lang="scss"></style>
