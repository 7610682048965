<template>
  <div v-if="deductionsPlansSlidesDetailsData" class="">
    <div class="table-container">
      <table class="my-table">
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t("deductionsPlanDetails.maxFrom") }}</th>
            <th>{{ $t("deductionsPlanDetails.maxTo") }}</th>
            <th>{{ $t("deductionsPlanDetails.hourfactor") }}</th>
            <th>{{ $t("salaryEffectName") }}</th>
            <th>{{ $t("deductions.effectOnEmployeeSalaryStatus") }}</th>
            <th>{{ $t("deductions.slideType") }}</th>
            <th>{{ $t("deductions.fullShiftDeductionStatus") }}</th>
            <th>{{ $t("info") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(
              deductionsPlansSlide, index
            ) in deductionsPlansSlidesDetailsData"
            :key="index"
          >
            <td>{{ ++index }}</td>
            <td>
              {{
                convertTimeAndDaysToString(
                  deductionsPlansSlide.fromNumberInSeconds,
                  deductionsPlansSlide.fromNumberInMinutes,
                  deductionsPlansSlide.fromNumberInHours,
                  deductionsPlansSlide.fromNumberInDays
                )
              }}
            </td>
            <td>
              {{
                convertTimeAndDaysToString(
                  deductionsPlansSlide.toNumberInSeconds,
                  deductionsPlansSlide.toNumberInMinutes,
                  deductionsPlansSlide.toNumberInHours,
                  deductionsPlansSlide.toNumberInDays
                )
              }}
            </td>

            <td>
              {{ isDataExist(deductionsPlansSlide.hourfactor) }}
            </td>
            <td>
              {{
                isDataExist(
                  deductionsPlansSlide.employeeSalaryEffectNameCurrent
                )
              }}
            </td>
            <td>
              {{
                deductionsPlansSlide.effectOnEmployeeSalaryStatus
                  ? $t("yes")
                  : $t("no")
              }}
            </td>
            <td>
              {{ isDataExist(deductionsPlansSlide.slideTypeNameCurrent) }}
            </td>
            <td>
              {{
                deductionsPlansSlide.fullShiftDeductionStatus
                  ? $t("yes")
                  : $t("no")
              }}
            </td>
            <td>
              <button
                v-b-modal.DeductionsPlanDetailsInfo
                class="btn p-0"
                :title="$t('info')"
                @click="
                  setDeductionsPlansSlideDetailsData(deductionsPlansSlide)
                "
              >
                <img src="@/assets/images/info.svg" class="icon-lg" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import privilegeMixin from "@/utils/privilege-mixin";
import {
  setDataMultiLang,
  timeToLang,
  convertTimeAndDaysToString,
  isDataExist,
} from "@/utils/functions";

export default {
  name: "DeductionsPlanDetailsTable",
  mixins: [privilegeMixin],
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: [
    "deductionsPlansSlidesDetailsData",
    "deductionsPlansSlideDetailsData",
  ],
  methods: {
    setDeductionsPlansSlideDetailsData(deductionsPlansSlideDetailsData) {
      this.$emit(
        "setDeductionsPlansSlideDetailsData",
        deductionsPlansSlideDetailsData
      );
    },
    timeToLang(time) {
      return timeToLang(time, this.language);
    },
    setDataMultiLang(lang, dataAr, dataEn, dataUnd) {
      return setDataMultiLang(lang, dataAr, dataEn, dataUnd);
    },
    convertTimeAndDaysToString(seconds, minutes, hours, days) {
      return convertTimeAndDaysToString(seconds, minutes, hours, days).data.data
        .string;
    },
    isDataExist(data) {
      return isDataExist(data);
    },
  },
  computed: {},
};
</script>

<style lang="scss"></style>
