export default class UsersPrivilegeStores {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.storesView = false;
    this.storesAdd = false;
    this.storesUpdate = false;
    this.storesArchive = false;
    this.storesRestore = false;
    this.storesDelete = false;
  }
  fillData(data) {
    if (data) {
      this.storesView = data.storesView;
      this.storesAdd = data.storesAdd;
      this.storesUpdate = data.storesUpdate;
      this.storesArchive = data.storesArchive;
      this.storesRestore = data.storesRestore;
      this.storesDelete = data.storesDelete;
    } else {
      this.setInitialValue();
    }
  }
}
