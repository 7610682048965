// import { PAGE_SIZE } from "@/utils/constants";
// import axios from "axios";

export default class DeductionsPlansSlideDetails {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.fromNumberInDays = 0;
    this.fromNumberInHours = 0;
    this.fromNumberInMinutes = 0;
    this.fromNumberInSeconds = 0;
    this.toNumberInDays = "";
    this.toNumberInHours = "";
    this.toNumberInMinutes = "";
    this.toNumberInSeconds = "";
    this.hourfactor = "";
    this.slideTypeToken = "";
    this.slideTypeNameCurrent = "";
    this.slideTypeNameAr = "";
    this.slideTypeNameEn = "";
    this.slideTypeNameUnd = "";
    this.employeeSalaryEffectToken = "";
    this.employeeSalaryEffectNameCurrent = "";
    this.effectOnEmployeeSalaryStatus = false;
    this.fullShiftDeductionStatus = false;
    this.deductionsPlansDetailsSlideArchiveStatus = false;
  }
  fillData(data) {
    this.fromNumberInDays = data.fromNumberInDays;
    this.fromNumberInHours = data.fromNumberInHours;
    this.fromNumberInMinutes = data.fromNumberInMinutes;
    this.fromNumberInSeconds = data.fromNumberInSeconds;
    this.toNumberInDays = data.toNumberInDays;
    this.toNumberInHours = data.toNumberInHours;
    this.toNumberInMinutes = data.toNumberInMinutes;
    this.toNumberInSeconds = data.toNumberInSeconds;
    this.hourfactor = data.hourfactor;
    this.slideTypeToken = data.slideTypeToken;
    this.slideTypeNameCurrent = data.slideTypeNameCurrent;
    this.slideTypeNameAr = data.slideTypeNameAr;
    this.slideTypeNameEn = data.slideTypeNameEn;
    this.slideTypeNameUnd = data.slideTypeNameUnd;
    this.employeeSalaryEffectToken = data.employeeSalaryEffectToken;
    this.employeeSalaryEffectNameCurrent = data.employeeSalaryEffectNameCurrent;
    this.effectOnEmployeeSalaryStatus = data.effectOnEmployeeSalaryStatus;
    this.fullShiftDeductionStatus = data.fullShiftDeductionStatus;
    this.deductionsPlansDetailsSlideArchiveStatus =
      data.deductionsPlansDetailsSlideArchiveStatus;
  }

  // async getAllDeductionsPlansSlidesDetails(
  //   language,
  //   userAuthorizeToken,
  //   selfPage,
  //   textSearch
  // ) {
  //   try {
  //     let response = await axios.get(
  //       `/DeductionsPlansSlidesDetails/GetAllDeductionsPlansSlidesDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&page=${selfPage}&paginationStatus=true&pageSize=${PAGE_SIZE}&filterStatus=true&textSearch=${textSearch}`
  //     );
  //     return response;
  //   } catch (error) {
  //     return this.$t("errorCatch");
  //   }
  // }

  // async getDeductionsPlansSlidesDetailsDetails(language, userAuthorizeToken) {
  //   try {
  //     let response = await axios.get(
  //       `/DeductionsPlansSlidesDetails/GetDeductionsPlansSlidesDetailsDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&deductionsPlansSlidesDetailsToken=${this.deductionsPlansSlidesDetailsToken}`
  //     );
  //     return response;
  //   } catch (error) {
  //     return this.$t("errorCatch");
  //   }
  // }

  // async addOrUpdateDeductionsPlansSlidesDetails(language, userAuthorizeToken) {
  //   var data = {
  //     language: language,
  //     userAuthorizeToken: userAuthorizeToken,
  //     deductionsPlansSlidesDetailsToken: this.deductionsPlansSlidesDetailsToken,
  //     deductionsPlansToken: this.deductionsPlansToken,
  //     deductionTypeToken: this.deductionTypeToken,
  //     employeeSalaryEffectToken: this.employeeSalaryEffectToken,
  //     deductionNumberInDays: this.deductionNumberInDays,
  //     deductionNumberInHours: this.deductionNumberInHours,
  //     deductionNumberInMinutes: this.deductionNumberInMinutes,
  //     deductionNumberInSeconds: this.deductionNumberInSeconds,
  //     deductionsPlansSlidesDetailsNote: this.deductionsPlansSlidesDetailsNote,
  //   };

  //   try {
  //     let response = "";
  //     if (
  //       this.deductionsPlansSlidesDetailsToken == "" ||
  //       this.deductionsPlansSlidesDetailsToken == undefined
  //     ) {
  //       response = await axios.post(
  //         `/DeductionsPlansSlidesDetails//AddDeductionsPlansSlidesDetails`,
  //         data
  //       );
  //     } else {
  //       response = await axios.post(
  //         `/DeductionsPlansSlidesDetails//UpdatDeductionsPlansSlidesDetails`,
  //         data
  //       );
  //     }
  //     return response;
  //   } catch (e) {
  //     return this.$t("errorCatch");
  //   }
  // }

  // async archiveDeductionsPlansSlidesDetails(language, userAuthorizeToken) {
  //   var data = {
  //     language: language,
  //     userAuthorizeToken: userAuthorizeToken,
  //     deductionsPlansSlidesDetailsToken: this.deductionsPlansSlidesDetailsToken,
  //   };

  //   try {
  //     let response = await axios.post(
  //       `/DeductionsPlansSlidesDetails//ArchiveDeductionsPlansSlidesDetails`,
  //       data
  //     );
  //     return response;
  //   } catch (e) {
  //     return this.$t("errorCatch");
  //   }
  // }
}
