export default class UsersPrivlageTreasuriesTransactions {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.treasuriesTransactionsReportView = false;
    this.treasuryTransactionsAdd = false;
    this.treasuryTransactionsView = false;
  }
  fillData(data) {
    this.treasuriesTransactionsReportView =
      data.treasuriesTransactionsReportView;
    this.treasuryTransactionsAdd = data.treasuryTransactionsAdd;
    this.treasuryTransactionsView = data.treasuryTransactionsView;
  }
}
