<template>
  <div v-if="workPlanDaysData" class="">
    <div
      v-for="(workPlanDay, index) in workPlanDaysData"
      :key="index"
      class="container-collapse-with-btns"
    >
      <b-button
        v-b-toggle="'id' + index"
        class="btn btn-lg btn-collapse collapse-data"
      >
        {{
          `${workPlanDay.dayNameCurrent} ( ${workPlanDay.dayStatusNameCurrent} )`
        }}
        <i class="fa fa-angle-down" aria-hidden="true"></i>
      </b-button>
      <div class="collapse-actions btn-collapse">
        <div class="">
          <button
            v-b-modal.WorkPlanDayEdit
            class="btn p-0"
            :title="$t('workPlanDayEdit')"
            @click="setWorkPlanDayData(workPlanDay)"
          >
            <img src="@/assets/images/pencil.svg" class="icon-lg" />
          </button>
        </div>
        <div class="">
          <router-link
            :to="`/edit-work-plan-shifts/${workPlanDay.workPlanDayToken}`"
            :title="$t('WorkPlanShiftsEdit')"
          >
            <img src="@/assets/images/pencil2.svg" class="icon-lg" />
          </router-link>
        </div>
        <div class="">
          <button
            v-b-modal.WorkPlanShiftsDeleteAll
            class="btn p-0"
            :title="$t('WorkPlanShiftsDeleteAll')"
            @click="setWorkPlanDayData(workPlanDay)"
          >
            <img src="@/assets/images/trash.svg" class="icon-lg" />
          </button>
        </div>
      </div>
      <b-collapse :id="'id' + index">
        <div class="my-card table-container">
          <table class="my-table">
            <tbody>
              <tr>
                <th>
                  {{
                    `${$t("maximumAllowedAttendLateInDay")} (${isDataExist(
                      workPlanDay.employeeMaximumAllowedAttendLateInDayByHours24
                    )})`
                  }}
                </th>
                <th>
                  {{
                    `${$t("activeStatus")} (${
                      workPlanDay.employeeMaximumAllowedAttendLateInDayStatus
                        ? $t("yes")
                        : $t("no")
                    })`
                  }}
                </th>
              </tr>
              <tr>
                <th>
                  {{
                    `${$t("maximumAllowedEarlyDepartureInDay")} (${isDataExist(
                      workPlanDay.employeeMaximumAllowedEarlyDepartureInDayByHours24
                    )})`
                  }}
                </th>
                <th>
                  {{
                    `${$t("activeStatus")} (${
                      workPlanDay.employeeMaximumAllowedEarlyDepartureInDayStatus
                        ? $t("yes")
                        : $t("no")
                    })`
                  }}
                </th>
              </tr>
              <tr>
                <th>
                  {{
                    `${$t("maximumAllowedOverTimeInDay")} (${isDataExist(
                      workPlanDay.employeeMaximumAllowedOverTimeInDayByHours24
                    )})`
                  }}
                </th>
                <th>
                  {{
                    `${$t("activeStatus")} (${
                      workPlanDay.employeeMaximumAllowedOverTimeInDayStatus
                        ? $t("yes")
                        : $t("no")
                    })`
                  }}
                </th>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          class="table-container"
          v-if="workPlanDay.WorkPlansShiftsData.length != 0"
        >
          <table class="my-table">
            <thead>
              <tr>
                <th rowspan="2">#</th>
                <th rowspan="2">{{ $t("workShiftName") }}</th>
                <th colspan="2">{{ $t("workShiftFrom") }}</th>
                <th colspan="2">{{ $t("workShiftTo") }}</th>
                <th rowspan="2">{{ $t("workShiftDuration") }}</th>
                <th rowspan="2">{{ $t("WorkShiftType") }}</th>
                <th rowspan="2">{{ $t("info") }}</th>
              </tr>
              <tr>
                <th>{{ $t("day") }}</th>
                <th>{{ $t("time") }}</th>
                <th>{{ $t("day") }}</th>
                <th>{{ $t("time") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(
                  workPlanShift, index2
                ) in workPlanDay.WorkPlansShiftsData"
                :key="index2"
              >
                <td>{{ ++index2 }}</td>
                <td>
                  {{ isDataExist(workPlanShift.workPlanShiftNameCurrent) }}
                </td>
                <td>{{ isDataExist(workPlanShift.dayFromNameCurrent) }}</td>
                <td>{{ timeToLang(workPlanShift.workShiftTimeFrom) }}</td>
                <td>{{ isDataExist(workPlanShift.dayToNameCurrent) }}</td>
                <td>{{ timeToLang(workPlanShift.workShiftTimeTo) }}</td>
                <td>{{ isDataExist(workPlanShift.workShiftDuration) }}</td>
                <td>
                  {{ isDataExist(workPlanShift.workShiftTypeNameCurrent) }}
                </td>
                <td>
                  <button
                    v-b-modal.WorkPlanShiftInfo
                    class="btn p-0"
                    :title="$t('info')"
                    @click="setWorkPlanShiftData(workPlanShift)"
                  >
                    <img src="@/assets/images/info.svg" class="icon-lg" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { setDataMultiLang, timeToLang, isDataExist } from "@/utils/functions";
// import DataLabelGroup from "@/components/general/DataLabelGroup.vue";

export default {
  name: "WorkPlanDaysTable",
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["workPlanDaysData", "workPlanDayData"],
  components: {
    // DataLabelGroup,
  },
  methods: {
    setWorkPlanDayData(workPlanDayData) {
      this.$emit("setWorkPlanDayData", workPlanDayData);
    },
    setWorkPlanShiftData(workPlanShiftData) {
      this.$emit("setWorkPlanShiftData", workPlanShiftData);
    },
    timeToLang(time) {
      return timeToLang(time, this.language);
    },
    setDataMultiLang(lang, dataAr, dataEn, dataUnd) {
      return setDataMultiLang(lang, dataAr, dataEn, dataUnd);
    },
    isDataExist(data) {
      return isDataExist(data);
    },
  },
  computed: {},
};
</script>

<style lang="scss"></style>
