import { PAGE_SIZE } from "@/utils/constants";
import { getTimeSeparated } from "@/utils/functions";
import TimeSeparated from "@/models/general/TimeSeparated";
import axios from "axios";

export default class WorkPlanDay {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.workPlanDayToken = "";
    this.workPlanToken = "";
    this.dayToken = "";
    this.dayNameCurrent = "";
    this.dayNameAr = "";
    this.dayNameEn = "";
    this.dayNameUnd = "";
    this.dayStatusToken = "";
    this.dayStatusNameCurrent = "";
    this.dayStatusNameAr = "";
    this.dayStatusNameEn = "";
    this.dayStatusNameUnd = "";
    this.employeeMaximumAllowedAttendLateInDayByHours24 = "";
    this.employeeMaximumAllowedAttendLateInDayStatus = false;
    this.employeeMaximumAllowedEarlyDepartureInDayByHours24 = "";
    this.employeeMaximumAllowedEarlyDepartureInDayStatus = false;
    this.employeeMaximumAllowedOverTimeInDayByHours24 = "";
    this.employeeMaximumAllowedOverTimeInDayStatus = false;
    this.workDayFactor = 0;
    this.workDayDeductionFactor = 0;
    this.workPlanDayArchiveStatus = "";
    this.WorkPlansShiftsData = [];
    this.employeeMaximumAllowedAttendLateInDayByHours24Time =
      new TimeSeparated();
    this.employeeMaximumAllowedEarlyDepartureInDayByHours24Time =
      new TimeSeparated();
    this.employeeMaximumAllowedOverTimeInDayByHours24Time = new TimeSeparated();
  }
  fillData(data) {
    this.workPlanDayToken = data.workPlanDayToken;
    this.workPlanToken = data.workPlanToken;
    this.dayToken = data.dayToken;
    this.dayNameCurrent = data.dayNameCurrent;
    this.dayNameAr = data.dayNameAr;
    this.dayNameEn = data.dayNameEn;
    this.dayNameUnd = data.dayNameUnd;
    this.dayStatusToken = data.dayStatusToken;
    this.dayStatusNameCurrent = data.dayStatusNameCurrent;
    this.dayStatusNameAr = data.dayStatusNameAr;
    this.dayStatusNameEn = data.dayStatusNameEn;
    this.dayStatusNameUnd = data.dayStatusNameUnd;
    this.employeeMaximumAllowedAttendLateInDayByHours24 =
      data.employeeMaximumAllowedAttendLateInDayByHours24;
    this.employeeMaximumAllowedAttendLateInDayStatus =
      data.employeeMaximumAllowedAttendLateInDayStatus;
    this.employeeMaximumAllowedEarlyDepartureInDayByHours24 =
      data.employeeMaximumAllowedEarlyDepartureInDayByHours24;
    this.employeeMaximumAllowedEarlyDepartureInDayStatus =
      data.employeeMaximumAllowedEarlyDepartureInDayStatus;
    this.employeeMaximumAllowedOverTimeInDayByHours24 =
      data.employeeMaximumAllowedOverTimeInDayByHours24;
    this.employeeMaximumAllowedOverTimeInDayStatus =
      data.employeeMaximumAllowedOverTimeInDayStatus;
    this.workDayFactor = data.workDayFactor;
    this.workDayDeductionFactor = data.workDayDeductionFactor;
    this.workPlanDayArchiveStatus = data.workPlanDayArchiveStatus;
    this.WorkPlansShiftsData = data.WorkPlansShiftsData;

    this.employeeMaximumAllowedAttendLateInDayByHours24Time.fillData(
      getTimeSeparated(data.employeeMaximumAllowedAttendLateInDayByHours24)
    );
    this.employeeMaximumAllowedEarlyDepartureInDayByHours24Time.fillData(
      getTimeSeparated(data.employeeMaximumAllowedEarlyDepartureInDayByHours24)
    );
    this.employeeMaximumAllowedOverTimeInDayByHours24Time.fillData(
      getTimeSeparated(data.employeeMaximumAllowedOverTimeInDayByHours24)
    );
  }

  async getAllWorkPlanDays(
    language,
    userAuthorizeToken,
    selfPage,
    textSearch,
    workPlanToken = ""
  ) {
    try {
      let response = await axios.get(
        `/WorkPlansDays/GetAllWorkPlansDays?language=${language}&userAuthorizeToken=${userAuthorizeToken}&page=${selfPage}&pageSize=${PAGE_SIZE}&filterStatus=true&workPlanToken=${workPlanToken}&textSearch=${textSearch}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getWorkPlanDayDetails(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/WorkPlansDays/GetWorkPlanDayDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&workPlanDayToken=${this.workPlanDayToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async updateWorkPlanDay(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      workPlanDayToken: this.workPlanDayToken,
      dayStatusToken: this.dayStatusToken,
      employeeMaximumAllowedAttendLateInDayByHours24:
        this.employeeMaximumAllowedAttendLateInDayByHours24,
      employeeMaximumAllowedAttendLateInDayStatus:
        this.employeeMaximumAllowedAttendLateInDayStatus,
      employeeMaximumAllowedEarlyDepartureInDayByHours24:
        this.employeeMaximumAllowedEarlyDepartureInDayByHours24,
      employeeMaximumAllowedEarlyDepartureInDayStatus:
        this.employeeMaximumAllowedEarlyDepartureInDayStatus,
      employeeMaximumAllowedOverTimeInDayByHours24:
        this.employeeMaximumAllowedOverTimeInDayByHours24,
      employeeMaximumAllowedOverTimeInDayStatus:
        this.employeeMaximumAllowedOverTimeInDayStatus,
      workDayFactor: this.workDayFactor,
      workDayDeductionFactor: this.workDayDeductionFactor,
    };

    try {
      return await axios.post(`/WorkPlansDays/UpdateWorkPlanDay`, data);
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async updateWorkPlanShifts(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      workPlanDayToken: this.workPlanDayToken,
      workShiftListDto: this.WorkPlansShiftsData,
    };

    try {
      return await axios.post(`/WorkPlansShifts/UpdateWorkPlanShift`, data);
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
  async finalDeleteAllWorkPlansShifts(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      workPlanDayToken: this.workPlanDayToken,
    };

    try {
      let response = await axios.post(
        `/WorkPlansShifts/FinalDeleteAllWorkPlansShifts`,
        data
      );
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
