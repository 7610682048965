<template>
  <b-modal
    id="EmployeeSalarySettingInfo"
    scrollable
    size="lg"
    centered
    hide-footer
  >
    <template #modal-title>
      <h3>
        <img src="@/assets/images/money.svg" class="icon-lg" />
        {{ $t("employeeSalarySettingData") }}
      </h3>
    </template>
    <div class="row">
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="employeeSalarySettingData.employeeNameCurrent"
        :title="$t('employeeName')"
        :imgName="'man.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeSalarySettingData.employeeSalarySettingNameCurrentAr"
        :title="$t('salarySettingNameAr')"
        :imgName="'money.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeSalarySettingData.employeeSalarySettingNameCurrentEn"
        :title="$t('salarySettingNameEn')"
        :imgName="'money.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeSalarySettingData.employeeSalarySettingNameCurrentUnd"
        :title="$t('salarySettingNameUnd')"
        :imgName="'money.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          employeeSalarySettingData.employeeSalarySettingActivationStatus ===
          true
            ? $t('yes')
            : $t('no')
        "
        :title="$t('employeeSalarySettingActivationStatus')"
        :imgName="'type.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeSalarySettingData.employeeSalarySettingDescriptionAr"
        :title="$t('employeeSalarySettingDescriptionAr')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeSalarySettingData.employeeSalarySettingDescriptionEn"
        :title="$t('employeeSalarySettingDescriptionEn')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeSalarySettingData.employeeSalarySettingDescriptionUnd"
        :title="$t('employeeSalarySettingDescriptionUnd')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeSalarySettingData.employeeSalarySettingNotes"
        :title="$t('notes')"
        :imgName="'notes.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeSalarySettingData.employeeInsuranceTypeNameCurrent"
        :title="$t('insuranceType')"
        :imgName="'type.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="moneyFormat(employeeSalarySettingData.employeeTotalSalary)"
        :title="$t('employeeTotalSalary')"
        :imgName="'money.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="moneyFormat(employeeSalarySettingData.employeeBasicSalary)"
        :title="$t('employeeBasicSalary')"
        :imgName="'money.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="moneyFormat(employeeSalarySettingData.employeeVariableSalary)"
        :title="$t('employeeVariableSalary')"
        :imgName="'money.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeSalarySettingData.employeeWorkDaysNumberPerMonth"
        :title="$t('employeeWorkDaysNumberPerMonth')"
        :imgName="'number.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeSalarySettingData.employeeWorkHoursNumberPerDay"
        :title="$t('employeeWorkHoursNumberPerDay')"
        :imgName="'hours.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="moneyFormat(employeeSalarySettingData.employeeWorkHourPrice)"
        :title="$t('employeeWorkHourPrice')"
        :imgName="'money.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          moneyFormat(employeeSalarySettingData.employeePriceUnKnownWorkhour)
        "
        :title="$t('employeePriceUnKnownWorkhour')"
        :imgName="'money.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          $t('days') +
          ': ( ' +
          employeeSalarySettingData.employeeMaximumAllowedOverTimeInMonthByDays +
          ' ) / ' +
          $t('hours') +
          ': ( ' +
          employeeSalarySettingData.employeeMaximumAllowedOverTimeInMonthByHours24 +
          ' )'
        "
        :title="$t('maximumAllowedOverTimeInMonth')"
        :imgName="'dateAndTime.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          employeeSalarySettingData.employeeMaximumAllowedOverTimeInMonthStatus ===
          true
            ? $t('yes')
            : $t('no')
        "
        :title="$t('activationStatus')"
        :imgName="'type.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          employeeSalarySettingData.employeeMaximumAllowedOverTimeInMonthByDays
        "
        :title="$t('maximumAllowedOverTimeInMonth')"
        :imgName="'hours.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          employeeSalarySettingData.employeeMaximumAllowedOverTimeInMonthStatus ===
          true
            ? $t('yes')
            : $t('no')
        "
        :title="$t('activationStatus')"
        :imgName="'type.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          employeeSalarySettingData.employeeMaximumAllowedShiftAbsenceUntillSalaryStop
        "
        :title="$t('maximumAllowedShiftAbsenceUntillSalaryStop')"
        :imgName="'number.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          employeeSalarySettingData.employeeMaximumAllowedShiftAbsenceUntillSalaryStopStatus ===
          true
            ? $t('yes')
            : $t('no')
        "
        :title="$t('activationStatus')"
        :imgName="'type.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeSalarySettingData.employeeBalancePlanNameCurrent"
        :title="$t('balancesPlansName')"
        :imgName="'balances-plans.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeSalarySettingData.employeeAbsencePlanNameCurrent"
        :title="$t('absencePlansName')"
        :imgName="'absence-plans.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeSalarySettingData.employeeAllowancePlanNameCurrent"
        :title="$t('allowancesPlansName')"
        :imgName="'allowances-plans.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          employeeSalarySettingData.employeeMonthAllowancesPlanNameCurrent
        "
        :title="$t('monthAllowancesPlansName')"
        :imgName="'month-allowances-plans.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeSalarySettingData.employeeDeductionsPlanNameCurrent"
        :title="$t('deductions.name')"
        :imgName="'deductions.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          formateDateTimeLang(
            employeeSalarySettingData.actionDate,
            employeeSalarySettingData.actionTime
          )
        "
        :title="$t('actionDateTime')"
        :imgName="'dateAndTime.svg'"
      />
    </div>
  </b-modal>
</template>

<script>
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";
import {
  setDataMultiLang,
  getTimeFormatInMinSec,
  moneyFormat,
  formateDateTimeLang,
} from "@/utils/functions";

export default {
  name: "EmployeeSalarySettingInfo",
  components: {
    DataLabelGroup,
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["employeeSalarySettingData"],
  methods: {
    setDataMultiLang,
    getTimeFormatInMinSec,
    moneyFormat,
    formateDateTimeLang(date, time) {
      return formateDateTimeLang(date, time, this.language);
    },
  },
};
</script>

<style scoped lang="scss"></style>
