export default class UsersPrivilegeEmployeesAttendance {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.employeesAttendanceView = false;
    this.employeesAttendanceViewSelf = false;
    this.employeesAttendanceViewAll = false;
    this.employeesAttendanceAttendanceManual = false;
    this.employeesAttendanceAttendanceList = false;
    this.employeesAttendanceDepatureList = false;
    this.employeesAttendanceUpdate = false;
    this.employeesAttendanceDelete = false;
    this.employeesAttendanceAttendedSelf = false;
    this.employeesAttendanceDepartureSelf = false;
  }
  fillData(data) {
    if (data) {
      this.employeesAttendanceView = data.employeesAttendanceView;
      this.employeesAttendanceViewSelf = data.employeesAttendanceViewSelf;
      this.employeesAttendanceViewAll = data.employeesAttendanceViewAll;
      this.employeesAttendanceAttendanceManual =
        data.employeesAttendanceAttendanceManual;
      this.employeesAttendanceAttendanceList =
        data.employeesAttendanceAttendanceList;
      this.employeesAttendanceDepatureList =
        data.employeesAttendanceDepatureList;
      this.employeesAttendanceUpdate = data.employeesAttendanceUpdate;
      this.employeesAttendanceDelete = data.employeesAttendanceDelete;
      this.employeesAttendanceAttendedSelf =
        data.employeesAttendanceAttendedSelf;
      this.employeesAttendanceDepartureSelf =
        data.employeesAttendanceDepartureSelf;
    } else {
      this.setInitialValue();
    }
  }
}
