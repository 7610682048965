import Vue from "vue";
import VueRouter from "vue-router";
import { ifAuthenticated, ifNotAuthenticated } from "../utils/functions";
import i18n from "./../i18n";

import Test from "../views/user/Test.vue";
import Home from "../views/home/Home.vue";
import Login from "../views/user/Login.vue";
// import ForgetPassword from "../views/user/ForgetPassword.vue";

import EmployeeBranches from "../views/employees/employeeBranches/EmployeeBranches.vue";
import EmployeeBranchAdd from "../views/employees/employeeBranches/EmployeeBranchAdd.vue";
import EmployeeBranchEdit from "../views/employees/employeeBranches/EmployeeBranchEdit.vue";

import EmployeeSalarySettings from "../views/employees/employeeSalarySettings/EmployeeSalarySettings.vue";
import EmployeeSalarySettingAdd from "../views/employees/employeeSalarySettings/EmployeeSalarySettingAdd.vue";
import EmployeeSalarySettingEdit from "../views/employees/employeeSalarySettings/EmployeeSalarySettingEdit.vue";

import EmployeeWorkSchedules from "../views/employees/employeeWorkSchedules/EmployeeWorkSchedules.vue";
import EmployeeWorkScheduleAdd from "../views/employees/employeeWorkSchedules/EmployeeWorkScheduleAdd.vue";

import EmployeeOfficialHolidaysPlans from "../views/employees/employeeOfficialHolidaysPlans/EmployeeOfficialHolidaysPlans.vue";
import EmployeeOfficialHolidaysPlanAdd from "../views/employees/employeeOfficialHolidaysPlans/EmployeeOfficialHolidaysPlanAdd.vue";

import EmployeesInputSheetsAttendance from "../views/employees/employeesInputSheetsAttendance/EmployeesInputSheetsAttendance.vue";
import EmployeesInputSheetAttendanceAdd from "../views/employees/employeesInputSheetsAttendance/EmployeesInputSheetAttendanceAdd.vue";

import EmployeesInputSheetAttendanceMerges from "../views/employees/employeesInputSheetAttendanceMerges/EmployeesInputSheetAttendanceMerges.vue";
import EmployeesInputSheetAttendanceMergeAdd from "../views/employees/employeesInputSheetAttendanceMerges/EmployeesInputSheetAttendanceMergeAdd.vue";

import EmployeeAttendance from "../views/employeesAttendance/EmployeeAttendance.vue";
import EmployeeAttendanceAdd from "../views/employeesAttendance/EmployeeAttendanceAdd.vue";
import EmployeeAttendanceEdit from "../views/employeesAttendance/EmployeeAttendanceEdit.vue";

import EmployeeMovement from "../views/employeesMovement/EmployeeMovement.vue";
import EmployeeMovementAdd from "../views/employeesMovement/EmployeeMovementAdd.vue";
import EmployeeMovementEdit from "../views/employeesMovement/EmployeeMovementEdit.vue";

import EmployeeMovementMedia from "../views/employeesMovementMedia/EmployeeMovementMedia.vue";
import EmployeeMovementMediaInfo from "../views/employeesMovementMedia/EmployeeMovementMediaInfo.vue";

import AttendanceHistory from "../views/attendance/AttendanceHistory.vue";
import AttendanceAttend from "../views/attendance/AttendanceAttend.vue";
import AttendanceDeparture from "../views/attendance/AttendanceDeparture.vue";

import Points from "../views/points/Points.vue";
import EmployeePoints from "../views/points/EmployeePoints.vue";

import EmployeeSlices from "../views/slices/EmployeeSlices.vue";
import EmployeeSlicesAdd from "../views/slices/EmployeeSlicesAdd.vue";

import Reports from "../views/reports/Reports.vue";

import EstablishmentPrivileges from "../views/privileges/establishmentPrivileges/EstablishmentPrivileges.vue";

import BranchPrivileges from "../views/privileges/branchPrivileges/BranchPrivileges.vue";

import Branches from "../views/branches/Branches.vue";
import BranchAdd from "../views/branches/BranchAdd.vue";
import BranchEdit from "../views/branches/BranchEdit.vue";

import Treasuries from "../views/finance/treasuries/Treasuries.vue";
import TreasuryAdd from "../views/finance/treasuries/TreasuryAdd.vue";
import TreasuryEdit from "../views/finance/treasuries/TreasuryEdit.vue";

import TreasuriesTransactions from "../views/finance/treasuriesTransactions/TreasuriesTransactions.vue";
import TreasuryTransactionAdd from "../views/finance/treasuriesTransactions/TreasuryTransactionAdd.vue";

import Revenues from "../views/finance/revenues/Revenues.vue";
import RevenueAdd from "../views/finance/revenues/RevenueAdd.vue";

import Expenses from "../views/finance/expenses/Expenses.vue";
import ExpenseAdd from "../views/finance/expenses/ExpenseAdd.vue";

import Jobs from "../views/settings/settingsOther/jobs/Jobs.vue";
import JobsAdd from "../views/settings/settingsOther/jobs/JobsAdd.vue";
import JobsEdit from "../views/settings/settingsOther/jobs/JobsEdit.vue";

import Qualifications from "../views/settings/settingsOther/qualifications/Qualifications.vue";
import QualificationsAdd from "../views/settings/settingsOther/qualifications/QualificationsAdd.vue";
import QualificationsEdit from "../views/settings/settingsOther/qualifications/QualificationsEdit.vue";

import RevenuesTypes from "../views/settings/settingsOther/revenuesTypes/RevenuesTypes.vue";
import RevenueTypeAdd from "../views/settings/settingsOther/revenuesTypes/RevenueTypeAdd.vue";
import RevenueTypeEdit from "../views/settings/settingsOther/revenuesTypes/RevenueTypeEdit.vue";

import ExpensesTypes from "../views/settings/settingsOther/expensesTypes/ExpensesTypes.vue";
import ExpenseTypeAdd from "../views/settings/settingsOther/expensesTypes/ExpenseTypeAdd.vue";
import ExpenseTypeEdit from "../views/settings/settingsOther/expensesTypes/ExpenseTypeEdit.vue";

import EffectTypes from "../views/settings/settingsOther/effectTypes/EffectTypes.vue";
import EffectTypeAdd from "../views/settings/settingsOther/effectTypes/EffectTypeAdd.vue";
import EffectTypeEdit from "../views/settings/settingsOther/effectTypes/EffectTypeEdit.vue";

import ServicesTypes from "../views/settings/settingsOther/servicesTypes/ServicesTypes.vue";
import ServicesTypeAdd from "../views/settings/settingsOther/servicesTypes/ServicesTypeAdd.vue";
import ServiceTypeEdit from "../views/settings/settingsOther/servicesTypes/ServiceTypeEdit.vue";

import GeneralSpecialties from "../views/settings/settingsOther/generalSpecialties/GeneralSpecialties.vue";
import GeneralSpecialtyAdd from "../views/settings/settingsOther/generalSpecialties/GeneralSpecialtyAdd.vue";
import GeneralSpecialtyEdit from "../views/settings/settingsOther/generalSpecialties/GeneralSpecialtyEdit.vue";

import NewsCategories from "../views/settings/settingsOther/newsCategories/NewsCategories.vue";
import NewsCategoryAdd from "../views/settings/settingsOther/newsCategories/NewsCategoryAdd.vue";
import NewsCategoryEdit from "../views/settings/settingsOther/newsCategories/NewsCategoryEdit.vue";

import SpecialSpecialties from "../views/settings/settingsOther/specialSpecialties/SpecialSpecialties.vue";
import SpecialSpecialtyAdd from "../views/settings/settingsOther/specialSpecialties/SpecialSpecialtyAdd.vue";
import SpecialSpecialtyEdit from "../views/settings/settingsOther/specialSpecialties/SpecialSpecialtyEdit.vue";

import Services from "../views/settings/settingsOther/services/Services.vue";
import ServiceAdd from "../views/settings/settingsOther/services/ServiceAdd.vue";
import ServiceEdit from "../views/settings/settingsOther/services/ServiceEdit.vue";

import WorkPlans from "../views/settings/settingsSalary/workPlans/WorkPlans.vue";
import WorkPlanAdd from "../views/settings/settingsSalary/workPlans/WorkPlanAdd.vue";
import WorkPlanEdit from "../views/settings/settingsSalary/workPlans/WorkPlanEdit.vue";

import WorkPlanDays from "../views/settings/settingsSalary/workPlanDays/WorkPlanDays.vue";
import WorkPlanShiftsEdit from "../views/settings/settingsSalary/workPlanDays/WorkPlanShiftsEdit.vue";

import SalaryEffects from "../views/settings/settingsSalary/salaryEffects/SalaryEffects.vue";
import SalaryEffectAdd from "../views/settings/settingsSalary/salaryEffects/SalaryEffectAdd.vue";
import SalaryEffectEdit from "../views/settings/settingsSalary/salaryEffects/SalaryEffectEdit.vue";

import OfficialHolidays from "../views/settings/settingsSalary/officialHolidays/OfficialHolidays.vue";
import OfficialHolidayAdd from "../views/settings/settingsSalary/officialHolidays/OfficialHolidayAdd.vue";
import OfficialHolidayEdit from "../views/settings/settingsSalary/officialHolidays/OfficialHolidayEdit.vue";

import BalancesPlans from "../views/settings/settingsSalary/balancesPlans/BalancesPlans.vue";
import BalancesPlanAdd from "../views/settings/settingsSalary/balancesPlans/BalancesPlanAdd.vue";
import BalancesPlanEdit from "../views/settings/settingsSalary/balancesPlans/BalancesPlanEdit.vue";
import BalancesPlanDetails from "../views/settings/settingsSalary/balancesPlans/BalancesPlanDetails.vue";
import BalancesPlanDetailsAdd from "../views/settings/settingsSalary/balancesPlans/BalancesPlanDetailsAdd.vue";
import BalancesPlanDetailsEdit from "../views/settings/settingsSalary/balancesPlans/BalancesPlanDetailsEdit.vue";

import DeductionsPlans from "../views/settings/settingsSalary/deductionsPlans/DeductionsPlans.vue";
import DeductionsPlanAdd from "../views/settings/settingsSalary/deductionsPlans/DeductionsPlanAdd.vue";
import DeductionsPlanEdit from "../views/settings/settingsSalary/deductionsPlans/DeductionsPlanEdit.vue";
import DeductionsPlanDetails from "../views/settings/settingsSalary/deductionsPlans/DeductionsPlanDetails.vue";
import DeductionsPlanDetailsAdd from "../views/settings/settingsSalary/deductionsPlans/DeductionsPlanDetailsAdd.vue";
import DeductionsPlanDetailsEdit from "../views/settings/settingsSalary/deductionsPlans/DeductionsPlanDetailsEdit.vue";

import Allowances from "../views/settings/settingsSalary/allowances/Allowances.vue";
import AllowanceAdd from "../views/settings/settingsSalary/allowances/AllowanceAdd.vue";
import AllowanceEdit from "../views/settings/settingsSalary/allowances/AllowanceEdit.vue";

import AllowancesPlans from "../views/settings/settingsSalary/allowancesPlans/AllowancesPlans.vue";
import AllowancesPlanAdd from "../views/settings/settingsSalary/allowancesPlans/AllowancesPlanAdd.vue";
import AllowancesPlanEdit from "../views/settings/settingsSalary/allowancesPlans/AllowancesPlanEdit.vue";
import AllowancesPlanDetails from "../views/settings/settingsSalary/allowancesPlans/AllowancesPlanDetails.vue";
import AllowancesPlanDetailsAdd from "../views/settings/settingsSalary/allowancesPlans/AllowancesPlanDetailsAdd.vue";
import AllowancesPlanDetailsEdit from "../views/settings/settingsSalary/allowancesPlans/AllowancesPlanDetailsEdit.vue";

import MonthAllowancesPlans from "../views/settings/settingsSalary/monthAllowancesPlans/MonthAllowancesPlans.vue";
import MonthAllowancesPlanAdd from "../views/settings/settingsSalary/monthAllowancesPlans/MonthAllowancesPlanAdd.vue";
import MonthAllowancesPlanEdit from "../views/settings/settingsSalary/monthAllowancesPlans/MonthAllowancesPlanEdit.vue";
import MonthAllowancesPlanDetails from "../views/settings/settingsSalary/monthAllowancesPlans/MonthAllowancesPlanDetails.vue";
import MonthAllowancesPlanDetailsAdd from "../views/settings/settingsSalary/monthAllowancesPlans/MonthAllowancesPlanDetailsAdd.vue";
import MonthAllowancesPlanDetailsEdit from "../views/settings/settingsSalary/monthAllowancesPlans/MonthAllowancesPlanDetailsEdit.vue";

import AbsencePlans from "../views/settings/settingsSalary/absencePlans/AbsencePlans.vue";
import AbsencePlanAdd from "../views/settings/settingsSalary/absencePlans/AbsencePlanAdd.vue";
import AbsencePlanEdit from "../views/settings/settingsSalary/absencePlans/AbsencePlanEdit.vue";
import AbsencePlanDetails from "../views/settings/settingsSalary/absencePlans/AbsencePlanDetails.vue";

import MainColumns from "../views/settings/settingsSalary/mainColumns/MainColumns.vue";
import MainColumnAdd from "../views/settings/settingsSalary/mainColumns/MainColumnAdd.vue";
import MainColumnEdit from "../views/settings/settingsSalary/mainColumns/MainColumnEdit.vue";

import InsurancesPlans from "../views/settings/settingsSalary/insurancesPlans/InsurancesPlans.vue";
import InsurancesPlanAdd from "../views/settings/settingsSalary/insurancesPlans/InsurancesPlanAdd.vue";
import InsurancesPlanEdit from "../views/settings/settingsSalary/insurancesPlans/InsurancesPlanEdit.vue";
import InsurancesPlanDetails from "../views/settings/settingsSalary/insurancesPlans/InsurancesPlanDetails.vue";
import InsurancesPlanDetailsAdd from "../views/settings/settingsSalary/insurancesPlans/InsurancesPlanDetailsAdd.vue";
import InsurancesPlanDetailsEdit from "../views/settings/settingsSalary/insurancesPlans/InsurancesPlanDetailsEdit.vue";

import OfficialHolidaysPlans from "../views/settings/settingsSalary/officialHolidaysPlans/OfficialHolidaysPlans.vue";
import OfficialHolidaysPlanAdd from "../views/settings/settingsSalary/officialHolidaysPlans/OfficialHolidaysPlanAdd.vue";
import OfficialHolidaysPlanEdit from "../views/settings/settingsSalary/officialHolidaysPlans/OfficialHolidaysPlanEdit.vue";
import OfficialHolidaysPlanDetails from "../views/settings/settingsSalary/officialHolidaysPlans/OfficialHolidaysPlanDetails.vue";
import OfficialHolidaysPlanDetailsAdd from "../views/settings/settingsSalary/officialHolidaysPlans/OfficialHolidaysPlanDetailsAdd.vue";
import OfficialHolidaysPlanDetailsEdit from "../views/settings/settingsSalary/officialHolidaysPlans/OfficialHolidaysPlanDetailsEdit.vue";

import CalculatePayroll from "../views/settings/settingsSalary/calculatePayroll/CalculatePayroll.vue";

// import Chambers from "../views/settings/settingsBranch/chambers/Chambers.vue";
// import ChamberAdd from "../views/settings/settingsBranch/chambers/ChamberAdd.vue";
// import ChamberEdit from "../views/settings/settingsBranch/chambers/ChamberEdit.vue";

// import Offices from "../views/settings/settingsBranch/offices/Offices.vue";
// import OfficeAdd from "../views/settings/settingsBranch/offices/OfficeAdd.vue";
// import OfficeEdit from "../views/settings/settingsBranch/offices/OfficeEdit.vue";

// import OfficesTimes from "../views/settings/settingsBranch/officesTimes/OfficesTimes.vue";
// import OfficesTimeAdd from "../views/settings/settingsBranch/officesTimes/OfficesTimeAdd.vue";
// import OfficesTimeEdit from "../views/settings/settingsBranch/officesTimes/OfficesTimeEdit.vue";

// import MedicinesTypes from "../views/settings/settingsOther/medicinesTypes/MedicinesTypes.vue";
// import MedicinesTypeAdd from "../views/settings/settingsOther/medicinesTypes/MedicinesTypeAdd.vue";
// import MedicinesTypeEdit from "../views/settings/settingsOther/medicinesTypes/MedicinesTypeEdit.vue";

// import Medicines from "../views/settings/settingsOther/medicines/Medicines.vue";
// import MedicineAdd from "../views/settings/settingsOther/medicines/MedicineAdd.vue";
// import MedicineEdit from "../views/settings/settingsOther/medicines/MedicineEdit.vue";

// import Requirements from "../views/settings/settingsOther/requirements/Requirements.vue";
// import RequirementAdd from "../views/settings/settingsOther/requirements/RequirementAdd.vue";
// import RequirementEdit from "../views/settings/settingsOther/requirements/RequirementEdit.vue";

import BloodTypes from "../views/settings/settingsOther/bloodTypes/BloodTypes.vue";
import BloodTypeAdd from "../views/settings/settingsOther/bloodTypes/BloodTypeAdd.vue";
import BloodTypeEdit from "../views/settings/settingsOther/bloodTypes/BloodTypeEdit.vue";

import MethodPaids from "../views/settings/settingsOther/methodPaids/MethodPaids.vue";
import MethodPaidAdd from "../views/settings/settingsOther/methodPaids/MethodPaidAdd.vue";
import MethodPaidEdit from "../views/settings/settingsOther/methodPaids/MethodPaidEdit.vue";

import ScientificDegrees from "../views/settings/settingsOther/scientificDegrees/ScientificDegrees.vue";
import ScientificDegreeAdd from "../views/settings/settingsOther/scientificDegrees/ScientificDegreeAdd.vue";
import ScientificDegreeEdit from "../views/settings/settingsOther/scientificDegrees/ScientificDegreeEdit.vue";

import Countries from "../views/settings/settingsOther/countries/Countries.vue";
import CountryAdd from "../views/settings/settingsOther/countries/CountryAdd.vue";
import CountryEdit from "../views/settings/settingsOther/countries/CountryEdit.vue";

import Governates from "../views/settings/settingsOther/governates/Governates.vue";
import GovernateAdd from "../views/settings/settingsOther/governates/GovernateAdd.vue";
import GovernateEdit from "../views/settings/settingsOther/governates/GovernateEdit.vue";

import Cities from "../views/settings/settingsOther/cities/Cities.vue";
import CityAdd from "../views/settings/settingsOther/cities/CityAdd.vue";
import CityEdit from "../views/settings/settingsOther/cities/CityEdit.vue";

import Districts from "../views/settings/settingsOther/districts/Districts.vue";
import DistrictAdd from "../views/settings/settingsOther/districts/DistrictAdd.vue";
import DistrictEdit from "../views/settings/settingsOther/districts/DistrictEdit.vue";

import InsuranceCompanies from "../views/settings/settingsOther/insuranceCompanies/InsuranceCompanies.vue";
import InsuranceCompanyAdd from "../views/settings/settingsOther/insuranceCompanies/InsuranceCompanyAdd.vue";
import InsuranceCompanyEdit from "../views/settings/settingsOther/insuranceCompanies/InsuranceCompanyEdit.vue";

import EstablishmentRoles from "../views/settings/settingsOther/establishmentRoles/EstablishmentRoles.vue";
import EstablishmentRoleAdd from "../views/settings/settingsOther/establishmentRoles/EstablishmentRoleAdd.vue";
import EstablishmentRoleEdit from "../views/settings/settingsOther/establishmentRoles/EstablishmentRoleEdit.vue";

import BranchRoles from "../views/settings/settingsOther/branchRoles/BranchRoles.vue";
import BranchRoleAdd from "../views/settings/settingsOther/branchRoles/BranchRoleAdd.vue";
import BranchRoleEdit from "../views/settings/settingsOther/branchRoles/BranchRoleEdit.vue";

// import StoreRoles from "../views/settings/settingsOther/storeRoles/StoreRoles.vue";
// import StoreRoleAdd from "../views/settings/settingsOther/storeRoles/StoreRoleAdd.vue";
// import StoreRoleEdit from "../views/settings/settingsOther/storeRoles/StoreRoleEdit.vue";

Vue.use(VueRouter);

const routes = [
  //#region General
  {
    path: "/test",
    name: "Test",
    component: Test,
    meta: {
      pageName: i18n.t("homePage"),
      pageIcon: "homepage.svg",
      currentPage: "homePage",
      currentSubPage: "",
    },
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: ifAuthenticated,
    meta: {
      pageName: i18n.t("homePage"),
      pageIcon: "homepage.svg",
      currentPage: "homePage",
      currentSubPage: "",
    },
  },
  //#endregion General

  //#region Login
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter: ifAuthenticated,
    meta: {
      pageName: i18n.t("login"),
      pageIcon: "homepage.svg",
      currentPage: "homePage",
      currentSubPage: "",
    },
  },
  //#endregion Login

  //#region TheEmployees
  //#region Employees
  {
    path: "/employees",
    name: "Employees",
    component: () => import("../views/employees/employees/Employees.vue"),
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("employees"),
      pageIcon: "employees.svg",
      currentPage: "theEmployees",
      currentSubPage: "employees",
    },
  },
  // {
  //   path: "/add-employee",
  //   name: "EmployeeAdd",
  //   component: () => import("../views/employees/employees/EmployeeAdd.vue"),
  //   beforeEnter: ifNotAuthenticated,
  //   meta: {
  //     pageName: i18n.t("employeeAdd"),
  //     pageIcon: "employees.svg",
  //     currentPage: "theEmployees",
  //     currentSubPage: "employees",
  //   },
  // },
  // {
  //   path: "/edit-employee/:employeeToken",
  //   name: "EmployeeEdit",
  //   component: () => import("../views/employees/employees/EmployeeEdit.vue"),
  //   beforeEnter: ifNotAuthenticated,
  //   meta: {
  //     pageName: i18n.t("employeeEdit"),
  //     pageIcon: "employees.svg",
  //     currentPage: "theEmployees",
  //     currentSubPage: "employees",
  //   },
  // },
  //#endregion Employees

  //#region EmployeeData
  {
    path: "/employee-data",
    name: "EmployeeData",
    component: () => import("../views/employees/employeeData/EmployeeData.vue"),
    beforeEnter: ifNotAuthenticated,
    props(route) {
      return route.params || {};
    },
    meta: {
      pageName: i18n.t("employeeData"),
      pageIcon: "employees.svg",
      currentPage: "theEmployees",
      currentSubPage: "employees",
    },
    children: [
      //#region EmployeeAddEdit
      {
        path: "add-employee",
        name: "EmployeeAdd",
        component: () => import("../views/employees/employees/EmployeeAdd.vue"),
        props(route) {
          return route.params || {};
        },
        beforeEnter: ifNotAuthenticated,
        meta: {
          pageName: i18n.t("employeeAdd"),
          pageIcon: "employees.svg",
          currentPage: "theEmployees",
          currentSubPage: "employees",
          currentTab: "employeeData",
        },
      },
      {
        path: "edit-employee",
        name: "EmployeeEdit",
        component: () =>
          import("../views/employees/employees/EmployeeEdit.vue"),
        props(route) {
          return route.params || {};
        },
        beforeEnter: ifNotAuthenticated,
        meta: {
          pageName: i18n.t("employeeEdit"),
          pageIcon: "employees.svg",
          currentPage: "theEmployees",
          currentSubPage: "employees",
          currentTab: "employeeData",
        },
      },
      //#endregion EmployeeAddEdit

      //#region EmployeeFolders
      {
        path: "employee-media",
        name: "EmployeeFolders",
        component: () =>
          import("../views/employees/employeeFolders/EmployeeFolders.vue"),
        beforeEnter: ifNotAuthenticated,
        props(route) {
          return route.params || {};
        },
        meta: {
          pageName: i18n.t("employeeTabs.media"),
          pageIcon: "media.svg",
          currentPage: "theEmployees",
          currentSubPage: "employees",
          currentTab: "employeeMedia",
        },
      },
      {
        path: "add-employee-folder",
        name: "EmployeeFolderAdd",
        component: () =>
          import("../views/employees/employeeFolders/EmployeeFolderAdd.vue"),
        beforeEnter: ifNotAuthenticated,
        props(route) {
          return route.params || {};
        },
        meta: {
          pageName: i18n.t("employeeFolders.add"),
          pageIcon: "employeeFolders.svg",
          currentPage: "theEmployees",
          currentSubPage: "employees",
          currentTab: "employeeMedia",
        },
      },
      {
        path: "edit-employee-folder",
        name: "EmployeeFolderEdit",
        component: () =>
          import("../views/employees/employeeFolders/EmployeeFolderEdit.vue"),
        beforeEnter: ifNotAuthenticated,
        props(route) {
          return route.params || {};
        },
        meta: {
          pageName: i18n.t("employeeFolders.edit"),
          pageIcon: "employeeFolders.svg",
          currentPage: "theEmployees",
          currentSubPage: "employees",
          currentTab: "employeeMedia",
        },
      },
      //#endregion EmployeeFolders

      //#region EmployeesFoldersMedias
      {
        path: "employee-folder-medias",
        name: "EmployeesFoldersMedias",
        component: () =>
          import(
            "../views/employees/employeesFoldersMedias/EmployeesFoldersMedias.vue"
          ),
        beforeEnter: ifNotAuthenticated,
        props(route) {
          return route.params || {};
        },
        meta: {
          pageName: i18n.t("foldersMedias.modelName"),
          pageIcon: "media.svg",
          currentPage: "theEmployees",
          currentSubPage: "employees",
          currentTab: "employeeMedia",
        },
      },
      {
        path: "add-employee-folder-media",
        name: "EmployeesFoldersMediaAdd",
        component: () =>
          import(
            "../views/employees/employeesFoldersMedias/EmployeesFoldersMediaAdd.vue"
          ),
        beforeEnter: ifNotAuthenticated,
        props(route) {
          return route.params || {};
        },
        meta: {
          pageName: i18n.t("foldersMedias.add"),
          pageIcon: "media.svg",
          currentPage: "theEmployees",
          currentSubPage: "employees",
          currentTab: "employeeMedia",
        },
      },
      {
        path: "edit-employee-folder-media",
        name: "EmployeesFoldersMediaEdit",
        component: () =>
          import(
            "../views/employees/employeesFoldersMedias/EmployeesFoldersMediaEdit.vue"
          ),
        beforeEnter: ifNotAuthenticated,
        props(route) {
          return route.params || {};
        },
        meta: {
          pageName: i18n.t("foldersMedias.edit"),
          pageIcon: "media.svg",
          currentPage: "theEmployees",
          currentSubPage: "employees",
          currentTab: "employeeMedia",
        },
      },
      //#endregion EmployeesFoldersMedias

      //#region employeeBranchesTab
      {
        path: "employee-branches/:employeeToken?/:branchToken?",
        name: "EmployeeBranchesTab",
        component: () =>
          import("../views/employees/employeeBranchesTab/EmployeeBranches.vue"),
        beforeEnter: ifNotAuthenticated,
        props(route) {
          return route.params || {};
        },
        meta: {
          pageName: i18n.t("employeeTabs.sectors-branches"),
          pageIcon: "employee-branches.svg",
          currentPage: "theEmployees",
          currentSubPage: "employee-branches",
          currentTab: "employeeBranches",
        },
      },
      {
        path: "add-employee-branch",
        name: "EmployeeBranchAddTab",
        component: () =>
          import(
            "../views/employees/employeeBranchesTab/EmployeeBranchAdd.vue"
          ),
        beforeEnter: ifNotAuthenticated,
        props(route) {
          return route.params || {};
        },
        meta: {
          pageName: i18n.t("employee-branches-msgs.add"),
          pageIcon: "employee-branches.svg",
          currentPage: "theEmployees",
          currentSubPage: "employee-branches",
          currentTab: "employeeBranches",
        },
      },
      {
        path: "edit-employee-branch/:employeeBranchesToken",
        name: "EmployeeBranchEditTab",
        component: () =>
          import(
            "../views/employees/employeeBranchesTab/EmployeeBranchEdit.vue"
          ),
        beforeEnter: ifNotAuthenticated,
        props(route) {
          return route.params || {};
        },
        meta: {
          pageName: i18n.t("employee-branches-msgs.edit"),
          pageIcon: "employee-branches.svg",
          currentPage: "theEmployees",
          currentSubPage: "employee-branches",
          currentTab: "employeeBranches",
        },
      },
      //#endregion employeeBranchesTab

      {
        path: "",
        redirect: "edit-employee",
      },
    ],
  },

  //#endregion EmployeeData

  //#region EmployeeBranches
  {
    path: "/employee-branches/:employeeToken?/:branchToken?",
    name: "EmployeeBranches",
    component: EmployeeBranches,
    beforeEnter: ifNotAuthenticated,
    props(route) {
      return route.params || {};
    },
    meta: {
      pageName: i18n.t("employee-branches"),
      pageIcon: "employee-branches.svg",
      currentPage: "theEmployees",
      currentSubPage: "employee-branches",
    },
  },
  {
    path: "/add-employee-branch",
    name: "EmployeeBranchAdd",
    component: EmployeeBranchAdd,
    beforeEnter: ifNotAuthenticated,
    props(route) {
      return route.params || {};
    },
    meta: {
      pageName: i18n.t("employee-branches-msgs.add"),
      pageIcon: "employee-branches.svg",
      currentPage: "theEmployees",
      currentSubPage: "employee-branches",
    },
  },
  {
    path: "/edit-employee-branch/:employeeBranchesToken",
    name: "EmployeeBranchEdit",
    component: EmployeeBranchEdit,
    beforeEnter: ifNotAuthenticated,
    props(route) {
      return route.params || {};
    },
    meta: {
      pageName: i18n.t("employee-branches-msgs.edit"),
      pageIcon: "employee-branches.svg",
      currentPage: "theEmployees",
      currentSubPage: "employee-branches",
    },
  },
  //#endregion EmployeeBranches

  //#region EmployeeSalarySettings
  {
    path: "/employee-salary-settings/:employeeToken",
    name: "EmployeeSalarySettings",
    component: EmployeeSalarySettings,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("EmployeeSalarySetting"),
      pageIcon: "employee-salary-setting.svg",
      currentPage: "theEmployees",
      currentSubPage: "employeeSalarySetting",
    },
  },
  {
    path: "/add-employee-salary-setting",
    name: "EmployeeSalarySettingAdd",
    component: EmployeeSalarySettingAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("EmployeeSalarySettingAdd"),
      pageIcon: "employee-salary-setting.svg",
      currentPage: "theEmployees",
      currentSubPage: "employeeSalarySetting",
    },
  },
  {
    path: "/edit-employee-salary-setting/:employeeSalarySettingToken",
    name: "EmployeeSalarySettingEdit",
    component: EmployeeSalarySettingEdit,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("EmployeeSalarySettingEdit"),
      pageIcon: "employee-salary-setting.svg",
      currentPage: "theEmployees",
      currentSubPage: "employeeSalarySetting",
    },
  },
  //#endregion EmployeeSalarySettings

  //#region EmployeeWorkSchedules
  {
    path: "/employee-work-schedules/:employeeToken",
    name: "EmployeeWorkSchedules",
    component: EmployeeWorkSchedules,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("EmployeeWorkSchedule"),
      pageIcon: "work-schedule.svg",
      currentPage: "theEmployees",
      currentSubPage: "employeeWorkSchedule",
    },
  },
  {
    path: "/add-employee-work-schedule",
    name: "EmployeeWorkScheduleAdd",
    component: EmployeeWorkScheduleAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("EmployeeWorkScheduleAdd"),
      pageIcon: "work-schedule.svg",
      currentPage: "theEmployees",
      currentSubPage: "employeeWorkSchedule",
    },
  },
  //#endregion EmployeeWorkSchedules

  //#region EmployeeOfficialHolidaysPlans
  {
    path: "/employee-official-holidays-plans/:employeeToken",
    name: "EmployeeOfficialHolidaysPlans",
    component: EmployeeOfficialHolidaysPlans,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("EmployeeOfficialHolidaysPlans"),
      pageIcon: "holiday.svg",
      currentPage: "theEmployees",
      currentSubPage: "employeeOfficialHolidaysPlan",
    },
  },
  {
    path: "/add-employee-official-holidays-plan",
    name: "EmployeeOfficialHolidaysPlanAdd",
    component: EmployeeOfficialHolidaysPlanAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("EmployeeOfficialHolidaysPlanAdd"),
      pageIcon: "holiday.svg",
      currentPage: "theEmployees",
      currentSubPage: "employeeOfficialHolidaysPlan",
    },
  },
  //#endregion EmployeeOfficialHolidaysPlans

  //#region EmployeesInputSheetsAttendance
  {
    path: "/employees-input-sheets-attendance",
    name: "EmployeesInputSheetsAttendance",
    component: EmployeesInputSheetsAttendance,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("employeesInputSheetsAttendance"),
      pageIcon: "sheets.svg",
      currentPage: "theEmployees",
      currentSubPage: "employeesInputSheetsAttendance",
    },
  },
  {
    path: "/add-employees-input-sheet-attendance",
    name: "EmployeesInputSheetAttendanceAdd",
    component: EmployeesInputSheetAttendanceAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("employeesInputSheetAttendanceAdd"),
      pageIcon: "sheets.svg",
      currentPage: "theEmployees",
      currentSubPage: "employeesInputSheetsAttendance",
    },
  },
  //#endregion EmployeesInputSheetsAttendance

  //#region EmployeesInputSheetAttendanceMerges
  {
    path: "/employees-input-sheet-attendance-merges",
    name: "EmployeesInputSheetAttendanceMerges",
    component: EmployeesInputSheetAttendanceMerges,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("employeesInputSheetAttendanceMerges"),
      pageIcon: "merge.svg",
      currentPage: "theEmployees",
      currentSubPage: "employeesInputSheetAttendanceMerges",
    },
  },
  {
    path: "/add-employees-input-sheet-attendance-merge",
    name: "EmployeesInputSheetAttendanceMergeAdd",
    component: EmployeesInputSheetAttendanceMergeAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("employeesInputSheetAttendanceMergeAdd"),
      pageIcon: "merge.svg",
      currentPage: "theEmployees",
      currentSubPage: "employeesInputSheetAttendanceMerges",
    },
  },
  //#endregion EmployeesInputSheetAttendanceMerges
  //#endregion TheEmployees

  //#region EmployeeAttendance
  {
    path: "/attendance-employee/:employeeToken",
    name: "EmployeeAttendance",
    component: EmployeeAttendance,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("attendanceHistory"),
      pageIcon: "attendance.svg",
      currentPage: "employeeAttendance",
      currentSubPage: "",
    },
  },
  {
    path: "/add-attendance-employee/:employeeToken",
    name: "EmployeeAttendanceAdd",
    component: EmployeeAttendanceAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("attendanceAdd"),
      pageIcon: "attendance.svg",
      currentPage: "employeeAttendance",
      currentSubPage: "",
    },
  },
  {
    path: "/edit-attendance-employee/:employeeToken/:attendanceToken",
    name: "EmployeeAttendanceEdit",
    component: EmployeeAttendanceEdit,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("attendanceEdit"),
      pageIcon: "attendance.svg",
      currentPage: "employeeAttendance",
      currentSubPage: "",
    },
  },
  //#endregion EmployeeAttendance

  //#region EmployeeMovement
  {
    path: "/movement-employee/:attendanceToken",
    name: "EmployeeMovement",
    component: EmployeeMovement,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("employeeMovement"),
      pageIcon: "address.svg",
      currentPage: "employeeMovement",
      currentSubPage: "",
    },
  },
  {
    path: "/add-movement-employee/:attendanceToken",
    name: "EmployeeMovementAdd",
    component: EmployeeMovementAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("movementAdd"),
      pageIcon: "address.svg",
      currentPage: "employeeMovement",
      currentSubPage: "",
    },
  },
  {
    path: "/edit-movement-employee/:attendanceToken/:movementToken",
    name: "EmployeeMovementEdit",
    component: EmployeeMovementEdit,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("movementEdit"),
      pageIcon: "address.svg",
      currentPage: "employeeMovement",
      currentSubPage: "",
    },
  },
  //#endregion EmployeeMovement

  //#region EmployeeMovementMedia
  {
    path: "/employee-movement-media/:movementToken",
    name: "EmployeeMovementMedia",
    component: EmployeeMovementMedia,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("media"),
      pageIcon: "media.svg",
      currentPage: "employeeMovementMedia",
      currentSubPage: "",
    },
  },
  {
    path: "/employee-movement-media-info/:movementMediaToken",
    name: "EmployeeMovementMediaInfo",
    component: EmployeeMovementMediaInfo,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("media"),
      pageIcon: "media.svg",
      currentPage: "employeeMovementMedia",
      currentSubPage: "",
    },
  },
  //#endregion EmployeeMovementMedia

  //#region Attendance
  {
    path: "/attendance-history",
    name: "AttendanceHistory",
    component: AttendanceHistory,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("attendanceAndDepartureHistory"),
      pageIcon: "attendanceHistory.svg",
      currentPage: "attendance",
      currentSubPage: "attendance-history",
    },
  },
  {
    path: "/add-attendance",
    name: "AttendanceAttend",
    component: AttendanceAttend,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("checkIn"),
      pageIcon: "add-attendance.svg",
      currentPage: "attendance",
      currentSubPage: "add-attendance",
    },
  },
  {
    path: "/add-departure",
    name: "AttendanceDeparture",
    component: AttendanceDeparture,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("checkOut"),
      pageIcon: "add-departure.svg",
      currentPage: "attendance",
      currentSubPage: "add-departure",
    },
  },
  //#endregion Attendance

  //#region Points
  {
    path: "/points",
    name: "Points",
    component: Points,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("points"),
      pageIcon: "points.svg",
      currentPage: "points",
      currentSubPage: "",
    },
  },
  {
    path: "/employee-points/:employeeToken",
    name: "EmployeePoints",
    component: EmployeePoints,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("employeePoints"),
      pageIcon: "points.svg",
      currentPage: "points",
      currentSubPage: "",
    },
  },
  //#endregion Points

  //#region EmployeeSlices
  {
    path: "/employee-slices/:employeeToken",
    name: "EmployeeSlices",
    component: EmployeeSlices,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("slices"),
      pageIcon: "slices.svg",
      currentPage: "employeeSlices",
      currentSubPage: "",
    },
  },
  {
    path: "/add-employee-slice/:employeeToken",
    name: "EmployeeSlicesAdd",
    component: EmployeeSlicesAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("addSlice"),
      pageIcon: "slices.svg",
      currentPage: "employeeSlices",
      currentSubPage: "",
    },
  },
  //#endregion EmployeeSlices

  //#region Reports
  {
    path: "/reports",
    name: "Reports",
    component: Reports,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("reports"),
      pageIcon: "reports.svg",
      currentPage: "reports",
      currentSubPage: "",
    },
  },
  {
    path: "/calculate-payroll",
    name: "CalculatePayroll",
    component: CalculatePayroll,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("calculatePayroll"),
      pageIcon: "payroll.svg",
      currentPage: "reports",
      currentSubPage: "",
    },
  },
  //#endregion Reports

  //#region Privileges
  {
    path: "/establishment-privileges",
    name: "EstablishmentPrivileges",
    component: EstablishmentPrivileges,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("privilegesMsgs.establishment-privileges"),
      pageIcon: "establishment-privileges.svg",
      currentPage: "privileges",
      currentSubPage: "establishment-privileges",
    },
  },
  {
    path: "/branch-privileges",
    name: "BranchPrivileges",
    component: BranchPrivileges,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("privilegesMsgs.branch-privileges"),
      pageIcon: "branch-privileges.svg",
      currentPage: "privileges",
      currentSubPage: "branch-privileges",
    },
  },
  //#endregion Privileges

  //#region Branches
  {
    path: "/branches",
    name: "Branches",
    component: Branches,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("theBranches"),
      pageIcon: "branches.svg",
      currentPage: "branches",
      currentSubPage: "",
    },
  },
  {
    path: "/add-branch",
    name: "BranchAdd",
    component: BranchAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("branchAdd"),
      pageIcon: "branches.svg",
      currentPage: "branches",
      currentSubPage: "",
    },
  },
  {
    path: "/edit-branch/:branchToken",
    name: "BranchEdit",
    component: BranchEdit,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("branchEdit"),
      pageIcon: "branches.svg",
      currentPage: "branches",
      currentSubPage: "",
    },
  },
  //#endregion Branches

  //#region BranchesTimes
  // {
  //   path: "/branches-times",
  //   name: "BranchesTimes",
  //   component: () => import("../views/branchesTimes/BranchesTimes.vue"),
  //   beforeEnter: ifNotAuthenticated,
  // },
  // {
  //   path: "/add-branch-time",
  //   name: "BranchTimeAdd",
  //   component: () => import("../views/branchesTimes/BranchTimeAdd.vue"),
  //   beforeEnter: ifNotAuthenticated,
  // },
  // {
  //   path: "/edit-branch-time/:branchTimeToken",
  //   name: "BranchTimeEdit",
  //   component: () => import("../views/branchesTimes/BranchTimeEdit.vue"),
  //   beforeEnter: ifNotAuthenticated,
  // },
  //#endregion BranchesTimes

  //#region Stores
  // {
  //   path: "/stores",
  //   name: "Stores",
  //   component: () => import("../views/stores/Stores.vue"),
  //   beforeEnter: ifNotAuthenticated,
  // },
  // {
  //   path: "/add-store",
  //   name: "StoreAdd",
  //   component: () => import("../views/stores/StoreAdd.vue"),
  //   beforeEnter: ifNotAuthenticated,
  // },
  // {
  //   path: "/edit-store/:storeToken",
  //   name: "StoreEdit",
  //   component: () => import("../views/stores/StoreEdit.vue"),
  //   beforeEnter: ifNotAuthenticated,
  // },
  //#endregion Stores

  //#region StoresTimes
  // {
  //   path: "/stores-times",
  //   name: "StoresTimes",
  //   component: () => import("../views/storesTimes/StoresTimes.vue"),
  //   beforeEnter: ifNotAuthenticated,
  // },
  // {
  //   path: "/add-store-time",
  //   name: "StoreTimeAdd",
  //   component: () => import("../views/storesTimes/StoreTimeAdd.vue"),
  //   beforeEnter: ifNotAuthenticated,
  // },
  // {
  //   path: "/edit-store-time/:storeTimeToken",
  //   name: "StoreTimeEdit",
  //   component: () => import("../views/storesTimes/StoreTimeEdit.vue"),
  //   beforeEnter: ifNotAuthenticated,
  // },
  //#endregion StoresTimes

  //#region Finance
  //#region Treasuries
  {
    path: "/treasuries",
    name: "Treasuries",
    component: Treasuries,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("theTreasuries"),
      pageIcon: "treasuries.svg",
      currentPage: "finance",
      currentSubPage: "treasuries",
    },
  },
  {
    path: "/add-treasury",
    name: "TreasuryAdd",
    component: TreasuryAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("treasuryAdd"),
      pageIcon: "treasuries.svg",
      currentPage: "finance",
      currentSubPage: "treasuries",
    },
  },
  {
    path: "/edit-treasury/:treasuryToken",
    name: "TreasuryEdit",
    component: TreasuryEdit,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("treasuryEdit"),
      pageIcon: "treasuries.svg",
      currentPage: "finance",
      currentSubPage: "treasuries",
    },
  },
  //#endregion Treasuries

  //#region TreasuriesTransactions
  {
    path: "/treasuries-transactions",
    name: "TreasuriesTransactions",
    component: TreasuriesTransactions,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("treasuriesTransactions"),
      pageIcon: "treasuries-transactions.svg",
      currentPage: "finance",
      currentSubPage: "treasuries-transactions",
    },
  },
  {
    path: "/add-treasury-transaction",
    name: "TreasuryTransactionAdd",
    component: TreasuryTransactionAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("treasuryTransactionAdd"),
      pageIcon: "treasuries-transactions.svg",
      currentPage: "finance",
      currentSubPage: "treasuries-transactions",
    },
  },
  //#endregion TreasuriesTransactions

  //#region Revenues
  {
    path: "/revenues",
    name: "Revenues",
    component: Revenues,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("revenues"),
      pageIcon: "revenues.svg",
      currentPage: "finance",
      currentSubPage: "revenues",
    },
  },
  {
    path: "/add-revenue",
    name: "RevenueAdd",
    component: RevenueAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("revenueAdd"),
      pageIcon: "revenues.svg",
      currentPage: "finance",
      currentSubPage: "revenues",
    },
  },
  //#endregion Revenues

  //#region Expenses
  {
    path: "/expenses",
    name: "Expenses",
    component: Expenses,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("expenses"),
      pageIcon: "expenses.svg",
      currentPage: "finance",
      currentSubPage: "expenses",
    },
  },
  {
    path: "/add-expense",
    name: "ExpenseAdd",
    component: ExpenseAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("expenseAdd"),
      pageIcon: "expenses.svg",
      currentPage: "finance",
      currentSubPage: "expenses",
    },
  },
  //#endregion Expenses

  // #region Custodies
  {
    path: "/custodies",
    name: "Custodies",
    component: () => import("../views/finance/custodies/Custodies.vue"),
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("Custodies.modelName"),
      pageIcon: "Custodies.svg",
      currentPage: "finance",
      currentSubPage: "custodies",
    },
  },
  {
    path: "/add-custody",
    name: "CustodyAdd",
    component: () => import("../views/finance/custodies/CustodyAdd.vue"),
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("Custodies.add"),
      pageIcon: "Custodies.svg",
      currentPage: "finance",
      currentSubPage: "custodies",
    },
  },
  // #endregion Custodies

  // #region CustodyExpenseOperations
  {
    path: "/custody-expense-operations",
    name: "CustodyExpenseOperations",
    component: () =>
      import(
        "../views/finance/custodyExpenseOperations/CustodyExpenseOperations.vue"
      ),
    props(route) {
      return route.params || {};
    },
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("CustodyExpenseOperations.modelName"),
      pageIcon: "CustodyExpenseOperations.svg",
      currentPage: "finance",
      currentSubPage: "CustodyExpenseOperations",
    },
  },
  {
    path: "/add-custody-expense-operation",
    name: "CustodyExpenseOperationAdd",
    component: () =>
      import(
        "../views/finance/custodyExpenseOperations/CustodyExpenseOperationAdd.vue"
      ),
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("CustodyExpenseOperations.add"),
      pageIcon: "CustodyExpenseOperations.svg",
      currentPage: "finance",
      currentSubPage: "CustodyExpenseOperations",
    },
  },
  // #endregion CustodyExpenseOperations

  // #region SalaryInquiries
  {
    path: "/salary-inquiries",
    name: "SalaryInquiries",
    component: () =>
      import("../views/finance/salaryInquiries/SalaryInquiries.vue"),
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("SalaryInquiries.modelName"),
      pageIcon: "SalaryInquiries.svg",
      currentPage: "finance",
      currentSubPage: "SalaryInquiries",
    },
  },
  // #endregion SalaryInquiries
  //#endregion Finance

  //#region News
  // {
  //   path: "/news",
  //   name: "News",
  //   component: () => import("../views/news/News.vue"),
  //   beforeEnter: ifNotAuthenticated,
  // },
  // {
  //   path: "/add-news",
  //   name: "NewsAdd",
  //   component: () => import("../views/news/NewsAdd.vue"),
  //   beforeEnter: ifNotAuthenticated,
  // },
  // {
  //   path: "/edit-news/:newsToken",
  //   name: "NewsEdit",
  //   component: () => import("../views/news/NewsEdit.vue"),
  //   beforeEnter: ifNotAuthenticated,
  // },
  // {
  //   path: "/news-item/:newsToken",
  //   name: "NewsItem",
  //   component: () => import("../views/news/NewsItem.vue"),
  //   beforeEnter: ifNotAuthenticated,
  // },
  //#endregion News

  //#region Offers
  // {
  //   path: "/offers",
  //   name: "Offers",
  //   component: () => import("../views/offers/Offers.vue"),
  //   beforeEnter: ifNotAuthenticated,
  // },
  // {
  //   path: "/add-offer",
  //   name: "OfferAdd",
  //   component: () => import("../views/offers/OfferAdd.vue"),
  //   beforeEnter: ifNotAuthenticated,
  // },
  // {
  //   path: "/edit-offer/:offerToken",
  //   name: "OfferEdit",
  //   component: () => import("../views/offers/OfferEdit.vue"),
  //   beforeEnter: ifNotAuthenticated,
  // },
  // {
  //   path: "/offer-item/:offerToken",
  //   name: "OfferItem",
  //   component: () => import("../views/offers/OfferItem.vue"),
  //   beforeEnter: ifNotAuthenticated,
  // },
  //#endregion Offers

  //#region Clients
  // {
  //   path: "/clients",
  //   name: "Clients",
  //   component: () => import("../views/clients/Clients.vue"),
  //   beforeEnter: ifNotAuthenticated,
  // },
  // {
  //   path: "/add-client",
  //   name: "ClientAdd",
  //   component: () => import("../views/clients/ClientAdd.vue"),
  //   beforeEnter: ifNotAuthenticated,
  // },
  // {
  //   path: "/edit-client/:clientToken",
  //   name: "ClientEdit",
  //   component: () => import("../views/clients/ClientEdit.vue"),
  //   beforeEnter: ifNotAuthenticated,
  // },
  //#endregion Clients

  //#region SettingsBranch
  // {
  //   path: "/settings-branch",
  //   name: "SettingsBranch",
  //   component: () =>
  //     import("../views/settings/settingsBranch/SettingsBranch.vue"),
  //   beforeEnter: ifNotAuthenticated,
  //   meta: {
  //     pageName: i18n.t("settingsBranch"),
  //     pageIcon: "branches.svg",
  //     currentPage: "settings",
  //     currentSubPage: "SettingsBranch",
  //   },
  // },
  //#endregion SettingsBranch

  //#region SettingsEstablishment
  {
    path: "/settings-establishment",
    name: "SettingsEstablishment",
    component: () =>
      import(
        "../views/settings/settingsEstablishment/SettingsEstablishment.vue"
      ),
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("settings-establishment"),
      pageIcon: "settings-establishment.svg",
      currentPage: "settings",
      currentSubPage: "settings-establishment",
    },
  },

  //#region MonthsEstablishmentSettings
  {
    path: "/months-establishment-settings",
    name: "MonthsEstablishmentSettings",
    component: () =>
      import(
        "../views/settings/settingsEstablishment/monthsSettings/MonthsEstablishmentSettings.vue"
      ),
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("establishmentMonthsSettings"),
      pageIcon: "months-settings.svg",
      currentPage: "settings",
      currentSubPage: "settings-establishment",
    },
  },
  {
    path: "/add-months-establishment-setting",
    name: "MonthsEstablishmentSettingAdd",
    component: () =>
      import(
        "../views/settings/settingsEstablishment/monthsSettings/MonthsEstablishmentSettingAdd.vue"
      ),
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("establishmentMonthsSettingAdd"),
      pageIcon: "months-settings.svg",
      currentPage: "settings",
      currentSubPage: "settings-establishment",
    },
  },
  //#endregion MonthsEstablishmentSettings

  //#region EstablishmentYearSettings
  {
    path: "/year-establishment-settings",
    name: "EstablishmentYearSettings",
    component: () =>
      import(
        "../views/settings/settingsEstablishment/yearSettings/EstablishmentYearSettings.vue"
      ),
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("establishmentYearSettings"),
      pageIcon: "year-settings.svg",
      currentPage: "settings",
      currentSubPage: "settings-establishment",
    },
  },
  {
    path: "/add-year-establishment-setting",
    name: "EstablishmentYearSettingAdd",
    component: () =>
      import(
        "../views/settings/settingsEstablishment/yearSettings/EstablishmentYearSettingAdd.vue"
      ),
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("establishmentYearSettingAdd"),
      pageIcon: "year-settings.svg",
      currentPage: "settings",
      currentSubPage: "settings-establishment",
    },
  },
  {
    path: "/edit-year-establishment-setting/:establishmentYearSettingToken",
    name: "EstablishmentYearSettingEdit",
    component: () =>
      import(
        "../views/settings/settingsEstablishment/yearSettings/EstablishmentYearSettingEdit.vue"
      ),
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("establishmentYearSettingEdit"),
      pageIcon: "year-settings.svg",
      currentPage: "settings",
      currentSubPage: "settings-establishment",
    },
  },
  //#endregion EstablishmentYearSettings
  //#endregion SettingsEstablishment

  //#region SettingsOther
  {
    path: "/settings-other",
    name: "SettingsOther",
    component: () =>
      import("../views/settings/settingsOther/SettingsOther.vue"),
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("settings-other"),
      pageIcon: "settings-other.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },

  //#region Sectors
  {
    path: "/sectors",
    name: "Sectors",
    component: () =>
      import("../views/settings/settingsOther/sectors/Sectors.vue"),
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("Sectors.modelName"),
      pageIcon: "sectors.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/add-sector",
    name: "SectorAdd",
    component: () =>
      import("../views/settings/settingsOther/sectors/SectorAdd.vue"),
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("Sectors.add"),
      pageIcon: "sectors.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/edit-sector/:sectorToken",
    name: "SectorEdit",
    component: () =>
      import("../views/settings/settingsOther/sectors/SectorEdit.vue"),
    props(route) {
      return route.params || {};
    },
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("Sectors.edit"),
      pageIcon: "sectors.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  //#endregion Sectors

  //#region Jobs
  {
    path: "/jobs",
    name: "Jobs",
    component: Jobs,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("jobs"),
      pageIcon: "jobs.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/add-job",
    name: "JobsAdd",
    component: JobsAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("jobAdd"),
      pageIcon: "jobs.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/edit-job/:jobToken",
    name: "JobsEdit",
    component: JobsEdit,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("jobEdit"),
      pageIcon: "jobs.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  //#endregion Jobs

  //#region Qualifications
  {
    path: "/qualifications",
    name: "Qualifications",
    component: Qualifications,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("qualifications"),
      pageIcon: "qualifications.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/add-qualification",
    name: "QualificationsAdd",
    component: QualificationsAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("qualificationAdd"),
      pageIcon: "qualifications.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/edit-qualification/:qualificationToken",
    name: "QualificationsEdit",
    component: QualificationsEdit,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("qualificationEdit"),
      pageIcon: "qualifications.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  //#endregion Qualifications

  //#region RevenuesTypes
  {
    path: "/revenues-types",
    name: "RevenuesTypes",
    component: RevenuesTypes,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("revenuesTypes"),
      pageIcon: "revenues-types.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/add-revenue-type",
    name: "RevenueTypeAdd",
    component: RevenueTypeAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("revenueTypeAdd"),
      pageIcon: "revenues-types.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/edit-revenue-type/:revenuesTypeToken",
    name: "RevenueTypeEdit",
    component: RevenueTypeEdit,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("revenueTypeEdit"),
      pageIcon: "revenues-types.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  //#endregion RevenuesTypes

  //#region ExpensesTypes
  {
    path: "/expenses-types",
    name: "ExpensesTypes",
    component: ExpensesTypes,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("expensesTypes"),
      pageIcon: "expenses-types.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/add-expense-type",
    name: "ExpenseTypeAdd",
    component: ExpenseTypeAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("expenseTypeAdd"),
      pageIcon: "expenses-types.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/edit-expense-type/:expensesTypeToken",
    name: "ExpenseTypeEdit",
    component: ExpenseTypeEdit,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("expenseTypeEdit"),
      pageIcon: "expenses-types.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  //#endregion ExpensesTypes

  //#region BloodTypes
  {
    path: "/blood-types",
    name: "BloodTypes",
    component: BloodTypes,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("bloodTypes"),
      pageIcon: "blood-types.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/add-blood-type",
    name: "BloodTypeAdd",
    component: BloodTypeAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("bloodTypeAdd"),
      pageIcon: "blood-types.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/edit-blood-type/:bloodTypeToken",
    name: "BloodTypeEdit",
    component: BloodTypeEdit,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("bloodTypeEdit"),
      pageIcon: "blood-types.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  //#endregion BloodTypes

  //#region MethodPaids
  {
    path: "/method-paids",
    name: "MethodPaids",
    component: MethodPaids,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("methodPaids"),
      pageIcon: "method-paids.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/add-method-paid",
    name: "MethodPaidAdd",
    component: MethodPaidAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("methodPaidAdd"),
      pageIcon: "method-paids.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/edit-method-paid/:methodPaidToken",
    name: "MethodPaidEdit",
    component: MethodPaidEdit,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("methodPaidEdit"),
      pageIcon: "method-paids.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  //#endregion MethodPaids

  //#region scientificDegrees
  {
    path: "/scientific-degrees",
    name: "scientificDegrees",
    component: ScientificDegrees,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("scientificDegrees"),
      pageIcon: "scientific-degrees.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/add-scientific-degree",
    name: "scientificDegreeAdd",
    component: ScientificDegreeAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("scientificDegreeAdd"),
      pageIcon: "scientific-degrees.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/edit-scientific-degree/:scientificDegreeToken",
    name: "scientificDegreeEdit",
    component: ScientificDegreeEdit,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("scientificDegreeEdit"),
      pageIcon: "scientific-degrees.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  //#endregion scientificDegrees

  //#region Countries
  {
    path: "/countries",
    name: "Countries",
    component: Countries,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("setting.others.countries"),
      pageIcon: "country.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/add-country",
    name: "CountryAdd",
    component: CountryAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("countries.add"),
      pageIcon: "country.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/edit-country/:countryToken",
    name: "CountryEdit",
    component: CountryEdit,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("countries.edit"),
      pageIcon: "country.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  //#endregion Countries

  //#region Governates
  {
    path: "/governates",
    name: "Governates",
    component: Governates,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("setting.others.governates"),
      pageIcon: "governate.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/add-governate",
    name: "GovernateAdd",
    component: GovernateAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("governates.add"),
      pageIcon: "governate.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/edit-governate/:governateToken",
    name: "GovernateEdit",
    component: GovernateEdit,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("governates.edit"),
      pageIcon: "governate.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  //#endregion Governates

  //#region Cities
  {
    path: "/cities",
    name: "Cities",
    component: Cities,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("setting.others.cities"),
      pageIcon: "city.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/add-city",
    name: "CityAdd",
    component: CityAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("cities.add"),
      pageIcon: "city.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/edit-city/:cityToken",
    name: "CityEdit",
    component: CityEdit,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("cities.edit"),
      pageIcon: "city.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  //#endregion Cities

  //#region Districts
  {
    path: "/districts",
    name: "Districts",
    component: Districts,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("setting.others.districts"),
      pageIcon: "district.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/add-district",
    name: "DistrictAdd",
    component: DistrictAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("districts.add"),
      pageIcon: "district.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/edit-district/:districtToken",
    name: "DistrictEdit",
    component: DistrictEdit,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("districts.edit"),
      pageIcon: "district.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  //#endregion Districts

  //#region InsuranceCompanies
  {
    path: "/insurance-companies",
    name: "InsuranceCompanies",
    component: InsuranceCompanies,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("setting.others.insuranceCompanies"),
      pageIcon: "insurance-companies.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/add-insurance-company",
    name: "InsuranceCompanyAdd",
    component: InsuranceCompanyAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("insuranceCompanies.add"),
      pageIcon: "insurance-companies.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/edit-insurance-company/:insuranceCompanyToken",
    name: "InsuranceCompanyEdit",
    component: InsuranceCompanyEdit,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("insuranceCompanies.edit"),
      pageIcon: "insurance-companies.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  //#endregion InsuranceCompanies

  //#region EstablishmentRoles
  {
    path: "/establishment-roles",
    name: "EstablishmentRoles",
    component: EstablishmentRoles,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("setting.others.establishmentRoles"),
      pageIcon: "establishment-roles.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/add-establishment-role",
    name: "EstablishmentRoleAdd",
    component: EstablishmentRoleAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("setting.others.establishmentRolesMsgs.add"),
      pageIcon: "establishment-roles.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/edit-establishment-role/:establishmentRoleToken",
    name: "EstablishmentRoleEdit",
    component: EstablishmentRoleEdit,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("setting.others.establishmentRolesMsgs.edit"),
      pageIcon: "establishment-roles.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  //#endregion EstablishmentRoles

  //#region BranchRoles
  {
    path: "/branch-roles",
    name: "BranchRoles",
    component: BranchRoles,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("setting.others.branchRoles"),
      pageIcon: "branch-roles.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/add-branch-role",
    name: "BranchRoleAdd",
    component: BranchRoleAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("setting.others.branchRolesMsgs.add"),
      pageIcon: "branch-roles.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/edit-branch-role/:branchRoleToken",
    name: "BranchRoleEdit",
    component: BranchRoleEdit,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("setting.others.branchRolesMsgs.edit"),
      pageIcon: "branch-roles.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  //#endregion BranchRoles

  // #region CustodyExpenses
  {
    path: "/custody-expenses",
    name: "CustodyExpenses",
    component: () =>
      import(
        "../views/settings/settingsOther/custodyExpenses/CustodyExpenses.vue"
      ),
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("CustodyExpenses.modelName"),
      pageIcon: "CustodyExpenses.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/add-custody-expense",
    name: "CustodyExpenseAdd",
    component: () =>
      import(
        "../views/settings/settingsOther/custodyExpenses/CustodyExpenseAdd.vue"
      ),
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("CustodyExpenses.add"),
      pageIcon: "CustodyExpenses.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/edit-custody-expense",
    name: "CustodyExpenseEdit",
    component: () =>
      import(
        "../views/settings/settingsOther/custodyExpenses/CustodyExpenseEdit.vue"
      ),
    props(route) {
      return route.params || {};
    },
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("CustodyExpenses.edit"),
      pageIcon: "CustodyExpenses.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  // #endregion CustodyExpenses

  //#region EffectTypes
  {
    path: "/effect-types",
    name: "EffectTypes",
    component: EffectTypes,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("effectTypes.modelName"),
      pageIcon: "effect-types.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/add-effect-type",
    name: "EffectTypeAdd",
    component: EffectTypeAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("effectTypes.add"),
      pageIcon: "effect-types.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/edit-effect-type/:additionalEffectTypeToken",
    name: "EffectTypeEdit",
    component: EffectTypeEdit,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("effectTypes.edit"),
      pageIcon: "effect-types.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  //#endregion EffectTypes

  //#region Banks
  {
    path: "/banks",
    name: "Banks",
    component: () => import("../views/settings/settingsOther/banks/Banks.vue"),
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("banks.modelName"),
      pageIcon: "banks.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/add-bank",
    name: "BankAdd",
    component: () =>
      import("../views/settings/settingsOther/banks/BankAdd.vue"),
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("banks.add"),
      pageIcon: "banks.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/edit-bank/",
    name: "BankEdit",
    component: () =>
      import("../views/settings/settingsOther/banks/BankEdit.vue"),
    beforeEnter: ifNotAuthenticated,
    props(route) {
      return route.params || {};
    },
    meta: {
      pageName: i18n.t("banks.edit"),
      pageIcon: "banks.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  //#endregion Banks

  //#region RelativeRelations
  {
    path: "/relative-relations",
    name: "RelativeRelations",
    component: () =>
      import(
        "../views/settings/settingsOther/relativeRelations/RelativeRelations.vue"
      ),
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("relativeRelations.modelName"),
      pageIcon: "relativeRelations.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/add-relative-relation",
    name: "RelativeRelationAdd",
    component: () =>
      import(
        "../views/settings/settingsOther/relativeRelations/RelativeRelationAdd.vue"
      ),
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("relativeRelations.add"),
      pageIcon: "relativeRelations.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/edit-relative-relation/",
    name: "RelativeRelationEdit",
    component: () =>
      import(
        "../views/settings/settingsOther/relativeRelations/RelativeRelationEdit.vue"
      ),
    beforeEnter: ifNotAuthenticated,
    props(route) {
      return route.params || {};
    },
    meta: {
      pageName: i18n.t("relativeRelations.edit"),
      pageIcon: "relativeRelations.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  //#endregion RelativeRelations

  //#region ServicesTypes
  {
    path: "/services-types",
    name: "ServicesTypes",
    component: ServicesTypes,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("servicesTypes"),
      pageIcon: "services-types.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/add-services-type",
    name: "ServicesTypeAdd",
    component: ServicesTypeAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("servicesTypeAdd"),
      pageIcon: "services-types.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/edit-services-type/:serviceTypeToken",
    name: "ServiceTypeEdit",
    component: ServiceTypeEdit,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("servicesTypeEdit"),
      pageIcon: "services-types.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  //#endregion ServicesTypes

  //#region Services
  {
    path: "/services",
    name: "Services",
    component: Services,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("services"),
      pageIcon: "services.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/add-service",
    name: "ServiceAdd",
    component: ServiceAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("serviceAdd"),
      pageIcon: "services.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/edit-service/:serviceToken",
    name: "ServiceEdit",
    component: ServiceEdit,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("serviceEdit"),
      pageIcon: "services.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  //#endregion Services

  //#region GeneralSpecialties
  {
    path: "/general-specialties",
    name: "GeneralSpecialties",
    component: GeneralSpecialties,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("generalSpecialties"),
      pageIcon: "general-specialities.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/add-general-specialty",
    name: "GeneralSpecialtyAdd",
    component: GeneralSpecialtyAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("generalSpecialtyAdd"),
      pageIcon: "general-specialities.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/edit-general-specialty/:generalSpecialtyToken",
    name: "GeneralSpecialtyEdit",
    component: GeneralSpecialtyEdit,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("generalSpecialtyEdit"),
      pageIcon: "general-specialities.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  //#endregion GeneralSpecialties

  //#region SpecialSpecialties
  {
    path: "/special-specialties",
    name: "SpecialSpecialties",
    component: SpecialSpecialties,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("specialSpecialties"),
      pageIcon: "special-spcialities.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/add-special-specialty",
    name: "SpecialSpecialtyAdd",
    component: SpecialSpecialtyAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("specialSpecialtyAdd"),
      pageIcon: "special-spcialities.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/edit-special-specialty/:specialSpecialtyToken",
    name: "SpecialSpecialtyEdit",
    component: SpecialSpecialtyEdit,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("specialSpecialtyEdit"),
      pageIcon: "special-spcialities.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  //#endregion SpecialSpecialties

  //#region NewsCategories
  {
    path: "/news-categories",
    name: "NewsCategories",
    component: NewsCategories,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("newsCategories"),
      pageIcon: "category.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/add-news-category",
    name: "NewsCategoryAdd",
    component: NewsCategoryAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("newsCategoryAdd"),
      pageIcon: "category.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/edit-news-category/:newsCategoryToken",
    name: "NewsCategoryEdit",
    component: NewsCategoryEdit,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("newsCategoryEdit"),
      pageIcon: "category.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  //#endregion NewsCategories

  //#endregion SettingsOther

  //#region SettingsSalary
  {
    path: "/settings-salary",
    name: "SettingsSalary",
    component: () =>
      import("../views/settings/settingsSalary/SettingsSalary.vue"),
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("settings-salary"),
      pageIcon: "settings-salary.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },

  //#region WorkShifts
  // {
  //   path: "/work-shifts",
  //   name: "WorkShifts",
  //   component: () =>
  //     import("../views/settings/settingsSalary/workShifts/WorkShifts.vue"),
  //   beforeEnter: ifNotAuthenticated,
  //   meta: {
  //     pageName: i18n.t("workShifts"),
  //     pageIcon: "work-shifts.svg",
  //     currentPage: "settings",
  //     currentSubPage: "settings-salary",
  //   },
  // },
  // {
  //   path: "/add-work-shift",
  //   name: "WorkShiftAdd",
  //   component: () =>
  //     import("../views/settings/settingsSalary/workShifts/WorkShiftAdd.vue"),
  //   beforeEnter: ifNotAuthenticated,
  //   meta: {
  //     pageName: i18n.t("workShiftAdd"),
  //     pageIcon: "work-shifts.svg",
  //     currentPage: "settings",
  //     currentSubPage: "settings-salary",
  //   },
  // },
  // {
  //   path: "/edit-work-shift/:workShiftToken",
  //   name: "WorkShiftEdit",
  //   component: () =>
  //     import("../views/settings/settingsSalary/workShifts/WorkShiftEdit.vue"),
  //   beforeEnter: ifNotAuthenticated,
  //   meta: {
  //     pageName: i18n.t("workShiftEdit"),
  //     pageIcon: "work-shifts.svg",
  //     currentPage: "settings",
  //     currentSubPage: "settings-salary",
  //   },
  // },
  //#endregion WorkShifts

  //#region WorkPlans
  {
    path: "/work-plans",
    name: "WorkPlans",
    component: WorkPlans,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("workPlans"),
      pageIcon: "work-plans.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  {
    path: "/add-work-plan",
    name: "WorkPlanAdd",
    component: WorkPlanAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("workPlanAdd"),
      pageIcon: "work-plans.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  {
    path: "/edit-work-plan/:workPlanToken",
    name: "WorkPlanEdit",
    component: WorkPlanEdit,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("workPlanEdit"),
      pageIcon: "work-plans.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  //#endregion WorkPlans

  //#region WorkPlanDays
  {
    path: "/work-plan-days/:workPlanToken",
    name: "WorkPlanDays",
    component: WorkPlanDays,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("workPlanDays"),
      pageIcon: "work-plan-days.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  {
    path: "/edit-work-plan-shifts/:workPlanDayToken",
    name: "WorkPlanShiftsEdit",
    component: WorkPlanShiftsEdit,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("WorkPlanShiftsEdit"),
      pageIcon: "work-plan-days.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  //#endregion WorkPlanDays

  //#region SalaryEffects
  {
    path: "/salary-effects",
    name: "SalaryEffects",
    component: SalaryEffects,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("salaryEffects"),
      pageIcon: "salary-effects.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  {
    path: "/add-salary-effect",
    name: "SalaryEffectAdd",
    component: SalaryEffectAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("salaryEffectAdd"),
      pageIcon: "salary-effects.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  {
    path: "/edit-salary-effect/:employeeSalaryEffectToken",
    name: "SalaryEffectEdit",
    component: SalaryEffectEdit,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("salaryEffectEdit"),
      pageIcon: "salary-effects.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  //#endregion SalaryEffects

  //#region BalancesPlans
  {
    path: "/balances-plans",
    name: "BalancesPlans",
    component: BalancesPlans,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("balancesPlans"),
      pageIcon: "balances-plans.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  {
    path: "/add-balances-plan",
    name: "BalancesPlanAdd",
    component: BalancesPlanAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("balancesPlanAdd"),
      pageIcon: "balances-plans.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  {
    path: "/edit-balances-plan/:balancesPlansToken",
    name: "BalancesPlanEdit",
    component: BalancesPlanEdit,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("balancesPlanEdit"),
      pageIcon: "balances-plans.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  {
    path: "/balances-plan-details/:balancesPlansToken",
    name: "BalancesPlanDetails",
    component: BalancesPlanDetails,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("balancesPlanDetails"),
      pageIcon: "balances-plans.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  {
    path: "/add-balances-plan-details/:balancesPlansToken",
    name: "BalancesPlanDetailsAdd",
    component: BalancesPlanDetailsAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("balancesPlanDetailsAdd"),
      pageIcon: "balances-plans.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  {
    path: "/edit-balances-plan-details/:balancesPlansSlidesDetailsToken",
    name: "BalancesPlanDetailsEdit",
    component: BalancesPlanDetailsEdit,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("balancesPlanDetailsEdit"),
      pageIcon: "balances-plans.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  //#endregion BalancesPlans

  //#region OfficialHolidays
  {
    path: "/official-holidays",
    name: "OfficialHolidays",
    component: OfficialHolidays,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("officialHolidays"),
      pageIcon: "holiday.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  {
    path: "/add-official-holiday",
    name: "OfficialHolidayAdd",
    component: OfficialHolidayAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("officialHolidayAdd"),
      pageIcon: "holiday.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  {
    path: "/edit-official-holiday/:officialHolidayToken",
    name: "OfficialHolidayEdit",
    component: OfficialHolidayEdit,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("officialHolidayEdit"),
      pageIcon: "holiday.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  //#endregion OfficialHolidays

  //#region OfficialHolidaysPlans
  {
    path: "/official-holidays-plans",
    name: "OfficialHolidaysPlans",
    component: OfficialHolidaysPlans,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("officialHolidaysPlans"),
      pageIcon: "official-holidays-plans.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  {
    path: "/add-official-holidays-plan",
    name: "OfficialHolidaysPlanAdd",
    component: OfficialHolidaysPlanAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("officialHolidaysPlanAdd"),
      pageIcon: "official-holidays-plans.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  {
    path: "/edit-official-holidays-plan/:officialHolidaysPlansToken",
    name: "OfficialHolidaysPlanEdit",
    component: OfficialHolidaysPlanEdit,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("officialHolidaysPlanEdit"),
      pageIcon: "official-holidays-plans.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  {
    path: "/official-holidays-plan-details/:officialHolidaysPlansToken",
    name: "OfficialHolidaysPlanDetails",
    component: OfficialHolidaysPlanDetails,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("officialHolidaysPlanDetails"),
      pageIcon: "official-holidays-plans.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  {
    path: "/add-official-holidays-plan-details/:officialHolidaysPlansToken",
    name: "OfficialHolidaysPlanDetailsAdd",
    component: OfficialHolidaysPlanDetailsAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("officialHolidaysPlanDetailsAdd"),
      pageIcon: "official-holidays-plans.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  {
    path: "/edit-official-holidays-plan-details/:officialHolidaysPlansSlidesDetailsToken",
    name: "OfficialHolidaysPlanDetailsEdit",
    component: OfficialHolidaysPlanDetailsEdit,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("officialHolidaysPlanDetailsEdit"),
      pageIcon: "official-holidays-plans.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  //#endregion OfficialHolidaysPlans

  //#region Allowances
  {
    path: "/allowances",
    name: "Allowances",
    component: Allowances,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("allowances"),
      pageIcon: "allowances.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  {
    path: "/add-allowance",
    name: "AllowanceAdd",
    component: AllowanceAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("allowanceAdd"),
      pageIcon: "allowances.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  {
    path: "/edit-allowance/:allowanceToken",
    name: "AllowanceEdit",
    component: AllowanceEdit,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("allowanceEdit"),
      pageIcon: "allowances.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  //#endregion Allowances

  //#region AllowancesPlans
  {
    path: "/allowances-plans",
    name: "AllowancesPlans",
    component: AllowancesPlans,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("allowancesPlans"),
      pageIcon: "allowances-plans.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  {
    path: "/add-allowances-plan",
    name: "AllowancesPlanAdd",
    component: AllowancesPlanAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("allowancesPlanAdd"),
      pageIcon: "allowances-plans.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  {
    path: "/edit-allowances-plan/:allowancesPlansToken",
    name: "AllowancesPlanEdit",
    component: AllowancesPlanEdit,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("allowancesPlanEdit"),
      pageIcon: "allowances-plans.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  {
    path: "/allowances-plan-details/:allowancesPlansToken",
    name: "AllowancesPlanDetails",
    component: AllowancesPlanDetails,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("allowancesPlanDetails"),
      pageIcon: "allowances-plans.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  {
    path: "/add-allowances-plan-details/:allowancesPlansToken",
    name: "AllowancesPlanDetailsAdd",
    component: AllowancesPlanDetailsAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("allowancesPlanDetailsAdd"),
      pageIcon: "allowances-plans.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  {
    path: "/edit-allowances-plan-details/:allowancesPlansSlidesDetailsToken",
    name: "AllowancesPlanDetailsEdit",
    component: AllowancesPlanDetailsEdit,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("allowancesPlanDetailsEdit"),
      pageIcon: "allowances-plans.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  //#endregion AllowancesPlans

  //#region MonthAllowancesPlans
  {
    path: "/month-allowances-plans",
    name: "MonthAllowancesPlans",
    component: MonthAllowancesPlans,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("monthAllowancesPlans"),
      pageIcon: "month-allowances-plans.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  {
    path: "/add-month-allowances-plan",
    name: "MonthAllowancesPlanAdd",
    component: MonthAllowancesPlanAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("monthAllowancesPlanAdd"),
      pageIcon: "month-allowances-plans.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  {
    path: "/edit-month-allowances-plan/:monthAllowancesPlansToken",
    name: "MonthAllowancesPlanEdit",
    component: MonthAllowancesPlanEdit,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("monthAllowancesPlanEdit"),
      pageIcon: "month-allowances-plans.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  {
    path: "/month-allowances-plan-details/:monthAllowancesPlansToken",
    name: "MonthAllowancesPlanDetails",
    component: MonthAllowancesPlanDetails,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("monthAllowancesPlanDetails"),
      pageIcon: "month-allowances-plans.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  {
    path: "/add-month-allowances-plan-details/:monthAllowancesPlansToken",
    name: "MonthAllowancesPlanDetailsAdd",
    component: MonthAllowancesPlanDetailsAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("monthAllowancesPlanDetailsAdd"),
      pageIcon: "month-allowances-plans.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  {
    path: "/edit-month-allowances-plan-details/:monthAllowancesPlansSlidesDetailsToken",
    name: "MonthAllowancesPlanDetailsEdit",
    component: MonthAllowancesPlanDetailsEdit,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("monthAllowancesPlanDetailsEdit"),
      pageIcon: "month-allowances-plans.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  //#endregion MonthAllowancesPlans

  //#region AbsencePlans
  {
    path: "/absence-plans",
    name: "AbsencePlans",
    component: AbsencePlans,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("absencePlans"),
      pageIcon: "absence-plans.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  {
    path: "/add-absence-plan",
    name: "AbsencePlanAdd",
    component: AbsencePlanAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("absencePlanAdd"),
      pageIcon: "absence-plans.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  {
    path: "/edit-absence-plan/:absencePlansToken",
    name: "AbsencePlanEdit",
    component: AbsencePlanEdit,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("absencePlanEdit"),
      pageIcon: "absence-plans.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  {
    path: "/absence-plan-details/:absencePlansToken",
    name: "AbsencePlanDetails",
    component: AbsencePlanDetails,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("absencePlanDetails"),
      pageIcon: "absence-plans.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  //#endregion AbsencePlans

  //#region MainColumns
  {
    path: "/main-columns",
    name: "MainColumns",
    component: MainColumns,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("mainColumns"),
      pageIcon: "main-columns.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  {
    path: "/add-main-column",
    name: "MainColumnAdd",
    component: MainColumnAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("mainColumnAdd"),
      pageIcon: "main-columns.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  {
    path: "/edit-main-column/:mainColumnToken",
    name: "MainColumnEdit",
    component: MainColumnEdit,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("mainColumnEdit"),
      pageIcon: "main-columns.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  //#endregion MainColumns

  //#region InsurancesPlans
  {
    path: "/insurances-plans",
    name: "InsurancesPlans",
    component: InsurancesPlans,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("insurancesPlans"),
      pageIcon: "insurances-plans.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  {
    path: "/add-insurances-plan",
    name: "InsurancesPlanAdd",
    component: InsurancesPlanAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("insurancesPlanAdd"),
      pageIcon: "insurances-plans.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  {
    path: "/edit-insurances-plan/:insurancesPlansToken",
    name: "InsurancesPlanEdit",
    component: InsurancesPlanEdit,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("insurancesPlanEdit"),
      pageIcon: "insurances-plans.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  {
    path: "/insurances-plan-details/:insurancesPlansToken",
    name: "InsurancesPlanDetails",
    component: InsurancesPlanDetails,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("insurancesPlanDetails"),
      pageIcon: "insurances-plans.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  {
    path: "/add-insurances-plan-details/:insurancesPlansToken",
    name: "InsurancesPlanDetailsAdd",
    component: InsurancesPlanDetailsAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("insurancesPlanDetailsAdd"),
      pageIcon: "insurances-plans.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  {
    path: "/edit-insurances-plan-details/:insurancesPlansSlidesDetailsToken",
    name: "InsurancesPlanDetailsEdit",
    component: InsurancesPlanDetailsEdit,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("insurancesPlanDetailsEdit"),
      pageIcon: "insurances-plans.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  //#endregion InsurancesPlans

  //#region DeductionsPlans
  {
    path: "/deductions-plans",
    name: "DeductionsPlans",
    component: DeductionsPlans,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("deductions.name"),
      pageIcon: "deductions.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  {
    path: "/add-deductions-plan",
    name: "DeductionsPlanAdd",
    component: DeductionsPlanAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("deductions.add"),
      pageIcon: "deductions.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  {
    path: "/edit-deductions-plan/:deductionsPlansToken",
    name: "DeductionsPlanEdit",
    component: DeductionsPlanEdit,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("deductions.edit"),
      pageIcon: "deductions.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  {
    path: "/deductions-plan-details/:deductionsPlansToken",
    name: "DeductionsPlanDetails",
    component: DeductionsPlanDetails,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("deductionsPlanDetails.name"),
      pageIcon: "deductions.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  {
    path: "/add-deductions-plan-details/:deductionsPlansToken",
    name: "DeductionsPlanDetailsAdd",
    component: DeductionsPlanDetailsAdd,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("deductionsPlanDetails.add"),
      pageIcon: "deductions.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  {
    path: "/edit-deductions-plan-details/:deductionsPlansSlidesDetailsToken",
    name: "DeductionsPlanDetailsEdit",
    component: DeductionsPlanDetailsEdit,
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("deductionsPlanDetails.edit"),
      pageIcon: "deductions.svg",
      currentPage: "settings",
      currentSubPage: "settings-salary",
    },
  },
  //#endregion DeductionsPlans

  //#region Grants
  {
    path: "/grants",
    name: "Grants",
    component: () =>
      import("../views/settings/settingsOther/grants/Grants.vue"),
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("Grants.modelName"),
      pageIcon: "grants.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/add-grant",
    name: "GrantAdd",
    component: () =>
      import("../views/settings/settingsOther/grants/GrantAdd.vue"),
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("Grants.add"),
      pageIcon: "grants.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  {
    path: "/edit-grant/:grantToken",
    name: "GrantEdit",
    component: () =>
      import("../views/settings/settingsOther/grants/GrantEdit.vue"),
    props(route) {
      return route.params || {};
    },
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("Grants.edit"),
      pageIcon: "grants.svg",
      currentPage: "settings",
      currentSubPage: "settings-other",
    },
  },
  //#endregion Grants

  //#endregion SettingsSalary

  //#region OverTimePlans
  // {
  //   path: "/overtime-plans",
  //   name: "OverTimePlans",
  //   component: () => import("../views/settings/settingsSalary/overTimePlans/OverTimePlans.vue"),
  //   beforeEnter: ifNotAuthenticated,
  // },
  // {
  //   path: "/add-overtime-plan",
  //   name: "OverTimePlanAdd",
  //   component: () => import("../views/settings/settingsSalary/overTimePlans/OverTimePlanAdd.vue"),
  //   beforeEnter: ifNotAuthenticated,
  // },
  // {
  //   path: "/edit-overtime-plan/:overTimePlansToken",
  //   name: "OverTimePlanEdit",
  //   component: () => import("../views/settings/settingsSalary/overTimePlans/OverTimePlanEdit.vue"),
  //   beforeEnter: ifNotAuthenticated,
  // },
  // {
  //   path: "/overtime-plan-details/:overTimePlansToken",
  //   name: "OverTimePlanDetails",
  //   component: () => import("../views/settings/settingsSalary/overTimePlans/OverTimePlanDetails.vue"),
  //   beforeEnter: ifNotAuthenticated,
  // },
  //#endregion OverTimePlans

  //#region Buildings
  // {
  //   path: "/buildings",
  //   name: "Buildings",
  //   component: () => import("../views/settings/settingsBranch/buildings/Buildings.vue"),
  //   beforeEnter: ifNotAuthenticated,
  // },
  // {
  //   path: "/add-building",
  //   name: "BuildingAdd",
  //   component: () => import("../views/settings/settingsBranch/buildings/BuildingAdd.vue"),
  //   beforeEnter: ifNotAuthenticated,
  // },
  // {
  //   path: "/edit-building/:buildingToken",
  //   name: "BuildingEdit",
  //   component: () => import("../views/settings/settingsBranch/buildings/BuildingEdit.vue"),
  //   beforeEnter: ifNotAuthenticated,
  // },
  //#endregion Buildings

  //#region Floors
  // {
  //   path: "/floors",
  //   name: "Floors",
  //   component: () => import("../views/settings/settingsBranch/floors/Floors.vue"),
  //   beforeEnter: ifNotAuthenticated,
  // },
  // {
  //   path: "/add-floor",
  //   name: "FloorAdd",
  //   component: () => import("../views/settings/settingsBranch/floors/FloorAdd.vue"),
  //   beforeEnter: ifNotAuthenticated,
  // },
  // {
  //   path: "/edit-floor/:floorToken",
  //   name: "FloorEdit",
  //   component: () => import("../views/settings/settingsBranch/floors/FloorEdit.vue"),
  //   beforeEnter: ifNotAuthenticated,
  // },
  //#endregion Floors

  //#region Not Exist
  // {
  //   path: "/chambers",
  //   name: "Chambers",
  //   component: Chambers,
  //   beforeEnter: ifNotAuthenticated,
  // },
  // {
  //   path: "/add-chamber",
  //   name: "ChamberAdd",
  //   component: ChamberAdd,
  //   beforeEnter: ifNotAuthenticated,
  // },
  // {
  //   path: "/edit-chamber/:chamberToken",
  //   name: "ChamberEdit",
  //   component: ChamberEdit,
  //   beforeEnter: ifNotAuthenticated,
  // },

  // {
  //   path: "/offices",
  //   name: "Offices",
  //   component: Offices,
  //   beforeEnter: ifNotAuthenticated,
  // },
  // {
  //   path: "/add-office",
  //   name: "OfficeAdd",
  //   component: OfficeAdd,
  //   beforeEnter: ifNotAuthenticated,
  // },
  // {
  //   path: "/edit-office/:officeToken",
  //   name: "OfficeEdit",
  //   component: OfficeEdit,
  //   beforeEnter: ifNotAuthenticated,
  // },

  // {
  //   path: "/offices-times",
  //   name: "OfficesTimes",
  //   component: OfficesTimes,
  //   beforeEnter: ifNotAuthenticated,
  // },
  // {
  //   path: "/add-offices-time",
  //   name: "OfficesTimeAdd",
  //   component: OfficesTimeAdd,
  //   beforeEnter: ifNotAuthenticated,
  // },
  // {
  //   path: "/edit-offices-time/:officeTimeToken",
  //   name: "OfficesTimeEdit",
  //   component: OfficesTimeEdit,
  //   beforeEnter: ifNotAuthenticated,
  // },

  // {
  //   path: "/medicines-types",
  //   name: "MedicinesTypes",
  //   component: MedicinesTypes,
  //   beforeEnter: ifNotAuthenticated,
  // },
  // {
  //   path: "/add-medicines-type",
  //   name: "MedicinesTypeAdd",
  //   component: MedicinesTypeAdd,
  //   beforeEnter: ifNotAuthenticated,
  // },
  // {
  //   path: "/edit-medicines-type/:medicinesTypeToken",
  //   name: "MedicinesTypeEdit",
  //   component: MedicinesTypeEdit,
  //   beforeEnter: ifNotAuthenticated,
  // },

  // {
  //   path: "/medicines",
  //   name: "Medicines",
  //   component: Medicines,
  //   beforeEnter: ifNotAuthenticated,
  // },
  // {
  //   path: "/add-medicine",
  //   name: "MedicineAdd",
  //   component: MedicineAdd,
  //   beforeEnter: ifNotAuthenticated,
  // },
  // {
  //   path: "/edit-medicine/:medicineToken",
  //   name: "MedicineEdit",
  //   component: MedicineEdit,
  //   beforeEnter: ifNotAuthenticated,
  // },

  // {
  //   path: "/requirements",
  //   name: "Requirements",
  //   component: Requirements,
  //   beforeEnter: ifNotAuthenticated,
  // },
  // {
  //   path: "/add-requirement",
  //   name: "RequirementAdd",
  //   component: RequirementAdd,
  //   beforeEnter: ifNotAuthenticated,
  // },
  // {
  //   path: "/edit-requirement/:requirementToken",
  //   name: "RequirementEdit",
  //   component: RequirementEdit,
  //   beforeEnter: ifNotAuthenticated,
  // },

  // {
  //   path: "/store-roles",
  //   name: "StoreRoles",
  //   component: StoreRoles,
  //   beforeEnter: ifNotAuthenticated,
  // },
  // {
  //   path: "/add-store-role",
  //   name: "StoreRoleAdd",
  //   component: StoreRoleAdd,
  //   beforeEnter: ifNotAuthenticated,
  // },
  // {
  //   path: "/edit-store-role/:storeRoleToken",
  //   name: "StoreRoleEdit",
  //   component: StoreRoleEdit,
  //   beforeEnter: ifNotAuthenticated,
  // },
  //#endregion Not Exist
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
