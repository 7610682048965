export default class UsersPrivilegeCustodies {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.custodyViewAll = false;
    this.custodyViewSelf = false;
    this.custodyAddAll = false;
    this.custodyAddSelf = false;
    this.custodyCancelAll = false;
    this.custodyCancelSelf = false;
    this.custodySettlementAddAll = false;
    this.custodySettlementAddSelf = false;
  }
  fillData(data) {
    if (data) {
      this.custodyViewAll = data.custodyViewAll;
      this.custodyViewSelf = data.custodyViewSelf;
      this.custodyAddAll = data.custodyAddAll;
      this.custodyAddSelf = data.custodyAddSelf;
      this.custodyCancelAll = data.custodyCancelAll;
      this.custodyCancelSelf = data.custodyCancelSelf;
      this.custodySettlementAddAll = data.custodySettlementAddAll;
      this.custodySettlementAddSelf = data.custodySettlementAddSelf;
    } else {
      this.setInitialValue();
    }
  }
}
