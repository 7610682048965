export default class UsersPrivilegeContractors {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.contractorsView = false;
    this.contractorsAdd = false;
    this.contractorsUpdate = false;
    this.contractorsArchive = false;
    this.contractorsRestore = false;
    this.contractorsDelete = false;
  }
  fillData(data) {
    if (data) {
      this.contractorsView = data.contractorsView;
      this.contractorsAdd = data.contractorsAdd;
      this.contractorsUpdate = data.contractorsUpdate;
      this.contractorsArchive = data.contractorsArchive;
      this.contractorsRestore = data.contractorsRestore;
      this.contractorsDelete = data.contractorsDelete;
    } else {
      this.setInitialValue();
    }
  }
}
