export default class EmployeeBranchesFilter {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.branchToken = "";
    this.employeeToken = "";
    this.branchRoleToken = "";
    this.textSearch = "";
  }
  fillData(data) {
    this.branchToken = data.branchToken;
    this.employeeToken = data.employeeToken;
    this.branchRoleToken = data.branchRoleToken;
    this.textSearch = data.textSearch;
  }
}
