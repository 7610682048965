import { checkPrivilege } from "@/utils/functions";
import { mapGetters } from "vuex";
import { EMPLOYEE_TYPE } from "@/utils/constantLists";

export default {
  data() {
    return {
      employeeTypeToken:
        this.$store.getters.userData.employeePersonalData.employeeTypeToken ||
        null,
      employeeType: EMPLOYEE_TYPE,
    };
  },
  computed: {
    ...mapGetters([
      "usersBranchPrivilageView",
      "usersBranchPrivilageAdd",
      "dialogOfBranchesFinancePrivilageAdd",
      "branchesTokensFinancePrivilageActions",
      "dialogOfBranchesFinancePrivilageActions",
    ]),
  },
  methods: {
    checkPrivilege,
    checkBranchExpensesRefund(branchToken) {
      return this.branchesTokensFinancePrivilageActions.branchesTokensOfExpensesRefund.find(
        (el) => el == branchToken
      )
        ? true
        : false;
    },
    checkBranchRevenuesRefund(branchToken) {
      return this.branchesTokensFinancePrivilageActions.branchesTokensOfRevenuesRefund.find(
        (el) => el == branchToken
      )
        ? true
        : false;
    },
    checkBranchTreasuriesUpdate(branchToken) {
      return this.branchesTokensFinancePrivilageActions.branchesTokensOfTreasuriesUpdate.find(
        (el) => el == branchToken
      )
        ? true
        : false;
    },
    checkBranchTreasuriesArchive(branchToken) {
      return this.branchesTokensFinancePrivilageActions.branchesTokensOfTreasuriesArchive.find(
        (el) => el == branchToken
      )
        ? true
        : false;
    },
    checkBranchTreasuryTransactionsReportView(branchToken) {
      return this.branchesTokensFinancePrivilageActions.branchesTokensOfTreasuryTransactionsReportView.find(
        (el) => el == branchToken
      )
        ? true
        : false;
    },
  },
};
