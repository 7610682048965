<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <form
      autocomplete="off"
      method="post"
      @submit.prevent="updateWorkPlanShifts"
    >
      <div class="my-card">
        <div class="row">
          <DataLabelGroup
            :className="'col-md-6'"
            :value="workPlanDayData.dayNameCurrent"
            :title="$t('day')"
            :imgName="'work-plan-days.svg'"
          />
          <DataLabelGroup
            :className="'col-md-6'"
            :value="workPlanDayData.dayStatusNameCurrent"
            :title="$t('dayStatus')"
            :imgName="'type.svg'"
          />
        </div>
      </div>
      <div class="my-card">
        <span class="my-card-title">{{ $t("workShifts") }}</span>

        <span
          v-if="
            workPlanDayData.WorkPlansShiftsData === undefined ||
            workPlanDayData.WorkPlansShiftsData.length == 0
          "
          class="my-card-title-2"
          >{{ $t("thereAreNoShifts") }}</span
        >
        <div
          v-else
          class="my-card"
          v-for="(workShiftList, index) in workPlanDayData.WorkPlansShiftsData"
          :key="index"
        >
          <span class="my-card-title">{{ index + 1 }}</span>

          <span class="remove-slice-container">
            <button
              @click="removeSlice(index)"
              type="button"
              class="btn btn-remove-slice"
            >
              ×
            </button>
          </span>
          <div class="row">
            <CustomSelectBox
              :className="'col-md-6'"
              :id="`WorkPlansShiftsData[${index}][dayFromToken]`"
              :value="workShiftList.dayFromToken"
              :options="dayTokenOptions"
              v-on:changeValue="workShiftList.dayFromToken = $event"
              :title="$t('selectDayFrom')"
              :imgName="'date.svg'"
            />
            <TimePicker
              :className="'col-md-6'"
              :value="workShiftList.workShiftTimeFrom"
              v-on:changeValue="workShiftList.workShiftTimeFrom = $event"
              :title="$t('workShiftTimeFrom')"
              :language="language"
            />

            <CustomSelectBox
              :className="'col-md-6'"
              :id="`WorkPlansShiftsData[${index}][dayToToken]`"
              :value="workShiftList.dayToToken"
              :options="dayTokenOptions"
              v-on:changeValue="workShiftList.dayToToken = $event"
              :title="$t('selectDayTo')"
              :imgName="'date.svg'"
            />
            <TimePicker
              :className="'col-md-6'"
              :value="workShiftList.workShiftTimeTo"
              v-on:changeValue="workShiftList.workShiftTimeTo = $event"
              :title="$t('workShiftTimeTo')"
              :language="language"
            />

            <CustomSelectBox
              :className="'col-md-6'"
              :id="`WorkPlansShiftsData[${index}][workShiftTypeToken]`"
              :value="workShiftList.workShiftTypeToken"
              :options="workShiftTypeTokenOptions"
              v-on:changeValue="workShiftList.workShiftTypeToken = $event"
              :title="$t('WorkShiftTypeSelect')"
              :imgName="'type.svg'"
            />
            <CustomInputFloat
              :className="'col-md-6'"
              :id="`WorkPlansShiftsData[${index}][workShiftHourFactor]`"
              :value="workShiftList.workShiftHourFactor"
              v-on:changeValue="workShiftList.workShiftHourFactor = $event"
              :title="$t('workShiftHourFactor')"
              :imgName="'number.svg'"
            />

            <TimeCounter
              @input="
                workShiftList.employeeMaximumAllowedAttendLateInShift =
                  $event.value;
                workShiftList.employeeMaximumAllowedAttendLateInShiftHour =
                  $event.hours;
                workShiftList.employeeMaximumAllowedAttendLateInShiftMinute =
                  $event.minutes;
                workShiftList.employeeMaximumAllowedAttendLateInShiftSecond =
                  $event.seconds;
              "
              :hourValue="
                workShiftList.employeeMaximumAllowedAttendLateInShiftHour
              "
              :minuteValue="
                workShiftList.employeeMaximumAllowedAttendLateInShiftMinute
              "
              :secondValue="
                workShiftList.employeeMaximumAllowedAttendLateInShiftSecond
              "
              :className="'col-md-6'"
              :title="$t('maximumAllowedAttendLateInShift')"
              :imgName="'dateAndTime.svg'"
              :dayStatus="false"
            />
            <TimeCounter
              @input="
                workShiftList.employeeMaximumAllowedEarlyDepartureInShift =
                  $event.value;
                workShiftList.employeeMaximumAllowedEarlyDepartureInShiftHour =
                  $event.hours;
                workShiftList.employeeMaximumAllowedEarlyDepartureInShiftMinute =
                  $event.minutes;
                workShiftList.employeeMaximumAllowedEarlyDepartureInShiftSecond =
                  $event.seconds;
              "
              :hourValue="
                workShiftList.employeeMaximumAllowedEarlyDepartureInShiftHour
              "
              :minuteValue="
                workShiftList.employeeMaximumAllowedEarlyDepartureInShiftMinute
              "
              :secondValue="
                workShiftList.employeeMaximumAllowedEarlyDepartureInShiftSecond
              "
              :className="'col-md-6'"
              :title="$t('maximumAllowedEarlyDepartureInShift')"
              :imgName="'dateAndTime.svg'"
              :dayStatus="false"
            />
            <TimeCounter
              @input="
                workShiftList.employeeOverTimeStartInShift = $event.value;
                workShiftList.employeeOverTimeStartInShiftHour = $event.hours;
                workShiftList.employeeOverTimeStartInShiftMinute =
                  $event.minutes;
                workShiftList.employeeOverTimeStartInShiftSecond =
                  $event.seconds;
              "
              :hourValue="workShiftList.employeeOverTimeStartInShiftHour"
              :minuteValue="workShiftList.employeeOverTimeStartInShiftMinute"
              :secondValue="workShiftList.employeeOverTimeStartInShiftSecond"
              :className="'col-md-6'"
              :title="$t('employeeOverTimeStartInShift')"
              :imgName="'dateAndTime.svg'"
              :dayStatus="false"
            />
          </div>
        </div>

        <div class="add-slice-container">
          <button
            @click="addSlice"
            type="button"
            class="btn btn-add-slice"
            :title="$t('addSlice')"
          >
            +
          </button>
        </div>
      </div>

      <div class="form-footer">
        <button name="submit" type="submit" class="btn btn-submit">
          {{ submitName }}
        </button>
        <router-link
          :to="`/work-plan-days/${workPlanDayData.workPlanToken}`"
          class="btn btn-cancel"
        >
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import TimeCounter from "@/components/general/TimeCounter.vue";
import CustomInputFloat from "@/components/general/CustomInputFloat.vue";
import TimePicker from "@/components/general/TimePicker.vue";
import WorkPlanShift from "@/models/settings/settingsSalary/workPlanShifts/WorkPlanShift";
import { setDataMultiLang } from "@/utils/functions";

export default {
  name: "WorkPlanForm",
  components: {
    PreLoader,
    ExceptionWithImg,
    DataLabelGroup,
    CustomSelectBox,
    TimeCounter,
    CustomInputFloat,
    TimePicker,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      workShiftTypeTokenOptions: [],
      dayTokenOptions: [],
    };
  },
  props: ["workPlanDayData", "submitName"],
  methods: {
    updateWorkPlanShifts() {
      this.$emit("updateWorkPlanShifts");
    },
    addSlice() {
      this.workPlanDayData.WorkPlansShiftsData.push(new WorkPlanShift());
    },
    removeSlice(index) {
      this.workPlanDayData.WorkPlansShiftsData.splice(index, 1);
    },
    async getDialogOfDays() {
      this.isLoading = true;
      this.dayTokenOptions = [];
      // this.dayTokenOptions.push({
      //   value: "",
      //   text: this.$t("selectDay"),
      // });
      let days = this.$store.getters.userData.constantLists.listDaysOfWeekData;
      for (let item in days) {
        this.dayTokenOptions.push({
          value: days[item]["itemToken"],
          text: setDataMultiLang(
            this.language,
            days[item]["itemNameAr"],
            days[item]["itemNameEn"]
          ),
        });
      }
      this.isLoading = false;
    },
    async getDialogOfWorkShiftTypes() {
      this.isLoading = true;
      this.workShiftTypeTokenOptions = [];
      let items = this.$store.getters.userData.constantLists.listWorkShiftType;
      for (let item in items) {
        this.workShiftTypeTokenOptions.push({
          value: items[item]["itemToken"],
          text: this.setDataMultiLang(
            this.language,
            items[item]["itemNameAr"],
            items[item]["itemNameEn"]
          ),
        });
      }
      this.isLoading = false;
    },
    setDataMultiLang(lang, dataAr, dataEn) {
      return setDataMultiLang(lang, dataAr, dataEn);
    },
  },
  watch: {},
  created() {
    this.getDialogOfDays();
    this.getDialogOfWorkShiftTypes();
  },
};
</script>
