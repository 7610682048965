<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <div v-if="effectTypesData">
      <EffectTypesTable
        :effectTypesData="effectTypesData"
        :effectTypeData="effectTypeData"
        v-on:setEffectTypeData="effectTypeData.fillData($event)"
      />

      <Pagination
        v-if="!isLoading"
        :paginationData="paginationData"
        v-on:changePage="changePage"
      />
      <EffectTypeDelete
        :effectTypeData="effectTypeData"
        v-on:refresh="getAllEffectTypes()"
      />
    </div>

    <EffectTypeFloatBtns :textSearch="textSearch" v-on:search="search" />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import EffectTypesTable from "@/components/settings/settingsOther/effectTypes/EffectTypesTable.vue";
import EffectTypeDelete from "@/components/settings/settingsOther/effectTypes/EffectTypeDelete.vue";
import EffectTypeFloatBtns from "@/components/settings/settingsOther/effectTypes/EffectTypeFloatBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import EffectType from "@/models/settings/settingsOther/effectTypes/EffectType";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "EffectTypes",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    EffectTypesTable,
    EffectTypeDelete,
    EffectTypeFloatBtns,
    Pagination,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      textSearch: "",
      paginationData: {
        totalPages: 1,
        totalItems: 0,
        countItemsInPage: 0,
        selfPage: 1,
      },
      effectTypesData: null,
      effectTypeData: new EffectType(),
    };
  },
  methods: {
    changePage(page) {
      this.paginationData.selfPage = page;
      this.getAllEffectTypes();
    },
    search(text) {
      this.textSearch = text;
      this.getAllEffectTypes();
    },
    async getAllEffectTypes() {
      this.isLoading = true;
      try {
        const response = await this.effectTypeData.getAllEffectTTypes(
          this.language,
          this.userAuthorizeToken,
          this.paginationData.selfPage,
          this.textSearch
        );

        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.paginationData = {
            totalPages:
              response.data.additionalEffectTypesPagination.totalPages,
            totalItems:
              response.data.additionalEffectTypesPagination.totalItems,
            countItemsInPage:
              response.data.additionalEffectTypesPagination.countItemsInPage,
            selfPage: response.data.additionalEffectTypesPagination.selfPage,
          };
          this.effectTypesData =
            response.data.additionalEffectTypesPagination.additionalEffectsTypesData;
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.effectTypesData = null;
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.effectTypesData = null;
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.effectTypesData = null;
          this.exceptionMsg = response.data.msg;
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.effectTypesData = null;
      }
      this.isLoading = false;
    },
  },
  computed: {},
  async created() {
    this.getAllEffectTypes();
  },
};
</script>
