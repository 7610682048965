var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"WorkPlanShiftInfo","scrollable":"","size":"lg","centered":"","hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h3',[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/work-shifts.svg")}}),_vm._v(" "+_vm._s(_vm.$t("workPlanShiftData"))+" ")])]},proxy:true}])},[_c('div',{staticClass:"row"},[_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.workPlanShiftData.workPlanShiftNameCurrent,"title":_vm.$t('workShiftName'),"imgName":'work-shifts.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.workPlanShiftData.workShiftDuration,"title":_vm.$t('workShiftDuration'),"imgName":'time.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":((_vm.$t('day')) + ": (" + (_vm.isDataExist(
        _vm.workPlanShiftData.dayFromNameCurrent
      )) + ") - " + (_vm.$t('time')) + ": " + (_vm.timeToLang(
        _vm.workPlanShiftData.workShiftTimeFrom
      ))),"title":_vm.$t('workShiftFrom'),"imgName":'time.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":((_vm.$t('day')) + ": (" + (_vm.isDataExist(
        _vm.workPlanShiftData.dayToNameCurrent
      )) + ") - " + (_vm.$t('time')) + ": " + (_vm.timeToLang(_vm.workPlanShiftData.workShiftTimeTo))),"title":_vm.$t('workShiftTo'),"imgName":'time.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.workPlanShiftData.workShiftTypeNameCurrent,"title":_vm.$t('WorkShiftType'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.workPlanShiftData.employeeMaximumAllowedAttendLateInShift,"title":_vm.$t('maximumAllowedAttendLateInShift'),"imgName":'dateAndTime.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.workPlanShiftData.employeeMaximumAllowedEarlyDepartureInShift,"title":_vm.$t('maximumAllowedEarlyDepartureInShift'),"imgName":'dateAndTime.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.workPlanShiftData.employeeOverTimeStartInShift,"title":_vm.$t('employeeOverTimeStartInShift'),"imgName":'dateAndTime.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.workPlanShiftData.workShiftHourFactor,"title":_vm.$t('workShiftHourFactor'),"imgName":'number.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.workPlanShiftData.workShiftIsHaveCompleteStatus == true
          ? _vm.$t('yes')
          : _vm.$t('no'),"title":_vm.$t('workShiftIsHaveCompleteStatus'),"imgName":'type.svg'}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }