import axios from "axios";

import UsersPrivilegeEmployees from "./UsersPrivilegeEmployees";
import UsersPrivilegeEmployeesAttendance from "./UsersPrivilegeEmployeesAttendance";
import UsersPrivilegeEmployeesMovement from "./UsersPrivilegeEmployeesMovement";
import UsersPrivilegeEmployeesMovementMedai from "./UsersPrivilegeEmployeesMovementMedai";
import UsersPrivilegeEstablishmentsSettings from "./UsersPrivilegeEstablishmentsSettings"; // change Done
import UsersPrivilegeEmployeesSettlementMovement from "./UsersPrivilegeEmployeesSettlementMovement";
import UsersPrivilegAdditionalSettings from "./UsersPrivilegAdditionalSettings";
import UsersPrivilegeEmployeesMoveSlides from "./UsersPrivilegeEmployeesMoveSlides";
import UsersPrivilegeBranches from "./UsersPrivilegeBranches";
import UsersPrivilegeStores from "./UsersPrivilegeStores"; //
import UsersPrivilegeClients from "./UsersPrivilegeClients"; //
import UsersPrivilegeNews from "./UsersPrivilegeNews"; //
import UsersPrivilegeEmployeeSalarySetting from "./UsersPrivilegeEmployeeSalarySetting"; // create Done
import UsersPrivilegeEmployeeWorkSchedule from "./UsersPrivilegeEmployeeWorkSchedule"; // create Done
import UsersPrivilegeEmployeeBranches from "./UsersPrivilegeEmployeeBranches"; // create Done
import UsersPrivilegeEmployeeStores from "./UsersPrivilegeEmployeeStores"; //
import UsersPrivilegeContractors from "./UsersPrivilegeContractors"; //
// import UsersPrivilegeEmployeeOfficialHolidaysPlans from "./UsersPrivilegeEmployeeOfficialHolidaysPlans"; // create Done
import UsersPrivilegeTransactionPointsHistory from "./UsersPrivilegeTransactionPointsHistory"; //
import UsersPrivilegeOffers from "./UsersPrivilegeOffers"; //
import UsersPrivilegeEstablishmentYearSetting from "./UsersPrivilegeEstablishmentYearSetting"; // create Done
import UsersPrivilegeUsers from "./UsersPrivilegeUsers";
import UsersPrivilegeEmployeesInputSheetAttendance from "./UsersPrivilegeEmployeesInputSheetAttendance";
import UsersPrivilegeEmployeesInputSheetAttendanceMerge from "./UsersPrivilegeEmployeesInputSheetAttendanceMerge";
import UsersPrivilegeCustodies from "./UsersPrivilegeCustodies";

export default class UsersEstablishmentPrivilege {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.establishmentRoleToken = "";
    this.userEstablishmentPrivilegeToken = "";
    this.usersPrivilegeEmployees = new UsersPrivilegeEmployees();
    this.usersPrivilegeEmployeesAttendance =
      new UsersPrivilegeEmployeesAttendance();
    this.usersPrivilegeEmployeesMovement =
      new UsersPrivilegeEmployeesMovement();
    this.usersPrivilegeEmployeesMovementMedai =
      new UsersPrivilegeEmployeesMovementMedai();
    this.usersPrivilegeEstablishmentsSettings =
      new UsersPrivilegeEstablishmentsSettings();
    this.usersPrivilegeEmployeesSettlementMovement =
      new UsersPrivilegeEmployeesSettlementMovement();
    this.usersPrivilegAdditionalSettings =
      new UsersPrivilegAdditionalSettings();
    this.usersPrivilegeEmployeesMoveSlides =
      new UsersPrivilegeEmployeesMoveSlides();
    this.usersPrivilegeBranches = new UsersPrivilegeBranches();
    this.usersPrivilegeStores = new UsersPrivilegeStores();
    this.usersPrivilegeClients = new UsersPrivilegeClients();
    this.usersPrivilegeNews = new UsersPrivilegeNews();
    this.usersPrivilegeEmployeeSalarySetting =
      new UsersPrivilegeEmployeeSalarySetting();
    this.usersPrivilegeEmployeeWorkSchedule =
      new UsersPrivilegeEmployeeWorkSchedule();
    this.usersPrivilegeEmployeeBranches = new UsersPrivilegeEmployeeBranches();
    this.usersPrivilegeEmployeeStores = new UsersPrivilegeEmployeeStores();
    this.usersPrivilegeContractors = new UsersPrivilegeContractors();
    // this.usersPrivilegeEmployeeOfficialHolidaysPlans =
    //   new UsersPrivilegeEmployeeOfficialHolidaysPlans();
    this.usersPrivilegeTransactionPointsHistory =
      new UsersPrivilegeTransactionPointsHistory();
    this.usersPrivilegeOffers = new UsersPrivilegeOffers();
    this.usersPrivilegeEstablishmentYearSetting =
      new UsersPrivilegeEstablishmentYearSetting();
    this.usersPrivilegeUsers = new UsersPrivilegeUsers();
    this.usersPrivilegeEmployeesInputSheetAttendance =
      new UsersPrivilegeEmployeesInputSheetAttendance();
    this.usersPrivilegeEmployeesInputSheetAttendanceMerge =
      new UsersPrivilegeEmployeesInputSheetAttendanceMerge();
    this.usersPrivilegeCustodies = new UsersPrivilegeCustodies();
  }
  fillData(data) {
    this.establishmentRoleToken = data.establishmentRoleToken;
    this.userEstablishmentPrivilegeToken = data.userEstablishmentPrivilegeToken;
    this.usersPrivilegeEmployees.fillData(data.usersPrivilegeEmployees);
    this.usersPrivilegeEmployeesAttendance.fillData(
      data.usersPrivilegeEmployeesAttendance
    );
    this.usersPrivilegeEmployeesMovement.fillData(
      data.usersPrivilegeEmployeesMovement
    );
    this.usersPrivilegeEmployeesMovementMedai.fillData(
      data.usersPrivilegeEmployeesMovementMedai
    );
    this.usersPrivilegeEstablishmentsSettings.fillData(
      data.usersPrivilegeEstablishmentsSettings
    );
    this.usersPrivilegeEmployeesSettlementMovement.fillData(
      data.usersPrivilegeEmployeesSettlementMovement
    );
    this.usersPrivilegAdditionalSettings.fillData(
      data.usersPrivilegAdditionalSettings
    );
    this.usersPrivilegeEmployeesMoveSlides.fillData(
      data.usersPrivilegeEmployeesMoveSlides
    );
    this.usersPrivilegeBranches.fillData(data.usersPrivilegeBranches);
    this.usersPrivilegeStores.fillData(data.usersPrivilegeStores);
    this.usersPrivilegeClients.fillData(data.usersPrivilegeClients);
    this.usersPrivilegeNews.fillData(data.usersPrivilegeNews);
    this.usersPrivilegeEmployeeSalarySetting.fillData(
      data.usersPrivilegeEmployeeSalarySetting
    );
    this.usersPrivilegeEmployeeWorkSchedule.fillData(
      data.usersPrivilegeEmployeeWorkSchedule
    );
    this.usersPrivilegeEmployeeBranches.fillData(
      data.usersPrivilegeEmployeeBranches
    );
    this.usersPrivilegeEmployeeStores.fillData(
      data.usersPrivilegeEmployeeStores
    );
    this.usersPrivilegeContractors.fillData(data.usersPrivilegeContractors);
    // this.usersPrivilegeEmployeeOfficialHolidaysPlans =
    //   data.usersPrivilegeEmployeeOfficialHolidaysPlans;
    this.usersPrivilegeTransactionPointsHistory.fillData(
      data.usersPrivilegeTransactionPointsHistory
    );
    this.usersPrivilegeOffers.fillData(data.usersPrivilegeOffers);
    this.usersPrivilegeEstablishmentYearSetting.fillData(
      data.usersPrivilegeEstablishmentYearSetting
    );
    this.usersPrivilegeUsers.fillData(data.usersPrivilegeUsers);
    this.usersPrivilegeUsers.fillData(data.usersPrivilegeUsers);
    this.usersPrivilegeEmployeesInputSheetAttendance.fillData(
      data.usersPrivilegeEmployeesInputSheetAttendance
    );
    this.usersPrivilegeEmployeesInputSheetAttendanceMerge.fillData(
      data.usersPrivilegeEmployeesInputSheetAttendanceMerge
    );
    this.usersPrivilegeCustodies.fillData(data.usersPrivilegeCustodies);
  }

  async getUserEstablishmentPrivilege(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/UsersEstablishmentPrivilege//GetUserEstablishmentPrivilege?language=${language}&userAuthorizeToken=${userAuthorizeToken}&establishmentRoleToken=${this.establishmentRoleToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async updateUserEstablishmentPrivilege(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      establishmentRoleToken: this.establishmentRoleToken,
      usersPrivilegeEmployees: this.usersPrivilegeEmployees,
      usersPrivilegeEmployeesAttendance: this.usersPrivilegeEmployeesAttendance,
      usersPrivilegeEmployeesMovement: this.usersPrivilegeEmployeesMovement,
      usersPrivilegeEmployeesMovementMedai:
        this.usersPrivilegeEmployeesMovementMedai,
      usersPrivilegeEstablishmentsSettings:
        this.usersPrivilegeEstablishmentsSettings,
      usersPrivilegeEmployeesSettlementMovement:
        this.usersPrivilegeEmployeesSettlementMovement,
      usersPrivilegAdditionalSettings: this.usersPrivilegAdditionalSettings,
      usersPrivilegeEmployeesMoveSlides: this.usersPrivilegeEmployeesMoveSlides,
      usersPrivilegeBranches: this.usersPrivilegeBranches,
      usersPrivilegeStores: this.usersPrivilegeStores,
      usersPrivilegeClients: this.usersPrivilegeClients,
      usersPrivilegeNews: this.usersPrivilegeNews,
      usersPrivilegeEmployeeSalarySetting:
        this.usersPrivilegeEmployeeSalarySetting,
      usersPrivilegeEmployeeWorkSchedule:
        this.usersPrivilegeEmployeeWorkSchedule,
      usersPrivilegeEmployeeBranches: this.usersPrivilegeEmployeeBranches,
      usersPrivilegeEmployeeStores: this.usersPrivilegeEmployeeStores,
      usersPrivilegeContractors: this.usersPrivilegeContractors,
      // usersPrivilegeEmployeeOfficialHolidaysPlans:
      //   this.usersPrivilegeEmployeeOfficialHolidaysPlans,
      usersPrivilegeTransactionPointsHistory:
        this.usersPrivilegeTransactionPointsHistory,
      usersPrivilegeOffers: this.usersPrivilegeOffers,
      usersPrivilegeEstablishmentYearSetting:
        this.usersPrivilegeEstablishmentYearSetting,
      usersPrivilegeUsers: this.usersPrivilegeUsers,
      usersPrivilegeEmployeesInputSheetAttendance:
        this.usersPrivilegeEmployeesInputSheetAttendance,
      usersPrivilegeEmployeesInputSheetAttendanceMerge:
        this.usersPrivilegeEmployeesInputSheetAttendanceMerge,
      usersPrivilegeCustodies: this.usersPrivilegeCustodies,
    };

    try {
      return await axios.post(
        `/UsersEstablishmentPrivilege//UpdateUserEstablishmentPrivilege`,
        data
      );
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
