<template>
  <b-modal id="WorkPlanDayEdit" size="lg" scrollable centered hide-footer>
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <template #modal-title>
      <h3>
        <img src="@/assets/images/pencil.svg" class="icon-lg" />
        {{ $t("workPlanDayEdit") }}
      </h3>
    </template>

    <div class="row">
      <CustomSelectBox
        :className="'col-md-12'"
        :id="'dayStatusToken'"
        :value="workPlanDayData.dayStatusToken"
        :options="dayStatusTokenOptions"
        v-on:changeValue="workPlanDayData.dayStatusToken = $event"
        :title="$t('selectDayStatus')"
        :imgName="'type.svg'"
      />

      <div class="my-card col-12">
        <span class="my-card-title">{{
          $t("maximumAllowedAttendLateInDay")
        }}</span>

        <div class="row">
          <TimeCounter
            @input="
              workPlanDayData.employeeMaximumAllowedAttendLateInDayByHours24 =
                $event.value;
              workPlanDayData.employeeMaximumAllowedAttendLateInDayByHours24Time.hours =
                $event.hours;
              workPlanDayData.employeeMaximumAllowedAttendLateInDayByHours24Time.minutes =
                $event.minutes;
              workPlanDayData.employeeMaximumAllowedAttendLateInDayByHours24Time.seconds =
                $event.seconds;
            "
            :hourValue="
              workPlanDayData.employeeMaximumAllowedAttendLateInDayByHours24Time
                .hours
            "
            :minuteValue="
              workPlanDayData.employeeMaximumAllowedAttendLateInDayByHours24Time
                .minutes
            "
            :secondValue="
              workPlanDayData.employeeMaximumAllowedAttendLateInDayByHours24Time
                .seconds
            "
            :className="'col-sm-9'"
            :title="$t('time')"
            :imgName="'dateAndTime.svg'"
            :dayStatus="false"
          />
          <CustomCheckbox
            :className="'col-sm-3'"
            :value="workPlanDayData.employeeMaximumAllowedAttendLateInDayStatus"
            v-on:changeValue="
              workPlanDayData.employeeMaximumAllowedAttendLateInDayStatus =
                $event
            "
            :title="$t('activeStatus')"
          />
        </div>
      </div>

      <div class="my-card col-12">
        <span class="my-card-title">{{
          $t("maximumAllowedEarlyDepartureInDay")
        }}</span>

        <div class="row">
          <TimeCounter
            @input="
              workPlanDayData.employeeMaximumAllowedEarlyDepartureInDayByHours24 =
                $event.value;
              workPlanDayData.employeeMaximumAllowedEarlyDepartureInDayByHours24Time.hours =
                $event.hours;
              workPlanDayData.employeeMaximumAllowedEarlyDepartureInDayByHours24Time.minutes =
                $event.minutes;
              workPlanDayData.employeeMaximumAllowedEarlyDepartureInDayByHours24Time.seconds =
                $event.seconds;
            "
            :hourValue="
              workPlanDayData
                .employeeMaximumAllowedEarlyDepartureInDayByHours24Time.hours
            "
            :minuteValue="
              workPlanDayData
                .employeeMaximumAllowedEarlyDepartureInDayByHours24Time.minutes
            "
            :secondValue="
              workPlanDayData
                .employeeMaximumAllowedEarlyDepartureInDayByHours24Time.seconds
            "
            :className="'col-sm-9'"
            :title="$t('time')"
            :imgName="'dateAndTime.svg'"
            :dayStatus="false"
          />
          <CustomCheckbox
            :className="'col-sm-3'"
            :value="
              workPlanDayData.employeeMaximumAllowedEarlyDepartureInDayStatus
            "
            v-on:changeValue="
              workPlanDayData.employeeMaximumAllowedEarlyDepartureInDayStatus =
                $event
            "
            :title="$t('activeStatus')"
          />
        </div>
      </div>

      <div class="my-card col-12">
        <span class="my-card-title">{{
          $t("maximumAllowedOverTimeInDay")
        }}</span>

        <div class="row">
          <TimeCounter
            @input="
              workPlanDayData.employeeMaximumAllowedOverTimeInDayByHours24 =
                $event.value;
              workPlanDayData.employeeMaximumAllowedOverTimeInDayByHours24Time.hours =
                $event.hours;
              workPlanDayData.employeeMaximumAllowedOverTimeInDayByHours24Time.minutes =
                $event.minutes;
              workPlanDayData.employeeMaximumAllowedOverTimeInDayByHours24Time.seconds =
                $event.seconds;
            "
            :hourValue="
              workPlanDayData.employeeMaximumAllowedOverTimeInDayByHours24Time
                .hours
            "
            :minuteValue="
              workPlanDayData.employeeMaximumAllowedOverTimeInDayByHours24Time
                .minutes
            "
            :secondValue="
              workPlanDayData.employeeMaximumAllowedOverTimeInDayByHours24Time
                .seconds
            "
            :className="'col-sm-9'"
            :title="$t('time')"
            :imgName="'dateAndTime.svg'"
            :dayStatus="false"
          />
          <CustomCheckbox
            :className="'col-sm-3'"
            :value="workPlanDayData.employeeMaximumAllowedOverTimeInDayStatus"
            v-on:changeValue="
              workPlanDayData.employeeMaximumAllowedOverTimeInDayStatus = $event
            "
            :title="$t('activeStatus')"
          />
        </div>
      </div>
    </div>

    <div class="form-footer">
      <button
        name="submit"
        type="submit"
        class="btn btn-submit"
        @click.prevent="updateWorkPlanDay"
      >
        {{ $t("edit") }}
      </button>
      <button
        name="submit"
        type="submit"
        class="btn btn-cancel"
        @click.prevent="$bvModal.hide('WorkPlanDayEdit')"
      >
        {{ $t("cancel") }}
      </button>
    </div>
  </b-modal>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import { setDataMultiLang } from "@/utils/functions";
import TimeCounter from "@/components/general/TimeCounter.vue";
import CustomCheckbox from "@/components/general/CustomCheckbox.vue";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "WorkPlanDayEdit",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    TimeCounter,
    CustomCheckbox,
    CustomSelectBox,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      dayStatusTokenOptions: [],
    };
  },
  methods: {
    async getDialogOfDayStatus() {
      this.dayStatusTokenOptions = [];
      // this.dayStatusTokenOptions.push({
      //   value: "",
      //   text: this.$t("selectDayStatus"),
      // });
      let list = this.$store.getters.userData.constantLists.listDayType;
      for (let item in list) {
        this.dayStatusTokenOptions.push({
          value: list[item]["itemToken"],
          text: this.setDataMultiLang(
            this.language,
            list[item]["itemNameAr"],
            list[item]["itemNameEn"]
          ),
        });
      }
    },
    async updateWorkPlanDay() {
      this.isLoading = true;
      try {
        let response = await this.workPlanDayData.updateWorkPlanDay(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$bvModal.hide("WorkPlanDayEdit");
          this.$emit("refresh");
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    setDataMultiLang(lang, dataAr, dataEn) {
      return setDataMultiLang(lang, dataAr, dataEn);
    },
  },
  props: ["workPlanDayData"],
  computed: {},
  async created() {
    this.getDialogOfDayStatus();
  },
};
</script>

<style scoped lang="scss"></style>
