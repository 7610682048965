export const EMPLOYEE_TYPE = {
  Employee: "EMT-1",
  Admin: "EMT-2",
  FounderOfSystem: "EMT-3",
  Receptionist: "EMT-4",
  Doctor: "EMT-5",
};

export const RESERVATION_STATUS = {
  pending: "RTT-1", // Reservations pending الحجوزات المعلقة
  current: "RTT-2", // Current reservations الحجوزات الحالية
  waitingApprovalCancel: "RTT-3", // Waiting Approval Cancel في انتظار التأكيد علي إلغاء الحجز
  canceledBeforeApproval: "RTT-4", // Canceled Before Approval الحجوزات الملغية قبل التأكيد علي الحجز
  canceledAfterApproval: "RTT-5", // Canceled After Approval الحجوزات الملغية بعد التأكيد علي الحجز
  attended: "RTT-6", // Attend To center حضر بالمركز
  entered: "RTT-7", // Service Reservations الحجوزات بالخدمة
  waitingReEntry: "RTT-8", // Reservations awaiting re-entry الحجوزات المنتظرة الدخول مره اخري
  finished: "RTT-9", // Finish reservations الحجوزات المنتهية
};

export const DEDUCTIONS_TYPE_LIST = [
  {
    itemId: 1,
    itemPosition: 0,
    itemToken: "DTT-1",
    itemName: null,
    itemNameAr: "مرتب",
    itemNameEn: "Sallary",
    itemNameUnd: "مرتب",
  },
  {
    itemId: 2,
    itemPosition: 1,
    itemToken: "DTT-2",
    itemName: null,
    itemNameAr: "مؤثر",
    itemNameEn: "Effect",
    itemNameUnd: "مؤثر",
  },
];

export const DEDUCTIONS_TYPE_TOKENS = {
  Sallary: "DTT-1",
  Effect: "DTT-2",
};

export const ATTENDANCE_TYPE_TOKENS = {
  vacation: "EAT-3",
};

//#region MEDIA
export const MEDIA_TYPE_TOKENS = {
  image: "MET-1",
  video: "MET-2",
  audio: "MET-3",
  pdf: "MET-4",
  excel: "MET-5",
  word: "MET-6",
  powerPoint: "MET-7",
};

export const MEDIA_EXTENSIONS = {
  image: [
    "jpeg",
    "jpg",
    "png",
    "gif",
    "tiff",
    "psd",
    "tiff",
    "eps",
    "ai",
    "aidd",
    "raw",
  ],
  video: [
    "mov",
    "avi",
    "wmv",
    "mp4",
    "m4p",
    "m4v",
    "mpg",
    "mp2",
    "mpeg",
    "mpe",
    "mpv",
    "3gp",
    "flv",
  ],
  audio: ["aif", "cda", "mid", "mp3", "mpa", "ogg", "wav", "wma", "wpl"],
  pdf: ["pdf"],
  excel: ["xlsx", "xlsm", "xlsb", "xltx"],
  word: ["doc", "docm", "docx"],
  powerPoint: ["ppsx", "ppt", "pptm", "pptx"],
};
//#endregion MEDIA
