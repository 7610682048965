<template>
  <div class="">
    <div class="row">
      <CustomFileInput
        :className="'col-12'"
        @changeValue="mediaFile = $event"
        :defaultImg="defaultImg"
      />
    </div>
  </div>
</template>

<script>
import CustomFileInput from "@/components/general/CustomFileInput.vue";

export default {
  data() {
    return {
      mediaFile: "",
      // defaultImg: "https://api-admins-abl-v1.1.premcoprecast.com//_imagesEmployees/20211106143035PMr4396456.jpg",
      defaultImg: require("@/assets/images/reports.svg"),
    };
  },
  components: {
    CustomFileInput,
  },
  methods: {},
  computed: {},
  created() {},
};
</script>
