<template>
  <div class="fab-container">
    <div class="fab-parent fab-icon-holder">
      <i class="fa fa-chevron-up"></i>
    </div>
    <ul class="fab-options">
      <li
        v-if="
          checkPrivilege(
            usersEstablishmentPrivilegeData.usersPrivilegAdditionalSettings
              .additionalSettingsAdd
          )
        "
      >
        <span class="fab-label">
          <router-link to="/add-establishment-role" class="">
            {{ $t("setting.others.establishmentRolesMsgs.add") }}
          </router-link>
        </span>
        <div class="fab-icon-holder">
          <router-link to="/add-establishment-role" class="">
            <img src="@/assets/images/plus.svg" />
          </router-link>
        </div>
      </li>
      <li>
        <span class="fab-label" @click="open">
          {{ $t("filter") }}
        </span>
        <div class="fab-icon-holder" @click="open">
          <img src="@/assets/images/filter.svg" />
        </div>
      </li>
    </ul>

    <vue-bottom-sheet ref="CustomBottomSheet">
      <div class="row">
        <CustomInput
          :className="'col-md-12'"
          :id="'textSearch'"
          :value="filterData.textSearch"
          :title="$t('search')"
          :imgName="'search.svg'"
          v-on:changeValue="filterData.textSearch = $event"
        />
      </div>

      <div class="form-footer">
        <button
          name="submit"
          type="submit"
          class="btn btn-submit"
          @click.prevent="search"
        >
          {{ $t("search") }}
        </button>
        <button class="btn btn-cancel" @click.prevent="close">
          {{ $t("cancel") }}
        </button>
      </div>
    </vue-bottom-sheet>
  </div>
</template>

<script>
import CustomInput from "@/components/general/CustomInput.vue";
import privilegeMixin from "@/utils/privilege-mixin";

export default {
  name: "EstablishmentRoleFloatBtns",
  mixins: [privilegeMixin],
  props: {
    filterEstablishmentRoleData: {
      type: Object,
    },
  },
  data() {
    return {
      filterData: this.filterEstablishmentRoleData,
    };
  },
  components: {
    CustomInput,
  },
  methods: {
    open() {
      this.$refs.CustomBottomSheet.open();
    },
    close() {
      this.$refs.CustomBottomSheet.close();
    },
    search() {
      this.close();
      this.$emit("search", this.filterData);
    },
  },
};
</script>
