<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <form autocomplete="off">
      <div class="my-card">
        <div class="row">
          <CustomSelectBox
            :className="'col-md-12'"
            :id="'branchRoleToken'"
            :value="userBranchPrivilegeDate.branchRoleToken"
            :options="branchRoleTokenOptions"
            v-on:changeValue="changeBranchRoleToken($event)"
            :title="$t('setting.others.branchRolesMsgs.select')"
            :imgName="'branch-roles.svg'"
          />
        </div>
      </div>

      <div v-if="userBranchPrivilegeDate.branchRoleToken">
        <UsersPrivilegeExpenses
          :usersPrivilegeExpenses="
            userBranchPrivilegeDate.usersPrivilegeExpenses
          "
        />
        <UsersPrivilegeRevenus
          :usersPrivilegeRevenus="
            userBranchPrivilegeDate.usersPrivilegeRevenues
          "
        />
        <UsersPrivilegeTreasuries
          :usersPrivilegeTreasuries="
            userBranchPrivilegeDate.usersPrivilegeTreasuries
          "
        />
        <UsersPrivilegeTreasuriesTransaction
          :usersPrivilegeTreasuriesTransaction="
            userBranchPrivilegeDate.usersPrivlageTreasuriesTransactions
          "
        />

        <div class="form-footer">
          <button
            name="submit"
            type="submit"
            class="btn btn-submit"
            @click.prevent="updateUserBranchPrivilege"
          >
            {{ submitName }}
          </button>
          <router-link to="/employees" class="btn btn-cancel">
            {{ $t("cancel") }}
          </router-link>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import UsersPrivilegeTreasuries from "@/components/privileges/branchPrivileges/UsersPrivilegeTreasuries.vue";
import UsersPrivilegeTreasuriesTransaction from "@/components/privileges/branchPrivileges/UsersPrivilegeTreasuriesTransaction.vue";
import UsersPrivilegeExpenses from "@/components/privileges/branchPrivileges/UsersPrivilegeExpenses.vue";
import UsersPrivilegeRevenus from "@/components/privileges/branchPrivileges/UsersPrivilegeRevenus.vue";
import BranchRole from "@/models/settings/settingsOther/branchRoles/BranchRole";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "BranchPrivilegeForm",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    CustomSelectBox,

    UsersPrivilegeTreasuries,
    UsersPrivilegeTreasuriesTransaction,
    UsersPrivilegeExpenses,
    UsersPrivilegeRevenus,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      branchRoleData: new BranchRole(),
      branchRoleTokenOptions: [],
    };
  },
  props: ["userBranchPrivilegeDate", "submitName"],
  methods: {
    async changeBranchRoleToken(branchRoleToken) {
      this.userBranchPrivilegeDate.branchRoleToken = branchRoleToken;

      this.getUserBranchPrivilege();
    },
    async getDialogOfBranchRoles() {
      this.isLoading = true;
      this.branchRoleTokenOptions = [];
      this.branchRoleTokenOptions.push({
        value: "",
        text: this.$t("setting.others.branchRolesMsgs.select"),
      });
      try {
        let response = await this.branchRoleData.getDialogOfBranchRoles(
          this.language,
          this.userAuthorizeToken
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.branchRoleTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getUserBranchPrivilege() {
      this.isLoading = true;
      try {
        let response =
          await this.userBranchPrivilegeDate.getUserBranchPrivilege(
            this.language,
            this.userAuthorizeToken
          );
        if (response.data.status == STATUS.SUCCESS) {
          this.userBranchPrivilegeDate.fillData(
            response.data.usersBranchPrivilegeData
          );
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async updateUserBranchPrivilege() {
      this.$emit("updateUserBranchPrivilege");
    },
  },
  async created() {
    this.getDialogOfBranchRoles();
  },
};
</script>

<style scoped lang="scss"></style>
