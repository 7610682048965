export default class UsersPrivilegeEmployeesSettlementMovement {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.employeesSettlementMovementView = false;
    this.employeesSettlementMovementViewAll = false;
    this.employeesSettlementMovementViewSelf = false;
    this.employeesSettlementMovementAdd = false;
    this.employeesSettlementMovementUpdate = false;
    this.employeesSettlementMovementDelete = false;
  }
  fillData(data) {
    if (data) {
      this.employeesSettlementMovementView =
        data.employeesSettlementMovementView;
      this.employeesSettlementMovementViewAll =
        data.employeesSettlementMovementViewAll;
      this.employeesSettlementMovementViewSelf =
        data.employeesSettlementMovementViewSelf;
      this.employeesSettlementMovementAdd = data.employeesSettlementMovementAdd;
      this.employeesSettlementMovementUpdate =
        data.employeesSettlementMovementUpdate;
      this.employeesSettlementMovementDelete =
        data.employeesSettlementMovementDelete;
    } else {
      this.setInitialValue();
    }
  }
}
