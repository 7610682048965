<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <form
      autocomplete="off"
      method="post"
      @submit.prevent="addOrUpdateEffectType"
    >
      <div class="my-card">
        <div class="row">
          <CustomInput
            :className="'col-md-6'"
            :id="'additionalEffectTypeNameAr'"
            :value="effectTypeData.additionalEffectTypeNameAr"
            :title="$t('effectTypes.nameAr')"
            :imgName="'effect-types.svg'"
            v-on:changeValue="
              effectTypeData.additionalEffectTypeNameAr = $event
            "
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'additionalEffectTypeNameEn'"
            :value="effectTypeData.additionalEffectTypeNameEn"
            :title="$t('effectTypes.nameEn')"
            :imgName="'effect-types.svg'"
            v-on:changeValue="
              effectTypeData.additionalEffectTypeNameEn = $event
            "
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'additionalEffectTypeNameUnd'"
            :value="effectTypeData.additionalEffectTypeNameUnd"
            :title="$t('effectTypes.nameUnd')"
            :imgName="'effect-types.svg'"
            v-on:changeValue="
              effectTypeData.additionalEffectTypeNameUnd = $event
            "
          />
          <CustomSelectBox
            :className="'col-md-6'"
            :id="`mathematicalTypeToken`"
            :value="effectTypeData.mathematicalTypeToken"
            :options="mathematicalTypeTokenOptions"
            v-on:changeValue="effectTypeData.mathematicalTypeToken = $event"
            :title="$t('effectTypes.selectMathematicalMethod')"
            :imgName="'type.svg'"
          />
          <CustomInputInt
            :className="'col-md-9'"
            :id="'additionalEffectMaximumNumberInMonth'"
            :value="effectTypeData.additionalEffectMaximumNumberInMonth"
            :title="$t('effectTypes.maximumNumberInMonth')"
            :imgName="'number.svg'"
            v-on:changeValue="
              effectTypeData.additionalEffectMaximumNumberInMonth = $event
            "
          />
          <CustomCheckbox
            :className="'col-md-3 col-lg-3'"
            :id="`additionalEffectMaximumNumberInMonthStatus`"
            :value="effectTypeData.additionalEffectMaximumNumberInMonthStatus"
            :title="$t('activeStatus')"
            v-on:changeValue="
              effectTypeData.additionalEffectMaximumNumberInMonthStatus = $event
            "
          />
          <CustomInputInt
            :className="'col-md-9'"
            :id="'additionalEffectMaximumNumberInYear'"
            :value="effectTypeData.additionalEffectMaximumNumberInYear"
            :title="$t('effectTypes.maximumNumberInYear')"
            :imgName="'number.svg'"
            v-on:changeValue="
              effectTypeData.additionalEffectMaximumNumberInYear = $event
            "
          />
          <CustomCheckbox
            :className="'col-md-3 col-lg-3'"
            :id="`additionalEffectMaximumNumberInYearStatus`"
            :value="effectTypeData.additionalEffectMaximumNumberInYearStatus"
            :title="$t('activeStatus')"
            v-on:changeValue="
              effectTypeData.additionalEffectMaximumNumberInYearStatus = $event
            "
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'additionalEffectTypeDescriptionAr'"
            :value="effectTypeData.additionalEffectTypeDescriptionAr"
            :title="$t('effectTypes.descriptionAr')"
            :imgName="'description.svg'"
            v-on:changeValue="
              effectTypeData.additionalEffectTypeDescriptionAr = $event
            "
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'additionalEffectTypeDescriptionEn'"
            :value="effectTypeData.additionalEffectTypeDescriptionEn"
            :title="$t('effectTypes.descriptionEn')"
            :imgName="'description.svg'"
            v-on:changeValue="
              effectTypeData.additionalEffectTypeDescriptionEn = $event
            "
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'additionalEffectTypeDescriptionUnd'"
            :value="effectTypeData.additionalEffectTypeDescriptionUnd"
            :title="$t('effectTypes.descriptionUnd')"
            :imgName="'description.svg'"
            v-on:changeValue="
              effectTypeData.additionalEffectTypeDescriptionUnd = $event
            "
          />

          <TextArea
            :className="'col-md-6'"
            :id="'additionalEffectTypeNotes'"
            :value="effectTypeData.additionalEffectTypeNotes"
            :title="$t('notes')"
            :imgName="'notes.svg'"
            v-on:changeValue="effectTypeData.additionalEffectTypeNotes = $event"
          />
        </div>
      </div>

      <div class="form-footer">
        <button name="submit" type="submit" class="btn btn-submit">
          {{ submitName }}
        </button>
        <router-link to="/effect-types" class="btn btn-cancel">
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import CustomInput from "@/components/general/CustomInput.vue";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import CustomCheckbox from "@/components/general/CustomCheckbox.vue";
import CustomInputInt from "@/components/general/CustomInputInt.vue";
import TextArea from "@/components/general/TextArea.vue";

export default {
  name: "EffectTypeForm",
  components: {
    PreLoader,
    ExceptionWithImg,
    CustomInput,
    CustomSelectBox,
    CustomCheckbox,
    CustomInputInt,
    TextArea,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      mathematicalTypeTokenOptions: [],
    };
  },
  props: ["effectTypeData", "submitName"],
  methods: {
    async getDialogOfMathematicalType() {
      this.isLoading = true;
      this.mathematicalTypeTokenOptions = [];
      this.mathematicalTypeTokenOptions.push({
        value: "",
        text: this.$t("effectTypes.selectMathematicalMethod"),
      });
      let deductions =
        this.$store.getters.userData.constantLists.listMathematicalType;
      for (let item in deductions) {
        this.mathematicalTypeTokenOptions.push({
          value: deductions[item]["itemToken"],
          text: this.setDataMultiLang(
            this.language,
            deductions[item]["itemNameAr"],
            deductions[item]["itemNameEn"]
          ),
        });
      }
      this.isLoading = false;
    },
    async addOrUpdateEffectType() {
      this.$emit("addOrUpdateEffectType");
    },
    setDataMultiLang(lang, dataAr, dataEn) {
      if (lang == "en") return dataEn;
      else return dataAr;
    },
  },
  watch: {},
  async created() {
    this.getDialogOfMathematicalType();
  },
};
</script>

<style lang="scss"></style>
