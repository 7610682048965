<template>
  <div class="form-label-group mt-2" :class="className">
    <b-form-checkbox
      v-model="theValue"
      name="check-button"
      switch
      :disabled="isDisabled"
    >
      {{ title ? title : $t("notFound") }}
    </b-form-checkbox>
  </div>
</template>

<script>
export default {
  name: "CustomCheckbox",
  data() {
    return {
      theValue: this.value,
    };
  },
  methods: {},
  props: {
    className: {
      type: String,
      default: "",
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    value: {
      default: "",
    },
    title: {
      type: String,
      default: "title",
    },
  },
  watch: {
    theValue: function (val) {
      this.$emit("changeValue", val);
    },
    value: function (val) {
      this.theValue = val;
    },
  },
  async created() {},
};
</script>

<style lang="scss" scoped>
// .form-label-group textarea:not(:placeholder-shown) ~ label,
// .form-label-group input:not(:placeholder-shown) ~ label {
//   padding-inline-start: 2.25rem;
// }
// .custom-switch .custom-control-label::before {
//   right: 0rem;
//   // left: 0rem;
// }
// .custom-switch .custom-control-label::after {
//   right: calc(1rem - 2px);
//   // right: var(--checkbox-right);
//   // left: var(--checkbox-left);
// }
</style>
