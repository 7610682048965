<template>
  <div class="">
    <b-button v-b-toggle.treasuriesTransactions class="btn btn-lg btn-collapse">
      {{ $t("treasuriesTransactions") }}
      <i class="fa fa-angle-down" aria-hidden="true"></i>
    </b-button>
    <b-collapse id="treasuriesTransactions">
      <div class="my-card">
        <div class="row">
          <div class="form-label-group col-md-4 col-lg-4">
            <input
              type="checkbox"
              id="treasuryTransactionsView"
              v-model="
                usersPrivilegeTreasuriesTransaction.treasuryTransactionsView
              "
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="treasuryTransactionsView">{{
              $t("view")
            }}</label>
          </div>
          <div class="form-label-group col-md-4 col-lg-4">
            <input
              type="checkbox"
              id="treasuryTransactionsAdd"
              v-model="
                usersPrivilegeTreasuriesTransaction.treasuryTransactionsAdd
              "
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="treasuryTransactionsAdd">{{
              $t("add")
            }}</label>
          </div>
          <div class="form-label-group col-md-4 col-lg-4">
            <input
              type="checkbox"
              id="treasuriesTransactionsReportView"
              v-model="
                usersPrivilegeTreasuriesTransaction.treasuriesTransactionsReportView
              "
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="treasuriesTransactionsReportView">{{
              $t("viewReports")
            }}</label>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: "UsersPrivilegeTreasuriesTransaction",
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["usersPrivilegeTreasuriesTransaction"],
  methods: {},
};
</script>

<style scoped lang="scss"></style>
