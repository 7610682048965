import { PAGE_SIZE } from "@/utils/constants";
import { getTimeSeparated } from "@/utils/functions";
import TimeSeparated from "@/models/general/TimeSeparated";
import axios from "axios";

export default class EmployeeSalarySetting {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.employeeSalarySettingToken = "";
    this.employeeToken = "";
    this.employeeNameCurrent = "";
    this.employeeSalarySettingNameCurrent = "";
    this.employeeSalarySettingNameEn = "";
    this.employeeSalarySettingNameAr = "";
    this.employeeSalarySettingNameUnd = "";
    this.employeeSalarySettingDescriptionCurrent = "";
    this.employeeSalarySettingDescriptionEn = "";
    this.employeeSalarySettingDescriptionAr = "";
    this.employeeSalarySettingDescriptionUnd = "";
    this.employeeSalarySettingNotes = "";
    this.employeeInsuranceTypeToken = ""; //new
    this.employeeInsuranceTypeNameCurrent = ""; //new
    this.employeeInsuranceTypeNameEn = ""; //new
    this.employeeInsuranceTypeNameAr = ""; //new
    this.employeeInsuranceTypeNameUnd = ""; //new
    this.employeeSalarySettingActivationStatus = "";

    this.employeeBasicSalary = "";
    this.employeeVariableSalary = "";
    this.employeeTotalSalary = "";

    this.employeeWorkDaysNumberPerMonth = "";
    this.employeeWorkHoursNumberPerDay = "";
    this.employeeWorkHoursNumberPerDayTime = new TimeSeparated(); //me
    this.employeeWorkHourPrice = "";
    this.employeePriceUnKnownWorkhour = ""; //new

    // this.employeeMaximumAllowedAttendLateInDayByHours24 = ""; //old
    // this.employeeMaximumAllowedAttendLateInDayStatus = ""; //old

    // this.employeeMaximumAllowedAttendLateInMonthByHours24 = ""; //old
    // this.employeeMaximumAllowedAttendLateInMonthByDays = ""; //old
    // this.employeeMaximumAllowedAttendLateInMonthStatus = ""; //old

    // this.employeeMaximumAllowedEarlyDepartureInDayByHours24 = ""; //old
    // this.employeeMaximumAllowedEarlyDepartureInDayStatus = ""; //old

    // this.employeeMaximumAllowedEarlyDepartureInMonthByHours24 = ""; //old
    // this.employeeMaximumAllowedEarlyDepartureInMonthByDays = ""; //old
    // this.employeeMaximumAllowedEarlyDepartureInMonthStatus = ""; //old

    // this.employeeMaximumAllowedOverTimeInDayByHours24 = ""; //old
    // this.employeeMaximumAllowedOverTimeInDayStatus = ""; //old

    this.employeeMaximumAllowedOverTimeInMonthByHours24 = "";
    this.employeeMaximumAllowedOverTimeInMonthByDays = "";
    this.employeeMaximumAllowedOverTimeInMonthByDaysTime = new TimeSeparated(); //me
    this.employeeMaximumAllowedOverTimeInMonthStatus = "";

    this.employeeMaximumAllowedShiftAbsenceUntillSalaryStop = "";
    this.employeeMaximumAllowedShiftAbsenceUntillSalaryStopStatus = "";

    // this.employeeOverTimeStartInDayByHours24 = ""; //old
    // this.employeeOverTimeStartInDayStatus = ""; //old

    // this.employeeDeductionFactorValueCaseLate = ""; //old
    // this.employeeDeductionPriceCaseLateStatus = ""; //old

    // this.employeeDeductionFactorValueCaseEarlyDeparture = ""; //old
    // this.employeeDeductionFactorValueCaseEarlyDepartureStatus = ""; //old

    this.employeeBalancePlanToken = "";
    this.employeeBalancePlanNameCurrent = "";
    this.balancesPlansData = "";

    this.employeeAllowancePlanToken = "";
    this.employeeAllowancePlanNameCurrent = "";
    this.allowancesPlansData = "";

    this.employeeAbsencePlanToken = "";
    this.employeeAbsencePlanNameCurrent = "";
    this.absencePlansData = "";

    this.employeeDeductionsPlanToken = "";
    this.employeeDeductionsPlanNameCurrent = "";
    this.deductionsPlansData = "";

    // this.employeeOverTimePlanToken = "";
    // this.employeeOverTimePlanNameCurrent = "";

    this.employeeMonthAllowancesPlanToken = "";
    this.employeeMonthAllowancesPlanNameCurrent = "";

    this.actionDateTime = "";
    this.actionDate = "";
    this.actionTime = "";
    this.employeeSalarySettingArchiveStatus = "";

    this.employeeTokenList = [];
  }
  fillData(data) {
    this.employeeSalarySettingToken = data.employeeSalarySettingToken;
    this.employeeToken = data.employeeToken;
    this.employeeNameCurrent = data.employeeNameCurrent;
    this.employeeSalarySettingNameCurrent =
      data.employeeSalarySettingNameCurrent;
    this.employeeSalarySettingNameEn = data.employeeSalarySettingNameEn;
    this.employeeSalarySettingNameAr = data.employeeSalarySettingNameAr;
    this.employeeSalarySettingNameUnd = data.employeeSalarySettingNameUnd;
    this.employeeSalarySettingDescriptionCurrent =
      data.employeeSalarySettingDescriptionCurrent;
    this.employeeSalarySettingDescriptionEn =
      data.employeeSalarySettingDescriptionEn;
    this.employeeSalarySettingDescriptionAr =
      data.employeeSalarySettingDescriptionAr;
    this.employeeSalarySettingDescriptionUnd =
      data.employeeSalarySettingDescriptionUnd;
    this.employeeSalarySettingNotes = data.employeeSalarySettingNotes;
    this.employeeInsuranceTypeToken = data.employeeInsuranceTypeToken;
    this.employeeInsuranceTypeNameCurrent =
      data.employeeInsuranceTypeNameCurrent;
    this.employeeInsuranceTypeNameEn = data.employeeInsuranceTypeNameEn;
    this.employeeInsuranceTypeNameAr = data.employeeInsuranceTypeNameAr;
    this.employeeInsuranceTypeNameUnd = data.employeeInsuranceTypeNameUnd;
    this.employeeSalarySettingActivationStatus =
      data.employeeSalarySettingActivationStatus;

    this.employeeBasicSalary = data.employeeBasicSalary;
    this.employeeVariableSalary = data.employeeVariableSalary;
    this.employeeTotalSalary = data.employeeTotalSalary;

    this.employeeWorkDaysNumberPerMonth = data.employeeWorkDaysNumberPerMonth;
    this.employeeWorkHoursNumberPerDay = data.employeeWorkHoursNumberPerDay;
    this.employeeWorkHoursNumberPerDayTime.fillData(
      getTimeSeparated(data.employeeWorkHoursNumberPerDay)
    );
    this.employeeWorkHourPrice = data.employeeWorkHourPrice;
    this.employeePriceUnKnownWorkhour = data.employeePriceUnKnownWorkhour;

    this.employeeMaximumAllowedOverTimeInMonthByHours24 =
      data.employeeMaximumAllowedOverTimeInMonthByHours24;
    this.employeeMaximumAllowedOverTimeInMonthByDays =
      data.employeeMaximumAllowedOverTimeInMonthByDays;
    this.employeeMaximumAllowedOverTimeInMonthByDaysTime.fillData(
      getTimeSeparated(data.employeeMaximumAllowedOverTimeInMonthByDays)
    );
    this.employeeMaximumAllowedOverTimeInMonthStatus =
      data.employeeMaximumAllowedOverTimeInMonthStatus;

    this.employeeMaximumAllowedShiftAbsenceUntillSalaryStop =
      data.employeeMaximumAllowedShiftAbsenceUntillSalaryStop;
    this.employeeMaximumAllowedShiftAbsenceUntillSalaryStopStatus =
      data.employeeMaximumAllowedShiftAbsenceUntillSalaryStopStatus;

    this.employeeBalancePlanToken = data.employeeBalancePlanToken;
    this.employeeBalancePlanNameCurrent = data.employeeBalancePlanNameCurrent;
    this.balancesPlansData = data.balancesPlansData;

    this.employeeAllowancePlanToken = data.employeeAllowancePlanToken;
    this.employeeAllowancePlanNameCurrent =
      data.employeeAllowancePlanNameCurrent;
    this.allowancesPlansData = data.allowancesPlansData;

    this.employeeAbsencePlanToken = data.employeeAbsencePlanToken;
    this.employeeAbsencePlanNameCurrent = data.employeeAbsencePlanNameCurrent;
    this.absencePlansData = data.absencePlansData;

    this.employeeDeductionsPlanToken = data.employeeDeductionsPlanToken;
    this.employeeDeductionsPlanNameCurrent =
      data.employeeDeductionsPlanNameCurrent;
    this.deductionsPlansData = data.deductionsPlansData;

    this.employeeMonthAllowancesPlanToken =
      data.employeeMonthAllowancesPlanToken;
    this.employeeMonthAllowancesPlanNameCurrent =
      data.employeeMonthAllowancesPlanNameCurrent;

    this.actionDateTime = data.actionDateTime;
    this.actionDate = data.actionDate;
    this.actionTime = data.actionTime;
    this.employeeSalarySettingArchiveStatus =
      data.employeeSalarySettingArchiveStatus;
  }

  async getAllEmployeeSalarySettings(
    language,
    userAuthorizeToken,
    selfPage,
    employeeToken,
    textSearch
  ) {
    try {
      return await axios.get(
        `/EmployeeSalarySetting/GetAllEmployeeSalarySetting?language=${language}&userAuthorizeToken=${userAuthorizeToken}&page=${selfPage}&pageSize=${PAGE_SIZE}&filterStatus=true&employeeToken=${employeeToken}&textSearch=${textSearch}`
      );
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getEmployeeSalarySettingDetails(language, userAuthorizeToken) {
    try {
      return await axios.get(
        `/EmployeeSalarySetting/GetEmployeeSalarySettingDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&employeeSalarySettingToken=${this.employeeSalarySettingToken}`
      );
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addOrUpdateEmployeeSalarySetting(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      employeeSalarySettingToken: this.employeeSalarySettingToken,
      employeeToken: this.employeeToken,
      //salarySettingData
      employeeSalarySettingDescriptionEn:
        this.employeeSalarySettingDescriptionEn,
      employeeSalarySettingDescriptionAr:
        this.employeeSalarySettingDescriptionAr,
      employeeSalarySettingDescriptionUnd:
        this.employeeSalarySettingDescriptionUnd,
      employeeSalarySettingNotes: this.employeeSalarySettingNotes,
      employeeSalarySettingActivationStatus:
        this.employeeSalarySettingActivationStatus,
      //employeeSalary
      employeeBasicSalary: this.employeeBasicSalary,
      employeeVariableSalary: this.employeeVariableSalary,
      employeeWorkDaysNumberPerMonth: this.employeeWorkDaysNumberPerMonth,
      employeeWorkHoursNumberPerDay: this.employeeWorkHoursNumberPerDay,
      employeeWorkHourPrice: this.employeeWorkHourPrice, //new
      employeeInsuranceTypeToken: this.employeeInsuranceTypeToken, //new
      //employeeMaximumAllowedOverTimeIn
      employeeMaximumAllowedOverTimeInMonthByHours24:
        this.employeeMaximumAllowedOverTimeInMonthByHours24,
      employeeMaximumAllowedOverTimeInMonthByDays:
        this.employeeMaximumAllowedOverTimeInMonthByDays,
      employeeMaximumAllowedOverTimeInMonthStatus:
        this.employeeMaximumAllowedOverTimeInMonthStatus,
      //employeeMaximumAllowedAbsenceUntillSalary
      employeeMaximumAllowedShiftAbsenceUntillSalaryStop:
        this.employeeMaximumAllowedShiftAbsenceUntillSalaryStop,
      employeeMaximumAllowedShiftAbsenceUntillSalaryStopStatus:
        this.employeeMaximumAllowedShiftAbsenceUntillSalaryStopStatus,
      //plansTokens
      employeeBalancePlanToken: this.employeeBalancePlanToken,
      employeeAllowancePlanToken: this.employeeAllowancePlanToken,
      employeeAbsencePlanToken: this.employeeAbsencePlanToken,
      employeeDeductionsPlanToken: this.employeeDeductionsPlanToken,
      employeeMonthAllowancesPlanToken: this.employeeMonthAllowancesPlanToken,
      employeePriceUnKnownWorkhour: this.employeePriceUnKnownWorkhour,
      //employeesList
      employeeTokenList: this.employeeTokenList,
    };

    try {
      if (this.employeeToken == "" || this.employeeToken == undefined) {
        return await axios.post(
          `/EmployeeSalarySetting/AddEmployeeSalarySetting`,
          data
        );
      } else {
        return await axios.post(
          `/EmployeeSalarySetting/UpdateEmployeeSalarySetting`,
          data
        );
      }
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async archiveEmployeeSalarySetting(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      employeeSalarySettingToken: this.employeeSalarySettingToken,
    };

    try {
      return await axios.post(
        `/EmployeeSalarySetting/ArchiveEmployeeSalarySetting`,
        data
      );
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
