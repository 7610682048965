var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.employeesInputSheetAttendanceMergesData)?_c('div',{},[_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"my-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v(_vm._s(_vm.$t("mergeCode")))]),_c('th',[_vm._v(_vm._s(_vm.$t("mergeYear")))]),_c('th',[_vm._v(_vm._s(_vm.$t("mergeMonth")))]),_c('th',[_vm._v(_vm._s(_vm.$t("actionDateTime")))]),_c('th',[_vm._v(_vm._s(_vm.$t("notes")))]),_c('th',[_vm._v(_vm._s(_vm.$t("caseOfProblems")))]),_c('th',[_vm._v(_vm._s(_vm.$t("downloadCausesOfProblems")))]),(
              _vm.checkPrivilege(
                _vm.usersEstablishmentPrivilegeData
                  .usersPrivilegeEmployeesInputSheetAttendanceMerge
                  .EmployeesInputSheetAttendanceMergeDelete
              )
            )?_c('th',[_vm._v(" "+_vm._s(_vm.$t("delete"))+" ")]):_vm._e()])]),_c('tbody',_vm._l((_vm.employeesInputSheetAttendanceMergesData),function(merge,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(++index))]),_c('td',[_vm._v(_vm._s(_vm.isDataExist(merge.mergeCode)))]),_c('td',[_vm._v(_vm._s(_vm.isDataExist(merge.mergeYear)))]),_c('td',[_vm._v(_vm._s(_vm.isDataExist(merge.mergeMonth)))]),_c('td',[_vm._v(_vm._s(_vm.timeToLang(merge.mergeDateTimeAtion)))]),_c('td',[_vm._v(_vm._s(_vm.isDataExist(merge.mergeNote)))]),_c('td',[_vm._v(" "+_vm._s(merge.sheetExptionStatus === true ? _vm.$t("yes") : _vm.$t("no"))+" ")]),_c('td',[(merge.sheetExptionStatus === true)?_c('button',{staticClass:"btn p-0",attrs:{"title":_vm.$t('download')},on:{"click":function($event){return _vm.downloadFile(merge.sheetExptionPath)}}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/sheets.svg")}})]):_c('span',[_vm._v("-")])]),(
              _vm.checkPrivilege(
                _vm.usersEstablishmentPrivilegeData
                  .usersPrivilegeEmployeesInputSheetAttendanceMerge
                  .EmployeesInputSheetAttendanceMergeDelete
              )
            )?_c('td',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.EmployeesInputSheetAttendanceMergeDelete",modifiers:{"EmployeesInputSheetAttendanceMergeDelete":true}}],staticClass:"btn p-0",attrs:{"title":_vm.$t('delete')},on:{"click":function($event){return _vm.setEmployeesInputSheetAttendanceMergeData(merge)}}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/trash.svg")}})])]):_vm._e()])}),0)])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }