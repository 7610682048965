import { PAGE_SIZE } from "@/utils/constants";
import axios from "axios";
import PaginationData from "./../../../general/PaginationData";
import JobsFilter from "./JobsFilter";

export default class Job {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.jobToken = "";
    this.branchToken = "";
    this.branchNameCurrent = "";
    this.sectorToken = "";
    this.sectorNameCurrent = "";
    this.jobNameCollecation = "";
    this.jobNameCurrent = "";
    this.jobNameAr = "";
    this.jobNameEn = "";
    this.jobNameUnd = "";
    this.jobNotes = "";
    this.jobArchiveStatus = false;
  }
  fillData(data) {
    this.jobToken = data.jobToken;
    this.branchToken = data.branchToken;
    this.branchNameCurrent = data.branchNameCurrent;
    this.sectorToken = data.sectorToken;
    this.sectorNameCurrent = data.sectorNameCurrent;
    this.jobNameCollecation = data.jobNameCollecation;
    this.jobNameCurrent = data.jobNameCurrent;
    this.jobNameAr = data.jobNameAr;
    this.jobNameEn = data.jobNameEn;
    this.jobNameUnd = data.jobNameUnd;
    this.jobNotes = data.jobNotes;
    this.jobArchiveStatus = data.jobArchiveStatus;
  }

  async getAllJobs(
    language,
    userAuthorizeToken,
    pagination = new PaginationData(),
    filterData = new JobsFilter()
  ) {
    try {
      return await axios.get(
        `/Jobs/GetAllJobs?language=${language}&userAuthorizeToken=${userAuthorizeToken}&cityArchiveStatus=false&page=${pagination.selfPage}&paginationStatus=true&pageSize=${PAGE_SIZE}&filterStatus=true&sectorToken=${filterData.sectorToken}&textSearch=${filterData.textSearch}`
      );
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getDialogOfJobs(
    language,
    userAuthorizeToken,
    filterData = new JobsFilter()
  ) {
    return await axios.get(
      `/Jobs/GetDialogOfJobs?language=${language}&userAuthorizeToken=${userAuthorizeToken}&filterStatus=true&sectorToken=${filterData.sectorToken}&textSearch=${filterData.textSearch}`
    );
  }

  async getJobDetails(language, userAuthorizeToken) {
    try {
      return await axios.get(
        `/Jobs/GetJobDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&jobToken=${this.jobToken}`
      );
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addOrUpdateJob(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      jobToken: this.jobToken,
      sectorToken: this.sectorToken,
      jobNameEn: this.jobNameEn,
      jobNameAr: this.jobNameAr,
      jobNameUnd: this.jobNameUnd,
      jobNotes: this.jobNotes,
    };

    try {
      if (this.jobToken == "" || this.jobToken == undefined) {
        return await axios.post(`/Jobs/AddJob`, data);
      } else {
        return await axios.post(`/Jobs/UpdateJob`, data);
      }
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async archiveJob(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      jobToken: this.jobToken,
    };

    try {
      return await axios.post(`/Jobs/ArchiveJob`, data);
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
