<template>
  <div v-if="effectTypesData" class="">
    <div class="table-container">
      <table class="my-table">
        <thead>
          <tr>
            <th rowspan="2">#</th>
            <th rowspan="2">{{ $t("name") }}</th>
            <th rowspan="2">{{ $t("effectTypes.mathematicalMethod") }}</th>
            <th colspan="2">{{ $t("effectTypes.maximumNumberInMonth") }}</th>
            <!-- <th>{{ $t("activeStatus") }}</th> -->
            <th colspan="2">{{ $t("effectTypes.maximumNumberInYear") }}</th>
            <!-- <th>{{ $t("activeStatus") }}</th> -->
            <th rowspan="2">{{ $t("description") }}</th>
            <th rowspan="2">{{ $t("notes") }}</th>
            <th
              rowspan="2"
              v-if="
                checkPrivilege(
                  usersEstablishmentPrivilegeData
                    .usersPrivilegAdditionalSettings.additionalSettingsUpdate
                )
              "
            >
              {{ $t("edit") }}
            </th>
            <th
              rowspan="2"
              v-if="
                checkPrivilege(
                  usersEstablishmentPrivilegeData
                    .usersPrivilegAdditionalSettings.additionalSettingsArchive
                )
              "
            >
              {{ $t("delete") }}
            </th>
          </tr>
          <tr>
            <th>{{ $t("effectTypes.number") }}</th>
            <th>{{ $t("activeStatus") }}</th>
            <th>{{ $t("effectTypes.number") }}</th>
            <th>{{ $t("activeStatus") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(effectType, index) in effectTypesData"
            :key="effectType.effectTypeToken"
          >
            <td>{{ ++index }}</td>
            <td>
              {{ isDataExist(effectType.additionalEffectTypeNameCurrent) }}
            </td>
            <td>{{ isDataExist(effectType.mathematicalTypeNameCurrent) }}</td>
            <td>
              {{ isDataExist(effectType.additionalEffectMaximumNumberInMonth) }}
            </td>
            <td>
              {{
                effectType.additionalEffectMaximumNumberInMonthStatus
                  ? $t("activeOfferStatus")
                  : $t("disActiveOfferStatus")
              }}
            </td>
            <td>
              {{ isDataExist(effectType.additionalEffectMaximumNumberInYear) }}
            </td>
            <td>
              {{
                effectType.additionalEffectMaximumNumberInYearStatus
                  ? $t("activeOfferStatus")
                  : $t("disActiveOfferStatus")
              }}
            </td>
            <td>
              {{
                isDataExist(effectType.additionalEffectTypeDescriptionCurrent)
              }}
            </td>
            <td>{{ isDataExist(effectType.additionalEffectTypeNotes) }}</td>
            <td
              v-if="
                checkPrivilege(
                  usersEstablishmentPrivilegeData
                    .usersPrivilegAdditionalSettings.additionalSettingsUpdate
                )
              "
            >
              <router-link
                :to="`/edit-effect-type/${effectType.additionalEffectTypeToken}`"
                :title="$t('edit')"
              >
                <img src="@/assets/images/pencil.svg" class="icon-lg" />
              </router-link>
            </td>
            <td
              v-if="
                checkPrivilege(
                  usersEstablishmentPrivilegeData
                    .usersPrivilegAdditionalSettings.additionalSettingsArchive
                )
              "
            >
              <button
                v-b-modal.EffectTypeDelete
                class="btn p-0"
                :title="$t('delete')"
                @click="setEffectTypeData(effectType)"
              >
                <img src="@/assets/images/trash.svg" class="icon-lg" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import privilegeMixin from "@/utils/privilege-mixin";
import { setDataMultiLang, timeToLang, isDataExist } from "@/utils/functions";

export default {
  name: "EffectTypesTable",
  mixins: [privilegeMixin],
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["effectTypesData", "effectTypeData"],
  methods: {
    setEffectTypeData(effectTypeData) {
      this.$emit("setEffectTypeData", effectTypeData);
    },
    timeToLang(time) {
      return timeToLang(time, this.language);
    },
    setDataMultiLang(lang, dataAr, dataEn, dataUnd) {
      return setDataMultiLang(lang, dataAr, dataEn, dataUnd);
    },
    isDataExist(data) {
      return isDataExist(data);
    },
  },
  computed: {},
};
</script>

<style lang="scss"></style>
