export default class UsersPrivilegeEstablishmentYearSetting {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.establishmentYearSettingView = false;
    this.establishmentYearSettingAdd = false;
    this.establishmentYearSettingUpdate = false;
    this.establishmentYearSettingArchive = false;
    this.establishmentYearSettingRestore = false;
    this.establishmentYearSettingDelete = false;
  }
  fillData(data) {
    if (data) {
      this.establishmentYearSettingView = data.establishmentYearSettingView;
      this.establishmentYearSettingAdd = data.establishmentYearSettingAdd;
      this.establishmentYearSettingUpdate = data.establishmentYearSettingUpdate;
      this.establishmentYearSettingArchive =
        data.establishmentYearSettingArchive;
      this.establishmentYearSettingRestore =
        data.establishmentYearSettingRestore;
      this.establishmentYearSettingDelete = data.establishmentYearSettingDelete;
    } else {
      this.setInitialValue();
    }
  }
}
