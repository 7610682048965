var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.effectTypesData)?_c('div',{},[_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"my-table"},[_c('thead',[_c('tr',[_c('th',{attrs:{"rowspan":"2"}},[_vm._v("#")]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v(_vm._s(_vm.$t("name")))]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v(_vm._s(_vm.$t("effectTypes.mathematicalMethod")))]),_c('th',{attrs:{"colspan":"2"}},[_vm._v(_vm._s(_vm.$t("effectTypes.maximumNumberInMonth")))]),_c('th',{attrs:{"colspan":"2"}},[_vm._v(_vm._s(_vm.$t("effectTypes.maximumNumberInYear")))]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v(_vm._s(_vm.$t("description")))]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v(_vm._s(_vm.$t("notes")))]),(
              _vm.checkPrivilege(
                _vm.usersEstablishmentPrivilegeData
                  .usersPrivilegAdditionalSettings.additionalSettingsUpdate
              )
            )?_c('th',{attrs:{"rowspan":"2"}},[_vm._v(" "+_vm._s(_vm.$t("edit"))+" ")]):_vm._e(),(
              _vm.checkPrivilege(
                _vm.usersEstablishmentPrivilegeData
                  .usersPrivilegAdditionalSettings.additionalSettingsArchive
              )
            )?_c('th',{attrs:{"rowspan":"2"}},[_vm._v(" "+_vm._s(_vm.$t("delete"))+" ")]):_vm._e()]),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("effectTypes.number")))]),_c('th',[_vm._v(_vm._s(_vm.$t("activeStatus")))]),_c('th',[_vm._v(_vm._s(_vm.$t("effectTypes.number")))]),_c('th',[_vm._v(_vm._s(_vm.$t("activeStatus")))])])]),_c('tbody',_vm._l((_vm.effectTypesData),function(effectType,index){return _c('tr',{key:effectType.effectTypeToken},[_c('td',[_vm._v(_vm._s(++index))]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist(effectType.additionalEffectTypeNameCurrent))+" ")]),_c('td',[_vm._v(_vm._s(_vm.isDataExist(effectType.mathematicalTypeNameCurrent)))]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist(effectType.additionalEffectMaximumNumberInMonth))+" ")]),_c('td',[_vm._v(" "+_vm._s(effectType.additionalEffectMaximumNumberInMonthStatus ? _vm.$t("activeOfferStatus") : _vm.$t("disActiveOfferStatus"))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist(effectType.additionalEffectMaximumNumberInYear))+" ")]),_c('td',[_vm._v(" "+_vm._s(effectType.additionalEffectMaximumNumberInYearStatus ? _vm.$t("activeOfferStatus") : _vm.$t("disActiveOfferStatus"))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist(effectType.additionalEffectTypeDescriptionCurrent))+" ")]),_c('td',[_vm._v(_vm._s(_vm.isDataExist(effectType.additionalEffectTypeNotes)))]),(
              _vm.checkPrivilege(
                _vm.usersEstablishmentPrivilegeData
                  .usersPrivilegAdditionalSettings.additionalSettingsUpdate
              )
            )?_c('td',[_c('router-link',{attrs:{"to":("/edit-effect-type/" + (effectType.additionalEffectTypeToken)),"title":_vm.$t('edit')}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/pencil.svg")}})])],1):_vm._e(),(
              _vm.checkPrivilege(
                _vm.usersEstablishmentPrivilegeData
                  .usersPrivilegAdditionalSettings.additionalSettingsArchive
              )
            )?_c('td',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.EffectTypeDelete",modifiers:{"EffectTypeDelete":true}}],staticClass:"btn p-0",attrs:{"title":_vm.$t('delete')},on:{"click":function($event){return _vm.setEffectTypeData(effectType)}}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/trash.svg")}})])]):_vm._e()])}),0)])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }