export default class UsersPrivilegeEmployeeBranches {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.employeeBranchesView = false;
    this.employeeBranchesAdd = false;
    this.employeeBranchesUpdate = false;
    this.employeeBranchesArchive = false;
    this.employeeBranchesRestore = false;
    this.employeeBranchesDelete = false;
  }
  fillData(data) {
    if (data) {
      this.employeeBranchesView = data.employeeBranchesView;
      this.employeeBranchesAdd = data.employeeBranchesAdd;
      this.employeeBranchesUpdate = data.employeeBranchesUpdate;
      this.employeeBranchesArchive = data.employeeBranchesArchive;
      this.employeeBranchesRestore = data.employeeBranchesRestore;
      this.employeeBranchesDelete = data.employeeBranchesDelete;
    } else {
      this.setInitialValue();
    }
  }
}
