<template>
  <b-modal id="WorkPlanShiftInfo" scrollable size="lg" centered hide-footer>
    <template #modal-title>
      <h3>
        <img src="@/assets/images/work-shifts.svg" class="icon-lg" />
        {{ $t("workPlanShiftData") }}
      </h3>
    </template>
    <div class="row">
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="workPlanShiftData.workPlanShiftNameCurrent"
        :title="$t('workShiftName')"
        :imgName="'work-shifts.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="workPlanShiftData.workShiftDuration"
        :title="$t('workShiftDuration')"
        :imgName="'time.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="`${$t('day')}: (${isDataExist(
          workPlanShiftData.dayFromNameCurrent
        )}) - ${$t('time')}: ${timeToLang(
          workPlanShiftData.workShiftTimeFrom
        )}`"
        :title="$t('workShiftFrom')"
        :imgName="'time.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="`${$t('day')}: (${isDataExist(
          workPlanShiftData.dayToNameCurrent
        )}) - ${$t('time')}: ${timeToLang(workPlanShiftData.workShiftTimeTo)}`"
        :title="$t('workShiftTo')"
        :imgName="'time.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="workPlanShiftData.workShiftTypeNameCurrent"
        :title="$t('WorkShiftType')"
        :imgName="'type.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="workPlanShiftData.employeeMaximumAllowedAttendLateInShift"
        :title="$t('maximumAllowedAttendLateInShift')"
        :imgName="'dateAndTime.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="workPlanShiftData.employeeMaximumAllowedEarlyDepartureInShift"
        :title="$t('maximumAllowedEarlyDepartureInShift')"
        :imgName="'dateAndTime.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="workPlanShiftData.employeeOverTimeStartInShift"
        :title="$t('employeeOverTimeStartInShift')"
        :imgName="'dateAndTime.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="workPlanShiftData.workShiftHourFactor"
        :title="$t('workShiftHourFactor')"
        :imgName="'number.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          workPlanShiftData.workShiftIsHaveCompleteStatus == true
            ? $t('yes')
            : $t('no')
        "
        :title="$t('workShiftIsHaveCompleteStatus')"
        :imgName="'type.svg'"
      />
    </div>
  </b-modal>
</template>

<script>
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";
import { isDataExist, timeToLang } from "@/utils/functions";

export default {
  name: "WorkPlanShiftInfo",
  components: {
    DataLabelGroup,
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["workPlanShiftData"],
  methods: {
    isDataExist,
    timeToLang(time) {
      return timeToLang(time, this.language);
    },
  },
};
</script>

<style scoped lang="scss"></style>
