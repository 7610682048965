export default class UsersPrivilegeUsers {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.usersEstablishmentPrivilegeView = false;
    this.usersEstablishmentPrivilegeUpdate = false;
    this.usersBranchPrivilegeView = false;
    this.usersBranchPrivilegeUpdate = false;
    this.usersStorePrivilegeView = false;
    this.usersStorePrivilegeUpdate = false;
  }
  fillData(data) {
    if (data) {
      this.usersEstablishmentPrivilegeView =
        data.usersEstablishmentPrivilegeView;
      this.usersEstablishmentPrivilegeUpdate =
        data.usersEstablishmentPrivilegeUpdate;
      this.usersBranchPrivilegeView = data.usersBranchPrivilegeView;
      this.usersBranchPrivilegeUpdate = data.usersBranchPrivilegeUpdate;
      this.usersStorePrivilegeView = data.usersStorePrivilegeView;
      this.usersStorePrivilegeUpdate = data.usersStorePrivilegeUpdate;
    } else {
      this.setInitialValue();
    }
  }
}
