export default class UsersPrivilegeEmployeesInputSheetAttendance {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.employeesInputSheetAttendanceView = false;
    this.employeesInputSheetAttendanceAdd = false;
    this.employeesInputSheetAttendanceDelete = false;
  }
  fillData(data) {
    if (data) {
      this.employeesInputSheetAttendanceView =
        data.employeesInputSheetAttendanceView;
      this.employeesInputSheetAttendanceAdd =
        data.employeesInputSheetAttendanceAdd;
      this.employeesInputSheetAttendanceDelete =
        data.employeesInputSheetAttendanceDelete;
    } else {
      this.setInitialValue();
    }
  }
}
