<template>
  <b-modal
    id="DeductionsPlanDetailsInfo"
    scrollable
    size="lg"
    centered
    hide-footer
  >
    <template #modal-title>
      <h3>
        <img src="@/assets/images/deductions.svg" class="icon-lg" />
        {{ $t("deductions.data") }}
      </h3>
    </template>
    <div class="row">
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="
          convertTimeAndDaysToString(
            deductionsPlansSlideDetailsData.fromNumberInSeconds,
            deductionsPlansSlideDetailsData.fromNumberInMinutes,
            deductionsPlansSlideDetailsData.fromNumberInHours,
            deductionsPlansSlideDetailsData.fromNumberInDays
          )
        "
        :title="$t('deductionsPlanDetails.maxFrom')"
        :imgName="'days.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="
          convertTimeAndDaysToString(
            deductionsPlansSlideDetailsData.toNumberInSeconds,
            deductionsPlansSlideDetailsData.toNumberInMinutes,
            deductionsPlansSlideDetailsData.toNumberInHours,
            deductionsPlansSlideDetailsData.toNumberInDays
          )
        "
        :title="$t('deductionsPlanDetails.maxFrom')"
        :imgName="'days.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="deductionsPlansSlideDetailsData.hourfactor"
        :title="$t('deductionsPlanDetails.hourfactor')"
        :imgName="'hours.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="deductionsPlansSlideDetailsData.employeeSalaryEffectNameCurrent"
        :title="$t('salaryEffectName')"
        :imgName="'money.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="
          deductionsPlansSlideDetailsData.effectOnEmployeeSalaryStatus
            ? $t('yes')
            : $t('no')
        "
        :title="$t('deductions.effectOnEmployeeSalaryStatus')"
        :imgName="'checkmark.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="deductionsPlansSlideDetailsData.slideTypeNameCurrent"
        :title="$t('deductions.slideType')"
        :imgName="'type.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="
          deductionsPlansSlideDetailsData.fullShiftDeductionStatus
            ? $t('yes')
            : $t('no')
        "
        :title="$t('deductions.fullShiftDeductionStatus')"
        :imgName="'checkmark.svg'"
      />
    </div>
  </b-modal>
</template>

<script>
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";
import {
  setDataMultiLang,
  timeToLang,
  convertTimeAndDaysToString,
} from "@/utils/functions";

export default {
  name: "DeductionsPlanDetailsInfo",
  components: {
    DataLabelGroup,
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["deductionsPlansSlideDetailsData"],
  methods: {
    timeToLang(time) {
      return timeToLang(time, this.language);
    },
    setDataMultiLang(lang, dataAr, dataEn) {
      return setDataMultiLang(lang, dataAr, dataEn);
    },
    convertTimeAndDaysToString(seconds, minutes, hours, days) {
      return convertTimeAndDaysToString(seconds, minutes, hours, days).data.data
        .string;
    },
  },
};
</script>

<style scoped lang="scss"></style>
