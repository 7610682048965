import { PAGE_SIZE } from "@/utils/constants";
import axios from "axios";
import DeductionsPlansSlideDetails from "@/models/settings/settingsSalary/deductionsPlans/DeductionsPlansSlideDetails";

export default class DeductionsPlan {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.deductionsPlansToken = "";
    this.deductionsPlansCode = "";
    this.deductionsPlansNameAr = "";
    this.deductionsPlansNameEn = "";
    this.deductionsPlansNameUnd = "";
    this.deductionsPlansNameCurrent = "";
    this.deductionsPlansNote = "";
    this.dateTimeActionAdd = "";
    this.dateActionAdd = "";
    this.TimeActionAdd = "";
    this.cumulativeCalculationTypeToken = "";
    this.cumulativeCalculationTypeNameCurrent = "";
    this.cumulativeCalculationTypeNameAr = "";
    this.cumulativeCalculationTypeNameEn = "";
    this.cumulativeCalculationTypeNameUnd = "";
    this.cumulativeCalculationLateAttendaceStatus = false;
    this.cumulativeCalculationEarlyDepartureStatus = false;
    this.deductionsPlansArchiveStatus = "";
    this.deductionsPlansDetailsSlideData = [new DeductionsPlansSlideDetails()];
  }
  fillData(data) {
    this.deductionsPlansToken = data.deductionsPlansToken;
    this.deductionsPlansCode = data.deductionsPlansCode;
    this.deductionsPlansNameAr = data.deductionsPlansNameAr;
    this.deductionsPlansNameEn = data.deductionsPlansNameEn;
    this.deductionsPlansNameUnd = data.deductionsPlansNameUnd;
    this.deductionsPlansNameCurrent = data.deductionsPlansNameCurrent;
    this.deductionsPlansNote = data.deductionsPlansNote;
    this.dateTimeActionAdd = data.dateTimeActionAdd;
    this.dateActionAdd = data.dateActionAdd;
    this.TimeActionAdd = data.TimeActionAdd;
    this.cumulativeCalculationTypeToken = data.cumulativeCalculationTypeToken;
    this.cumulativeCalculationTypeNameCurrent =
      data.cumulativeCalculationTypeNameCurrent;
    this.cumulativeCalculationTypeNameAr = data.cumulativeCalculationTypeNameAr;
    this.cumulativeCalculationTypeNameEn = data.cumulativeCalculationTypeNameEn;
    this.cumulativeCalculationTypeNameUnd =
      data.cumulativeCalculationTypeNameUnd;
    this.cumulativeCalculationLateAttendaceStatus =
      data.cumulativeCalculationLateAttendaceStatus;
    this.cumulativeCalculationEarlyDepartureStatus =
      data.cumulativeCalculationEarlyDepartureStatus;
    this.deductionsPlansArchiveStatus = data.deductionsPlansArchiveStatus;
    this.deductionsPlansDetailsSlideData = data.deductionsPlansDetailsSlideData;
  }

  async getDeductionsPlanses(
    language,
    userAuthorizeToken,
    selfPage,
    textSearch
  ) {
    try {
      let response = await axios.get(
        `/DeductionsPlans/GetDeductionsPlans?language=${language}&userAuthorizeToken=${userAuthorizeToken}&page=${selfPage}&paginationStatus=true&pageSize=${PAGE_SIZE}&filterStatus=true&textSearch=${textSearch}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getDeductionsPlanDetails(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/DeductionsPlans/GetDeductionsPlansDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&deductionsPlansToken=${this.deductionsPlansToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getDialogOfDeductionsPlans(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/DeductionsPlans/GetDialogOfDeductionsPlans?language=${language}&userAuthorizeToken=${userAuthorizeToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addOrUpdateDeductionsPlan(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      deductionsPlansToken: this.deductionsPlansToken,
      deductionsPlansCode: this.deductionsPlansCode,
      deductionsPlansNameAr: this.deductionsPlansNameAr,
      deductionsPlansNameEn: this.deductionsPlansNameEn,
      deductionsPlansNameUnd: this.deductionsPlansNameUnd,
      cumulativeCalculationTypeToken: this.cumulativeCalculationTypeToken,
      cumulativeCalculationLateAttendaceStatus:
        this.cumulativeCalculationLateAttendaceStatus,
      cumulativeCalculationEarlyDepartureStatus:
        this.cumulativeCalculationEarlyDepartureStatus,
      deductionsPlansNote: this.deductionsPlansNote,
      deductionsPlansDetailsSlideData: this.deductionsPlansDetailsSlideData,
    };

    try {
      let response = "";
      if (
        this.deductionsPlansToken == "" ||
        this.deductionsPlansToken == undefined
      ) {
        response = await axios.post(
          `/DeductionsPlans//AddDeductionsPlans`,
          data
        );
      } else {
        response = await axios.post(
          `/DeductionsPlans//UpdateDeductionsPlans`,
          data
        );
      }
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async archiveDeductionsPlan(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      deductionsPlansToken: this.deductionsPlansToken,
    };

    try {
      let response = await axios.post(
        `/DeductionsPlans//ArchiveDeductionsPlans`,
        data
      );
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
