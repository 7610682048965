import { checkLoginForShowSidebar } from "@/utils/functions";

export default {
  state: {
    userData: null,
    employeeToken: null,
    employeePersonalData: null,
    userPersonalData: null,
    usersEstablishmentPrivilegeData: null,
    usersBranchPrivilageView: null,
    usersBranchPrivilageAdd: {
      expensesAdd: false,
      revenuesAdd: false,
      treasuriesAdd: false,
      treasuryTransactionsAdd: false,
    },
    dialogOfBranchesFinancePrivilageAdd: {
      dialogOfBranchesExpensesAdd: [],
      dialogOfBranchesRevenuesAdd: [],
      dialogOfBranchesTreasuriesAdd: [],
      dialogOfBranchesTreasuryTransactionsAdd: [],
    },
    branchesTokensFinancePrivilageActions: {
      branchesTokensOfExpensesRefund: [],
      branchesTokensOfRevenuesRefund: [],
      branchesTokensOfTreasuriesUpdate: [],
      branchesTokensOfTreasuriesArchive: [],
      branchesTokensOfTreasuryTransactionsReportView: [],
    },
    dialogOfBranchesFinancePrivilageActions: {
      dialogOfBranchesTreasuriesUpdate: [],
      // dialogOfBranchesTreasuryTransactionsReportView: [],
    },
    employeeBranchPrivilage: null,
    employeeStorePrivilage: null,
    constantsLists: null,
    usersVersionData: null,
    employeeFolderToken: "",
  },
  getters: {
    userData: (state) => state.userData,
    employeeToken: (state) => state.employeeToken,
    employeePersonalData: (state) => state.employeePersonalData,
    userPersonalData: (state) => state.userPersonalData,
    usersEstablishmentPrivilegeData: (state) =>
      state.usersEstablishmentPrivilegeData,
    usersBranchPrivilageView: (state) => state.usersBranchPrivilageView,
    usersBranchPrivilageAdd: (state) => state.usersBranchPrivilageAdd,
    dialogOfBranchesFinancePrivilageAdd: (state) =>
      state.dialogOfBranchesFinancePrivilageAdd,
    branchesTokensFinancePrivilageActions: (state) =>
      state.branchesTokensFinancePrivilageActions,
    dialogOfBranchesFinancePrivilageActions: (state) =>
      state.dialogOfBranchesFinancePrivilageActions,
    employeeBranchPrivilage: (state) => state.employeeBranchPrivilage,
    employeeStorePrivilage: (state) => state.employeeStorePrivilage,
    constantsLists: (state) => state.constantsLists,
    usersVersionData: (state) => state.usersVersionData,
    employeeFolderToken: (state) => state.employeeFolderToken,
  },
  mutations: {
    UPDATE_USER_DATA: (state, userData) => {
      const employeeBranchPrivilageArray = userData.employeeBranchPrivilage;

      //#region Expenses
      const expensesAddFilter = employeeBranchPrivilageArray
        .filter(function (branchPrivilage) {
          return (
            branchPrivilage.usersBranchPrivilegeData.usersPrivilegeExpenses
              .expensesAdd == true
          );
        })
        .map(function (branchPrivilage) {
          return branchPrivilage
            .usersBranchPrivilegeData.usersPrivilegeExpenses.expensesAdd;
        });
      const expensesAddStatus =
        expensesAddFilter.find((element) => element == true) || false;

      // dialog of branches can Add
      const dialogOfBranchesExpensesAdd = employeeBranchPrivilageArray
        .filter(function (branchPrivilage) {
          return (
            branchPrivilage.usersBranchPrivilegeData.usersPrivilegeExpenses
              .expensesAdd == true
          );
        })
        .map(function (branchPrivilage) {
          return {
            branchToken: branchPrivilage.branchToken,
            branchNameEn: branchPrivilage.branchNameEn,
            branchNameAr: branchPrivilage.branchNameAr,
            branchNameUnd: branchPrivilage.branchNameUnd,
            branchNameCurrent: branchPrivilage.branchNameCurrent,
            branchImagePath: branchPrivilage.branchImagePath,
            branchRoleToken: branchPrivilage.branchRoleToken,
          };
        });

      // branches tokens can Refund
      const expensesRefundBranchesTokens = employeeBranchPrivilageArray
        .filter(function (branchPrivilage) {
          return (
            branchPrivilage.usersBranchPrivilegeData.usersPrivilegeExpenses
              .expensesRefund == true
          );
        })
        .map(function (branchPrivilage) {
          return branchPrivilage.branchToken;
        });

      //#endregion Expenses

      //#region Revenues
      const revenuesAddFilter = employeeBranchPrivilageArray
        .filter(function (branchPrivilage) {
          return (
            branchPrivilage.usersBranchPrivilegeData.usersPrivilegeRevenues
              .revenuesAdd == true
          );
        })
        .map(function (branchPrivilage) {
          return branchPrivilage
            .usersBranchPrivilegeData.usersPrivilegeRevenues.revenuesAdd;
        });
      const revenuesAddStatus =
        revenuesAddFilter.find((element) => element == true) || false;

      // dialog of branches can Add
      const dialogOfBranchesRevenuesAdd = employeeBranchPrivilageArray
        .filter(function (branchPrivilage) {
          return (
            branchPrivilage.usersBranchPrivilegeData.usersPrivilegeRevenues
              .revenuesAdd == true
          );
        })
        .map(function (branchPrivilage) {
          return {
            branchToken: branchPrivilage.branchToken,
            branchNameEn: branchPrivilage.branchNameEn,
            branchNameAr: branchPrivilage.branchNameAr,
            branchNameUnd: branchPrivilage.branchNameUnd,
            branchNameCurrent: branchPrivilage.branchNameCurrent,
            branchImagePath: branchPrivilage.branchImagePath,
            branchRoleToken: branchPrivilage.branchRoleToken,
          };
        });

      // branches tokens can Refund
      const revenuesRefundBranchesTokens = employeeBranchPrivilageArray
        .filter(function (branchPrivilage) {
          return (
            branchPrivilage.usersBranchPrivilegeData.usersPrivilegeRevenues
              .revenuesRefund == true
          );
        })
        .map(function (branchPrivilage) {
          return branchPrivilage.branchToken;
        });

      //#endregion Revenues

      //#region Treasuries
      const treasuriesAddFilter = employeeBranchPrivilageArray
        .filter(function (branchPrivilage) {
          return (
            branchPrivilage.usersBranchPrivilegeData.usersPrivilegeTreasuries
              .treasuriesAdd == true
          );
        })
        .map(function (branchPrivilage) {
          return branchPrivilage
            .usersBranchPrivilegeData.usersPrivilegeTreasuries.treasuriesAdd;
        });
      const treasuriesAddStatus =
        treasuriesAddFilter.find((element) => element == true) || false;

      // dialog of branches can Add
      const dialogOfBranchesTreasuriesAdd = employeeBranchPrivilageArray
        .filter(function (branchPrivilage) {
          return (
            branchPrivilage.usersBranchPrivilegeData.usersPrivilegeTreasuries
              .treasuriesAdd == true
          );
        })
        .map(function (branchPrivilage) {
          return {
            branchToken: branchPrivilage.branchToken,
            branchNameEn: branchPrivilage.branchNameEn,
            branchNameAr: branchPrivilage.branchNameAr,
            branchNameUnd: branchPrivilage.branchNameUnd,
            branchNameCurrent: branchPrivilage.branchNameCurrent,
            branchImagePath: branchPrivilage.branchImagePath,
            branchRoleToken: branchPrivilage.branchRoleToken,
          };
        });

      // branches tokens can Update
      const treasuriesUpdateBranchesTokens = employeeBranchPrivilageArray
        .filter(function (branchPrivilage) {
          return (
            branchPrivilage.usersBranchPrivilegeData.usersPrivilegeTreasuries
              .treasuriesUpdate == true
          );
        })
        .map(function (branchPrivilage) {
          return branchPrivilage.branchToken;
        });

      // dialog of branches can Update
      const dialogOfBranchesTreasuriesUpdate = employeeBranchPrivilageArray
        .filter(function (branchPrivilage) {
          return (
            branchPrivilage.usersBranchPrivilegeData.usersPrivilegeTreasuries
              .treasuriesUpdate == true
          );
        })
        .map(function (branchPrivilage) {
          return {
            branchToken: branchPrivilage.branchToken,
            branchNameEn: branchPrivilage.branchNameEn,
            branchNameAr: branchPrivilage.branchNameAr,
            branchNameUnd: branchPrivilage.branchNameUnd,
            branchNameCurrent: branchPrivilage.branchNameCurrent,
            branchImagePath: branchPrivilage.branchImagePath,
            branchRoleToken: branchPrivilage.branchRoleToken,
          };
        });

      // branches tokens can Archive
      const treasuriesArchiveBranchesTokens = employeeBranchPrivilageArray
        .filter(function (branchPrivilage) {
          return (
            branchPrivilage.usersBranchPrivilegeData.usersPrivilegeTreasuries
              .treasuriesArchive == true
          );
        })
        .map(function (branchPrivilage) {
          return branchPrivilage.branchToken;
        });

      //#endregion Treasuries

      //#region TreasuriesTransactions
      const treasuryTransactionsAddFilter = employeeBranchPrivilageArray
        .filter(function (branchPrivilage) {
          return (
            branchPrivilage.usersBranchPrivilegeData
              .usersPrivlageTreasuriesTransactions.treasuryTransactionsAdd ==
            true
          );
        })
        .map(function (branchPrivilage) {
          return branchPrivilage
            .usersBranchPrivilegeData.usersPrivlageTreasuriesTransactions.treasuryTransactionsAdd;
        });
      const treasuryTransactionsAddStatus =
        treasuryTransactionsAddFilter.find((element) => element == true) ||
        false;

      // dialog of branches can Add
      const dialogOfBranchesTreasuriesTransactionsAdd =
        employeeBranchPrivilageArray
          .filter(function (branchPrivilage) {
            return (
              branchPrivilage.usersBranchPrivilegeData
                .usersPrivlageTreasuriesTransactions.treasuryTransactionsAdd ==
              true
            );
          })
          .map(function (branchPrivilage) {
            return {
              branchToken: branchPrivilage.branchToken,
              branchNameEn: branchPrivilage.branchNameEn,
              branchNameAr: branchPrivilage.branchNameAr,
              branchNameUnd: branchPrivilage.branchNameUnd,
              branchNameCurrent: branchPrivilage.branchNameCurrent,
              branchImagePath: branchPrivilage.branchImagePath,
              branchRoleToken: branchPrivilage.branchRoleToken,
            };
          });

      // branches tokens can ReportView
      const treasuriesTransactionsReportViewBranchesTokens =
        employeeBranchPrivilageArray
          .filter(function (branchPrivilage) {
            return (
              branchPrivilage.usersBranchPrivilegeData
                .usersPrivlageTreasuriesTransactions
                .treasuriesTransactionsReportView == true
            );
          })
          .map(function (branchPrivilage) {
            return branchPrivilage.branchToken;
          });

      // dialog of branches can ReportView
      // const dialogOfBranchesTreasuriesTransactionsReportView =
      //   employeeBranchPrivilageArray
      //     .filter(function (branchPrivilage) {
      //       return (
      //         branchPrivilage.usersBranchPrivilegeData
      //           .usersPrivlageTreasuriesTransactions
      //           .treasuriesTransactionsReportView == true
      //       );
      //     })
      //     .map(function (branchPrivilage) {
      //       return {
      //         branchToken: branchPrivilage.branchToken,
      //         branchNameEn: branchPrivilage.branchNameEn,
      //         branchNameAr: branchPrivilage.branchNameAr,
      //         branchNameUnd: branchPrivilage.branchNameUnd,
      //         branchNameCurrent: branchPrivilage.branchNameCurrent,
      //         branchImagePath: branchPrivilage.branchImagePath,
      //         branchRoleToken: branchPrivilage.branchRoleToken,
      //       };
      //     });

      //#endregion TreasuriesTransactions

      //#region StateData

      state.usersBranchPrivilageAdd = {
        expensesAdd: expensesAddStatus,
        revenuesAdd: revenuesAddStatus,
        treasuriesAdd: treasuriesAddStatus,
        treasuryTransactionsAdd: treasuryTransactionsAddStatus,
      };

      state.dialogOfBranchesFinancePrivilageAdd = {
        dialogOfBranchesExpensesAdd: dialogOfBranchesExpensesAdd,
        dialogOfBranchesRevenuesAdd: dialogOfBranchesRevenuesAdd,
        dialogOfBranchesTreasuriesAdd: dialogOfBranchesTreasuriesAdd,
        dialogOfBranchesTreasuryTransactionsAdd:
          dialogOfBranchesTreasuriesTransactionsAdd,
      };

      state.branchesTokensFinancePrivilageActions = {
        branchesTokensOfExpensesRefund: expensesRefundBranchesTokens,
        branchesTokensOfRevenuesRefund: revenuesRefundBranchesTokens,
        branchesTokensOfTreasuriesUpdate: treasuriesUpdateBranchesTokens,
        branchesTokensOfTreasuriesArchive: treasuriesArchiveBranchesTokens,
        branchesTokensOfTreasuryTransactionsReportView:
          treasuriesTransactionsReportViewBranchesTokens,
      };

      state.dialogOfBranchesFinancePrivilageActions = {
        dialogOfBranchesTreasuriesUpdate: dialogOfBranchesTreasuriesUpdate,
        // dialogOfBranchesTreasuryTransactionsReportView:
        //   dialogOfBranchesTreasuriesTransactionsReportView,
      };

      state.userData = userData;
      state.employeePersonalData = userData.employeePersonalData;
      state.userPersonalData = userData.userPersonalData;
      state.usersEstablishmentPrivilegeData =
        userData.usersEstablishmentPrivilegeData;
      state.usersBranchPrivilageView = userData.usersBranchPrivilageView;
      state.employeeBranchPrivilage = userData.employeeBranchPrivilage;
      state.employeeStorePrivilage = userData.employeeStorePrivilage;
      state.constantsLists = userData.constantLists;
      state.usersVersionData = userData.usersVersionData;
      //#endregion StateData

      checkLoginForShowSidebar();
    },
    LOGOUT_USER: (state) => {
      state.userData = null;
      state.employeePersonalData = null;
      state.userPersonalData = null;
      state.usersEstablishmentPrivilegeData = null;
      state.usersBranchPrivilageView = null;
      state.employeeBranchPrivilage = null;
      state.employeeStorePrivilage = null;
      state.constantsLists = null;
      state.usersVersionData = null;
      checkLoginForShowSidebar();
    },
    UPDATE_EMPLOYEE_TOKEN: (state, employeeToken) => {
      state.employeeToken = employeeToken;
    },
    UPDATE_EMPLOYEE_FOLDER_TOKEN: (state, employeeFolderToken) => {
      state.employeeFolderToken = employeeFolderToken;
    },
  },
  actions: {
    logoutUser(context) {
      context.commit("LOGOUT_USER");
    },
    updateUserData(context, userData) {
      context.commit("UPDATE_USER_DATA", userData);
    },
    updateEmployeeToken(context, employeeToken) {
      context.commit("UPDATE_EMPLOYEE_TOKEN", employeeToken);
    },
    updateEmployeeFolderToken(context, employeeFolderToken) {
      context.commit("UPDATE_EMPLOYEE_FOLDER_TOKEN", employeeFolderToken);
    },
  },
  modules: {},
};
