import { PAGE_SIZE } from "@/utils/constants";
import axios from "axios";

export default class WorkPlanShift {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.workPlanShiftToken = "";
    this.workPlanToken = "";
    this.workPlanNameCurrent = "";
    this.workPlanDayToken = "";
    this.workPlanDayNameCurrent = "";
    // this.workShiftToken = ""; //old
    // this.workShiftNameCurrent = ""; //old
    this.workPlanShiftNameCurrent = ""; //new
    this.workPlanShiftNameAr = ""; //new
    this.workPlanShiftNameEn = ""; //new
    this.workPlanShiftNameUnd = ""; //new
    this.dayFromToken = ""; //new
    this.dayFromNameCurrent = ""; //new
    this.dayFromNameAr = ""; //new
    this.dayFromNameEn = ""; //new
    this.dayFromNameUnd = ""; //new
    this.workShiftTimeFrom = "";
    this.dayToToken = ""; //new
    this.dayToNameCurrent = ""; //new
    this.dayToNameAr = ""; //new
    this.dayToNameEn = ""; //new
    this.dayToNameUnd = ""; //new
    this.workShiftTimeTo = "";
    this.workShiftDuration = "";
    this.workShiftTypeToken = "";
    this.workShiftTypeNameCurrent = "";
    this.workShiftTypeNameEn = "";
    this.workShiftTypeNameAr = "";
    this.workShiftTypeNameUnd = "";
    this.workShiftHourFactor = "";
    this.employeeMaximumAllowedAttendLateInShift = "";
    this.employeeMaximumAllowedAttendLateInShiftHour = "";
    this.employeeMaximumAllowedAttendLateInShiftMinute = "";
    this.employeeMaximumAllowedAttendLateInShiftSecond = "";
    this.employeeMaximumAllowedEarlyDepartureInShift = "";
    this.employeeMaximumAllowedEarlyDepartureInShiftHour = "";
    this.employeeMaximumAllowedEarlyDepartureInShiftMinute = "";
    this.employeeMaximumAllowedEarlyDepartureInShiftSecond = "";
    this.employeeOverTimeStartInShift = "";
    this.employeeOverTimeStartInShiftHour = "";
    this.employeeOverTimeStartInShiftMinute = "";
    this.employeeOverTimeStartInShiftSecond = "";
    // this.workShiftIsHaveCompleteStatus = ""; //old
    this.workPlanShiftArchiveStatus = false;
  }
  fillData(data) {
    this.workPlanShiftToken = data.workPlanShiftToken;
    this.workPlanToken = data.workPlanToken;
    this.workPlanNameCurrent = data.workPlanNameCurrent;
    this.workPlanDayToken = data.workPlanDayToken;
    this.workPlanDayNameCurrent = data.workPlanDayNameCurrent;
    this.workPlanShiftNameCurrent = data.workPlanShiftNameCurrent;
    this.workPlanShiftNameAr = data.workPlanShiftNameAr;
    this.workPlanShiftNameEn = data.workPlanShiftNameEn;
    this.workPlanShiftNameUnd = data.workPlanShiftNameUnd;
    this.dayFromToken = data.dayFromToken;
    this.dayFromNameCurrent = data.dayFromNameCurrent;
    this.dayFromNameAr = data.dayFromNameAr;
    this.dayFromNameEn = data.dayFromNameEn;
    this.dayFromNameUnd = data.dayFromNameUnd;
    this.workShiftTimeFrom = data.workShiftTimeFrom;
    this.dayToToken = data.dayToToken;
    this.dayToNameCurrent = data.dayToNameCurrent;
    this.dayToNameAr = data.dayToNameAr;
    this.dayToNameEn = data.dayToNameEn;
    this.dayToNameUnd = data.dayToNameUnd;
    this.workShiftTimeTo = data.workShiftTimeTo;
    this.workShiftDuration = data.workShiftDuration;
    this.workShiftTypeToken = data.workShiftTypeToken;
    this.workShiftTypeNameCurrent = data.workShiftTypeNameCurrent;
    this.workShiftTypeNameEn = data.workShiftTypeNameEn;
    this.workShiftTypeNameAr = data.workShiftTypeNameAr;
    this.workShiftTypeNameUnd = data.workShiftTypeNameUnd;
    this.workShiftHourFactor = data.workShiftHourFactor;
    this.employeeMaximumAllowedAttendLateInShift =
      data.employeeMaximumAllowedAttendLateInShift;
    this.employeeMaximumAllowedEarlyDepartureInShift =
      data.employeeMaximumAllowedEarlyDepartureInShift;
    this.employeeOverTimeStartInShift = data.employeeOverTimeStartInShift;
    this.workPlanShiftArchiveStatus = data.workPlanShiftArchiveStatus;
  }

  async getAllWorkPlansShifts(
    language,
    userAuthorizeToken,
    selfPage,
    textSearch
  ) {
    try {
      let response = await axios.get(
        `/WorkPlansShifts/GetAllWorkPlansShifts?language=${language}&userAuthorizeToken=${userAuthorizeToken}&page=${selfPage}&pageSize=${PAGE_SIZE}&filterStatus=true&textSearch=${textSearch}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getWorkPlanShiftsDetalis(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/WorkPlansShifts/GetWorkPlanShiftsDetalis?language=${language}&userAuthorizeToken=${userAuthorizeToken}&workPlanShiftToken=${this.workPlanShiftToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  // async addWorkPlansShifts(language, userAuthorizeToken) {
  //   var data = {
  //     language: language,
  //     userAuthorizeToken: userAuthorizeToken,
  //     workPlanDayToken: this.workPlanDayToken,
  //     workShiftListDto: this.workShiftListDto,
  //   };

  //   try {
  //     return await axios.post(`/WorkPlansShifts/AddWorkPlansShifts`, data);
  //   } catch (e) {
  //     return this.$t("errorCatch");
  //   }
  // }

  // async updateWorkPlanShift(language, userAuthorizeToken) {
  //   var data = {
  //     language: language,
  //     userAuthorizeToken: userAuthorizeToken,
  //     workPlanShiftToken: this.workPlanShiftToken,
  //   };

  //   try {
  //     let response = "";
  //     response = await axios.post(`/WorkPlansShifts/UpdateWorkPlanShift`, data);
  //     return response;
  //   } catch (e) {
  //     return this.$t("errorCatch");
  //   }
  // }

  // async finalDeleteWorkPlansShift(language, userAuthorizeToken) {
  //   var data = {
  //     language: language,
  //     userAuthorizeToken: userAuthorizeToken,
  //     workPlanShiftToken: this.workPlanShiftToken,
  //   };

  //   try {
  //     let response = await axios.post(
  //       `/WorkPlansShifts/FinalDeleteWorkPlansShift`,
  //       data
  //     );
  //     return response;
  //   } catch (e) {
  //     return this.$t("errorCatch");
  //   }
  // }
}
