var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[(_vm.isLoading)?_c('PreLoader'):_vm._e(),(_vm.exceptionMsg)?_c('ExceptionWithImg',{attrs:{"msg":_vm.exceptionMsg}}):_vm._e(),_c('form',{attrs:{"autocomplete":"off","method":"post"},on:{"submit":function($event){$event.preventDefault();return _vm.addOrUpdateDeductionsPlansSlidesDetails.apply(null, arguments)}}},[_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('CustomSelectBox',{attrs:{"className":'col-md-12',"id":'deductionTypeToken',"value":_vm.deductionsPlansSlideDetailsData.deductionTypeToken,"options":_vm.deductionTypeTokenOptions,"title":_vm.$t('deductions.selectType'),"imgName":'type.svg'},on:{"changeValue":function($event){return _vm.deductionsPlanTypeChanged($event)}}}),(
            _vm.deductionsPlansSlideDetailsData.deductionTypeToken ==
            _vm.deductionTypeTokens.Effect
          )?_c('CustomSelectBox',{attrs:{"className":'col-md-12',"id":'employeeSalaryEffectToken',"value":_vm.deductionsPlansSlideDetailsData.employeeSalaryEffectToken,"options":_vm.employeeSalaryEffectTokenOptions,"title":_vm.$t('selectSalaryEffect'),"imgName":'money.svg'},on:{"changeValue":function($event){_vm.deductionsPlansSlideDetailsData.employeeSalaryEffectToken = $event}}}):_vm._e(),_c('div',{staticClass:"my-card col-md-12"},[_c('span',{staticClass:"my-card-title"},[_vm._v(_vm._s(_vm.$t("deductionsPlanDetails.max")))]),_c('div',{staticClass:"row"},[_c('CustomInput',{attrs:{"className":'col-md-3',"id":"deductionNumberInDays","value":_vm.deductionsPlansSlideDetailsData.deductionNumberInDays,"title":_vm.$t('day'),"imgName":'days.svg'},on:{"changeValue":function($event){_vm.deductionsPlansSlideDetailsData.deductionNumberInDays = $event}}}),_c('CustomInput',{attrs:{"className":'col-md-3',"id":'deductionNumberInHours',"value":_vm.deductionsPlansSlideDetailsData.deductionNumberInHours,"title":_vm.$t('hour'),"imgName":'hours.svg'},on:{"changeValue":function($event){_vm.deductionsPlansSlideDetailsData.deductionNumberInHours =
                  $event}}}),_c('CustomInput',{attrs:{"className":'col-md-3',"id":'deductionNumberInMinutes',"value":_vm.deductionsPlansSlideDetailsData.deductionNumberInMinutes,"title":_vm.$t('minute'),"imgName":'minutes.svg'},on:{"changeValue":function($event){_vm.deductionsPlansSlideDetailsData.deductionNumberInMinutes =
                  $event}}}),_c('CustomInput',{attrs:{"className":'col-md-3',"id":'deductionNumberInSeconds',"value":_vm.deductionsPlansSlideDetailsData.deductionNumberInSeconds,"title":_vm.$t('second'),"imgName":'seconds.svg'},on:{"changeValue":function($event){_vm.deductionsPlansSlideDetailsData.deductionNumberInSeconds =
                  $event}}})],1)]),_c('TextArea',{attrs:{"className":'col-md-12',"id":'deductionsPlansSlidesDetailsNote',"value":_vm.deductionsPlansSlideDetailsData.deductionsPlansSlidesDetailsNote,"title":_vm.$t('notes'),"imgName":'notes.svg'},on:{"changeValue":function($event){_vm.deductionsPlansSlideDetailsData.deductionsPlansSlidesDetailsNote =
              $event}}})],1)]),_c('div',{staticClass:"form-footer"},[_c('button',{staticClass:"btn btn-submit",attrs:{"name":"submit","type":"submit"}},[_vm._v(" "+_vm._s(_vm.submitName)+" ")]),_c('router-link',{staticClass:"btn btn-cancel",attrs:{"to":("/deductions-plan-details/" + (_vm.deductionsPlansSlideDetailsData.deductionsPlansToken))}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }