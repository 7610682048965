var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.salaryEffectsData)?_c('div',{},[_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"my-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v(_vm._s(_vm.$t("salaryEffectName")))]),_c('th',[_vm._v(_vm._s(_vm.$t("salaryEffectTypeName")))]),_c('th',[_vm._v(_vm._s(_vm.$t("calculationMethodTypeName")))]),_c('th',[_vm._v(_vm._s(_vm.$t("salaryEffectFeeTypeName")))]),_c('th',[_vm._v(_vm._s(_vm.$t("defaultValue")))]),_c('th',[_vm._v(_vm._s(_vm.$t("actionDateTime")))]),_c('th',[_vm._v(_vm._s(_vm.$t("notes")))]),(
              _vm.checkPrivilege(
                _vm.usersEstablishmentPrivilegeData
                  .usersPrivilegAdditionalSettings.additionalSettingsUpdate
              )
            )?_c('th',[_vm._v(" "+_vm._s(_vm.$t("edit"))+" ")]):_vm._e(),(
              _vm.checkPrivilege(
                _vm.usersEstablishmentPrivilegeData
                  .usersPrivilegAdditionalSettings.additionalSettingsArchive
              )
            )?_c('th',[_vm._v(" "+_vm._s(_vm.$t("delete"))+" ")]):_vm._e()])]),_c('tbody',_vm._l((_vm.salaryEffectsData),function(salaryEffect,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(++index))]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist(salaryEffect.employeeSalaryEffectNameCurrent))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist(salaryEffect.employeeSalaryEffectTypeNameCurrent))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist(salaryEffect.calculationMethodTypeNameCurrent))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist(salaryEffect.employeeSalaryEffectFeeTypeNameCurrent))+" ")]),_c('td',[_vm._v(_vm._s(salaryEffect.defaultValue))]),_c('td',[_vm._v(_vm._s(_vm.timeToLang(salaryEffect.actionDateTime)))]),_c('td',[_vm._v(_vm._s(_vm.isDataExist(salaryEffect.employeeSalaryEffectNotes)))]),(
              _vm.checkPrivilege(
                _vm.usersEstablishmentPrivilegeData
                  .usersPrivilegAdditionalSettings.additionalSettingsUpdate
              )
            )?_c('td',[_c('router-link',{attrs:{"to":("/edit-salary-effect/" + (salaryEffect.employeeSalaryEffectToken)),"title":_vm.$t('edit')}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/pencil.svg")}})])],1):_vm._e(),(
              _vm.checkPrivilege(
                _vm.usersEstablishmentPrivilegeData
                  .usersPrivilegAdditionalSettings.additionalSettingsArchive
              )
            )?_c('td',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.SalaryEffectDelete",modifiers:{"SalaryEffectDelete":true}}],staticClass:"btn p-0",attrs:{"title":_vm.$t('delete')},on:{"click":function($event){return _vm.setSalaryEffectData(salaryEffect)}}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/trash.svg")}})])]):_vm._e()])}),0)])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }