export default class UsersPrivilegeEmployeeWorkSchedule {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.employeeWorkScheduleView = false;
    this.employeeWorkScheduleAdd = false;
    this.employeeWorkScheduleUpdate = false;
    this.employeeWorkScheduleArchive = false;
    this.employeeWorkScheduleRestore = false;
    this.employeeWorkScheduleDelete = false;
  }
  fillData(data) {
    if (data) {
      this.employeeWorkScheduleView = data.employeeWorkScheduleView;
      this.employeeWorkScheduleAdd = data.employeeWorkScheduleAdd;
      this.employeeWorkScheduleUpdate = data.employeeWorkScheduleUpdate;
      this.employeeWorkScheduleArchive = data.employeeWorkScheduleArchive;
      this.employeeWorkScheduleRestore = data.employeeWorkScheduleRestore;
      this.employeeWorkScheduleDelete = data.employeeWorkScheduleDelete;
    } else {
      this.setInitialValue();
    }
  }
}
